import { OnInit, EventEmitter } from '@angular/core';
var FileNameComparator = /** @class */ (function () {
    function FileNameComparator() {
    }
    FileNameComparator.prototype.compare = function (a, b) {
        if (a.isDirectory && !b.isDirectory) {
            return -1;
        }
        if (b.isDirectory && !a.isDirectory) {
            return 1;
        }
        return a.name.localeCompare(b.name);
    };
    return FileNameComparator;
}());
var FilelistComponent = /** @class */ (function () {
    function FilelistComponent() {
        this.fileNameComparator = new FileNameComparator();
        this.loading = false;
        this._selectedFiles = [];
        this.selectedFilesChange = new EventEmitter();
    }
    Object.defineProperty(FilelistComponent.prototype, "selectedFiles", {
        get: function () {
            return this._selectedFiles;
        },
        set: function (value) {
            if (value !== undefined) {
                this._selectedFiles = value;
                this.selectedFilesChange.emit(this._selectedFiles);
            }
        },
        enumerable: true,
        configurable: true
    });
    FilelistComponent.prototype.ngOnInit = function () {
    };
    FilelistComponent.prototype.getIcon = function (file) {
        var icon = 'file';
        if (file.isDirectory) {
            icon = file.name === 'Trash' ? 'trash' : 'folder';
        }
        return icon;
    };
    return FilelistComponent;
}());
export { FilelistComponent };
