import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileResponse } from '../../services/files.service';
import { ClrDatagridComparatorInterface } from '@clr/angular';

class FileNameComparator implements ClrDatagridComparatorInterface<FileResponse> {

  compare(a: FileResponse, b: FileResponse) {
    if (a.isDirectory && !b.isDirectory) {
      return -1;
    }

    if (b.isDirectory && !a.isDirectory) {
      return 1;
    }

    return a.name.localeCompare(b.name);
  }
}

@Component({
  selector: 'app-filelist',
  templateUrl: './filelist.component.html',
  styleUrls: ['./filelist.component.css']
})
export class FilelistComponent implements OnInit {

  public fileNameComparator = new FileNameComparator();

  @Input() files: FileResponse[];
  @Input() loading = false;

  private _selectedFiles: FileResponse[] = [];
  @Input() get selectedFiles() {
    return this._selectedFiles;
  }

  set selectedFiles(value: FileResponse[]) {
    if (value !== undefined) {
      this._selectedFiles = value;
      this.selectedFilesChange.emit(this._selectedFiles);
    }
  }

  @Output() selectedFilesChange = new EventEmitter<FileResponse[]>();

  constructor() { }

  ngOnInit() {
  }

  getIcon(file: FileResponse): string {
    let icon = 'file';
    if (file.isDirectory) {
      icon = file.name === 'Trash' ? 'trash' : 'folder';
    }
    return icon;
  }
}
