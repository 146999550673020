import { LocationActions } from '../actions/edi';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EdiLocation } from '../models';

export interface State extends EntityState<EdiLocation> {
  loading: boolean;
  error: any | null;
  selectedLocationID: string | null;
  editing: boolean;
  saved: boolean;
}

export const locationsAdapter: EntityAdapter<EdiLocation> = createEntityAdapter<EdiLocation>({
  selectId: (location) => location.Code,
  sortComparer: false,
});

export const initialState = locationsAdapter.getInitialState({
  selectedLocationID: null,
  selectedIDs: [],
  error: null,
  loading: false,
  editing: false,
  saved: true,
});

export function locationsReducer(state = initialState, action: LocationActions.LocationsActionsUnion) {
  switch (action.type) {
    case LocationActions.ListPageLoad.type:
      return {
        ...state,
        loading: true,
      };
    case LocationActions.APIListSuccess.type:
      return {
        ...locationsAdapter.addAll(action.locations, state),
        loading: false,
      };
    case LocationActions.DetailPageLoad.type:
      return {
        ...state,
        selectedLocationID: action.id,
        loading: true,
        editing: action.edit,
        saved: !action.edit,
      };
    case LocationActions.APIGetSuccess.type:
      return {
        ...locationsAdapter.addOne(action.location, state),
        selectedLocationID: action.location.Code,
      };
    case LocationActions.APISaveSuccess.type:
      return {
        ...locationsAdapter.updateOne({ id: action.location.Code, changes: action.location }, state),
        saved: true,
      };
    case LocationActions.APIDeleteSuccess.type:
      return {
        ...locationsAdapter.removeMany(action.locationIDs, state),
      };
    case LocationActions.APICreateFailure.type:
      return {
        ...state,
        error: action.error
      };
    case LocationActions.APIDeleteFailure.type:
      return {
        ...state,
        error: action.error
      };
    case LocationActions.APICreateSuccess.type:
      return {
        ...state,
        saved: true,
      };
    case LocationActions.CreatePageLoad.type:
      return {
        ...state,
        error: undefined,
        saved: false,
      };
    case LocationActions.CreatePageUnload.type:
      return {
        ...state,
        error: undefined,
      };
    default:
      return state;
  }
}


export const selectSelectedLocationID = (state: State) => state.selectedLocationID;

export const selectLocationsLoading = (state: State) => state.loading;

export const {
  selectIds: selectLocationIDs,
  selectEntities: selectLocations,
  selectAll: selectLocationsList,
  selectTotal: selectLocationsTotal,
} = locationsAdapter.getSelectors();

