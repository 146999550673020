import { Routes } from '@angular/router';
import { FilesComponent } from './containers/files/files.component';


export const REPORTS_ROUTES: Routes = [
  {
    path: '**',
    component: FilesComponent
  }
];
