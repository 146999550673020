import { Component, OnInit, forwardRef, OnDestroy } from '@angular/core';
import { Observable, Subject, concat, of, Subscription } from 'rxjs';
import { DebtorsService } from '../../services/debtors.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-debtor-autocomplete',
  templateUrl: './debtor-autocomplete.component.html',
  styleUrls: ['./debtor-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DebtorAutocompleteComponent),
      multi: true
    }
  ]
})
export class DebtorAutocompleteComponent implements OnInit, ControlValueAccessor, OnDestroy {

  typeahead$ = new Subject<string>();
  options$: Observable<string[]>;
  input = new FormControl('');

  private _onChange = (value: any) => {};

  constructor(private debtorsService: DebtorsService) {

   }

  writeValue(value: any) {
    if (value !== undefined) {
      this.input.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this.input.valueChanges.subscribe(fn);
  }

  registerOnTouched() {

  }

  ngOnDestroy() {
  }

  ngOnInit() {
    const initialValues = [];

    this.options$ = concat(
      of(initialValues),
      this.typeahead$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap( (search) => this.debtorsService.list({ search: search, limit: '10' }).pipe(
          map( (debtors) => debtors.map( debtor => debtor.code )),
          catchError( () => of(initialValues)),
        ))
      )
    );

  }

}
