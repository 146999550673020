var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import * as AnzioActions from './actions';
import { Store } from '@ngrx/store';
import { FilesService } from '../reports/services/files.service';
import { TerminalService } from './services/terminal.service';
import { Router } from '@angular/router';
var AnzioEffects = /** @class */ (function () {
    function AnzioEffects(actions$, terminalService, fileService, router, store) {
        var _this = this;
        this.actions$ = actions$;
        this.terminalService = terminalService;
        this.fileService = fileService;
        this.router = router;
        this.store = store;
        this.connect = this.actions$.pipe(ofType(AnzioActions.CONNECT), switchMap(function (action) {
            return _this.terminalService.
                createSession(action.payload.server, action.payload.password).pipe(map(function (session) { return new AnzioActions.ConnectSuccess(session); }));
        }));
        this.connectionStatus = this.actions$.pipe(ofType(AnzioActions.CONNECT_SUCCESS), switchMap(function (action) {
            return action.payload.status.pipe(map(function (status) { return new AnzioActions.SessionStatus(status); }), catchError(function (error) { return of(new AnzioActions.SessionError(error)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AnzioEffects.prototype, "connect", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AnzioEffects.prototype, "connectionStatus", void 0);
    return AnzioEffects;
}());
export { AnzioEffects };
