import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { LoginRedirect } from './actions';
import { AppState } from '../reducers';
import { getUser } from './reducers';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  constructor(private store: Store<AppState>, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;
    return this.store.pipe(
      getUser,
      map(user => {
        if (!user.signedIn) {
          this.store.dispatch(new LoginRedirect(url));
          return false;
        }
        return true;
      }),
      take(1)
    );
  }

}
