import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { EdiStockTranslation, EdiStockTranslationID } from '../models';

function cleanCode(code: string) {
  return encodeURIComponent(encodeURIComponent(decodeURIComponent(code)));
}


@Injectable({
  providedIn: 'root'
})
export class StocksService {

  private getUrl(id: EdiStockTranslationID) {
    return '/edi/stocks/' + cleanCode(id.StockCode) + '/' + cleanCode(id.DebtorCode);
  }

  constructor(private api: ApiService) { }

  list() {
    return this.api.get<EdiStockTranslation[]>('/edi/stocks');
  }

  get(id: EdiStockTranslationID) {
    const url = this.getUrl(id);
    return this.api.get<EdiStockTranslation>(url);
  }

  create(stock: EdiStockTranslation) {
    return this.api.post<EdiStockTranslation, EdiStockTranslation>('/edi/stocks', stock);
  }

  update(stock: EdiStockTranslation) {
    const url = this.getUrl(stock);
    return this.api.post<EdiStockTranslation, EdiStockTranslation>(url, stock);
  }

  delete(id: EdiStockTranslationID) {
    const url = this.getUrl(id);
    return this.api.delete<boolean>(url);
  }

}
