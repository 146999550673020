import { of } from 'rxjs';
import * as i0 from "@angular/core";
var DialogService = /** @class */ (function () {
    function DialogService() {
    }
    DialogService.prototype.confirm = function (message) {
        var confirmation = window.confirm(message || 'Is it OK?');
        return of(confirmation);
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
