import { createAction, props, union } from '@ngrx/store';
import { EdiLocation } from '../../models';


export const APIListSuccess = createAction(
  '[EDI Locations API] List Success',
  props<{ locations: EdiLocation[] }>(),
);
export const APIListFailure = createAction(
  '[EDI Locations API] List Failure',
  props<{ error: any }>(),
);

export const APIGetSuccess = createAction(
  '[EDI Locations API] Get Success',
  props<{ location: EdiLocation }>(),
);
export const APIGetFailure = createAction(
  '[EDI Locations API] Get Failure',
  props<{ error: any }>(),
);

export const APISaveSuccess = createAction(
  '[EDI Locations API] Save Location Success',
  props<{ location: EdiLocation }>(),
);

export const APISaveFailure = createAction(
  '[EDI Locations API] Save Location Failure',
  props<{ error: any }>(),
);

export const APICreateSuccess = createAction(
  '[EDI Locations API] Create Location Success',
  props<{ location: EdiLocation }>(),
);

export const APICreateFailure = createAction(
  '[EDI Locations API] Create Location Failure',
  props<{ error: any }>(),
);

export const APIDeleteSuccess = createAction(
  '[EDI Locations API] Delete Success',
  props<{ locationIDs: string[] }>(),
);

export const APIDeleteFailure = createAction(
  '[EDI Locations API] Delete Failure',
  props<{ error: any }>(),
);

export const DetailPageLoad = createAction(
  '[EDI Locations Detail Page] Page Loaded',
  props<{ id: string, edit: boolean }>(),
);

export const DetailPageSaveButton = createAction(
  '[EDI Locations Detail Page] Save Button',
  props<{ location: EdiLocation }>(),
);

export const DetailPageEditButton = createAction(
  '[EDI Locations Detail Page] Cancel Button',
);

export const DetailPageDeleteButton = createAction(
  '[EDI Locations Detail Page] Delete Button',
  props<{ locations: EdiLocation[] }>(),
);

export const ListPageLoad = createAction(
  '[EDI Locations List Page] Page Loaded',
);

export const ListPageCreateButton = createAction(
  '[EDI Locations List Page] Create Button',
  props<{ location: EdiLocation }>(),
);

export const ListPageDeleteButton = createAction(
  '[EDI Locations List Page] Delete Button',
  props<{ locations: EdiLocation[] }>(),
);

export const CreatePageLoad = createAction(
  '[EDI Locations Create Page] Page Loaded',
);

export const CreatePageUnload = createAction(
  '[EDI Locations Create Page] Page Unloaded',
);


export const CreatePageCreateButton = createAction(
  '[EDI Locations Create Page] Create Button',
  props<{ location: EdiLocation }>(),
);

export const CreatePageCancelButton = createAction(
  '[EDI Locations Create Page] Cancel Button',
);



const all = union({
  ListPageLoad,
  APIListSuccess,
  APIListFailure,
  APIGetSuccess,
  APIGetFailure,
  DetailPageLoad,
  DetailPageEditButton,
  DetailPageSaveButton,
  APISaveSuccess,
  APISaveFailure,
  ListPageCreateButton,
  ListPageDeleteButton,
  APICreateSuccess,
  APICreateFailure,
  CreatePageLoad,
  CreatePageUnload,
  CreatePageCreateButton,
  CreatePageCancelButton,
  APIDeleteSuccess,
  APIDeleteFailure,
});

export type LocationsActionsUnion = typeof all;
