export interface EdiLocation {
  Code: string;
  Name: string;
  Account: string;
  DeliveryCode: string;
  OrderTemplate: string;
}

export interface EdiStockTranslationID {
  DebtorCode: string;
  StockCode: string;
}

export interface EdiStockTranslation {
  DebtorCode: string;
  StockCode: string;
  TUN: string;
}

export const EncodeEdiStockTranslationID = (translation: EdiStockTranslation) => `${translation.DebtorCode}::${translation.StockCode}`;
export const DecodeEdiStockTranslationID = (id: string) => {
  const parts = id.split('::');

  if (parts.length !== 2) {
    throw new Error('Invalid Stock Translation ID');
  }

  return {
    StockCode: parts[1],
    DebtorCode: parts[0],
  };
};


export interface CivilDate {
  Date: string;
  Time: string;
  HasTime: boolean;
}

export interface EdiOrderLine {
  Barcode: string;
  Price: number;
  Quantity: number;
}

export interface EdiOrderResponse {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  LastError: string;
  Acknowledged: boolean;
  InvoiceNumber: string;
  PickingSlip: string;
  OrderNumber: string;
  Department: string;
  NotBefore: CivilDate;
  NotAfter: CivilDate;
  AdvertDate: CivilDate;
  Notes: string;
  UnitsOrdered: string;
  Value: number;
  DC: string;
  Store: string;
  ChainName: string;
  VendorNumber: string;
  Test: boolean;
  InvoiceTo: string;
  MessageFormat: string;
  PromoCode: string;
  OrderType: string;
  Lines: EdiOrderLine[];
}
