import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectEdiList, selectEdiOrdersState, selectEdiOrdersLoading } from '../../../reducers';
import { OrderActions } from '../../../actions/edi';
import { EdiOrderResponse } from '../../../models';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
// tslint:disable-next-line:component-class-suffix
export class OrderListContainer implements OnInit {

  loading$ = this.store.pipe(select(selectEdiOrdersLoading));
  list$ = this.store.pipe(select(selectEdiList));

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.store.dispatch(OrderActions.ListPageLoad());
  }


  orderLink(order: EdiOrderResponse) {
    return [order.ID];
  }

}
