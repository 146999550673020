export var MenuActionTypes;
(function (MenuActionTypes) {
    MenuActionTypes["LoadMenus"] = "[Menu] Load Menus";
})(MenuActionTypes || (MenuActionTypes = {}));
var LoadMenus = /** @class */ (function () {
    function LoadMenus(payload) {
        this.payload = payload;
        this.type = MenuActionTypes.LoadMenus;
    }
    return LoadMenus;
}());
export { LoadMenus };
