import { createSelector, createFeatureSelector, select } from '@ngrx/store';
import { intersection, flatMap } from 'lodash';

import { AuthActionTypes, Actions } from './actions';

import { User } from './models';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectMenu } from '../reducers';
import { MenuItem } from '../models';

export const featureName = 'auth';

export interface AuthState {
  user: User | null;
  loginURL: string | null;
}

export const initialState: AuthState = {
  user: null,
  loginURL: null
};

export function reducer(state = initialState, action: Actions): AuthState {
  switch (action.type) {
    case AuthActionTypes.UPDATE_USER:
      return {
        ...state,
        user: action.payload
      };
    case AuthActionTypes.LOGIN_REDIRECT:
      return {
        ...state,
        loginURL: action.payload
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    case AuthActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        user: null
      };
    default: {
      return state;
    }
  }
}

export const selectAuth = createFeatureSelector<AuthState>(featureName);
export const selectLoggedIn = createSelector(selectAuth, (state: AuthState) => state.user !== null);

function getEffectivePermissions(user: User): string[] {
  const groupPermissions = flatMap(user.groups, group => group.permissions);
  return [
    ...user.permissions,
    ...groupPermissions,
  ];
}

function filterMenu(items: MenuItem[], user: User) {
  return items
    .filter(item =>
      item.requiredPermissions === undefined ||
      item.requiredPermissions.length === 0 ||
      intersection(item.requiredPermissions, getEffectivePermissions(user)).length > 0
    )
    .map(item => {
      return {
        ...item,
        children: filterMenu(item.children, user),
      };
    });
}

export const selectUserMenu = createSelector(selectAuth, selectMenu,
  (auth, menu) => {
    return filterMenu(menu, auth.user);
  });


export const getUser = pipe(
  select(selectAuth),
  map(auth => auth.user),
  filter(user => user !== null),
);
