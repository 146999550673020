var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
var WarehouseComponent = /** @class */ (function () {
    function WarehouseComponent(router) {
        this.router = router;
        this.collapsed = false;
        this.menu = [
            {
                icon: 'dashboard',
                link: './dashboard',
                name: 'Dashboard'
            },
            {
                icon: 'cog',
                name: 'EDI',
                children: [
                    {
                        icon: 'list',
                        link: 'edi/orders',
                        name: 'Orders'
                    },
                    {
                        icon: 'map',
                        link: 'edi/locations',
                        name: 'Locations'
                    },
                    {
                        icon: 'bar-code',
                        link: 'edi/translations',
                        name: 'Stock Translations'
                    },
                ]
            }
        ];
    }
    WarehouseComponent.prototype.ngOnInit = function () {
        var _this = this;
        var childActive = function (items) {
            if (items === undefined) {
                return false;
            }
            return items.filter(function (item) { return _this.router.url.indexOf(item.link) !== -1; }).length > 0;
        };
        this.menu = this.menu.map(function (item) {
            return __assign({}, item, { expanded: childActive(item.children) });
        });
    };
    return WarehouseComponent;
}());
export { WarehouseComponent };
