import { Action, createAction, props, union } from '@ngrx/store';
import { FileResponse } from './services/files.service';
import { TreeNode } from './reports.models';

export const InitialPageLoad = createAction(
  '[Reports Page] Initial Page Load',
);

export const PageLoadPath = createAction(
  '[Reports Page] Load Path',
  props<{ path: string }>(),
);

export const DownloadFile = createAction(
  '[Reports Page] Download File',
  props<{ file: FileResponse }>(),
);

export const ROUTE_SELECT_FILE = createAction(
  '[Reports Page] Set Path',
  props<{ path: string }>(),
);

export const LOAD_ROOT_FILE = createAction(
  '[Files] LoadRootFile',
);

export const ROUTE_SELECT_DIRECTORY = createAction(
  '[Files] RouteSelectDirectory',
  props<{ path: string }>(),
);
export const TREE_SELECT_FILE = createAction(
  '[Reports Page File Tree] Select File',
  props<{ node: TreeNode }>(),
);
export const TREE_EXPAND_FILE = createAction(
  '[Reports Page File Tree] Expand Directory',
  props<{ node: TreeNode }>(),
);
export const FileListSelectFile = createAction(
  '[Reports Page File List] Select File',
  props<{ file: FileResponse }>(),
);
export const FILELIST_LOAD_FILE = createAction(
  '[FileList] LoadFile',
  props<{ path: string }>(),
);

export const FILELIST_LOAD_DIRECTORY = createAction(
  '[FileList] LoadDirectory',
  props<{ path: string }>(),
);
export const FILELIST_LOAD_DIRECTORY_SUCCESS = createAction(
  '[FileList] LoadDirectorySuccess',
  props<{ file: FileResponse }>(),
);
export const FILELIST_LOAD_DIRECTORY_FAILURE = createAction(
  '[FileList] LoadDirectoryFailure',
  props<{ error: any }>(),
);

export const FILETREE_LOAD = createAction(
  '[FileTree] LoadTree',
  props<{ path: string }>(),
);

export const APIGetFileSuccess = createAction(
  '[Reports API] LoadFileSuccess',
  props<{ file: FileResponse }>(),
);
export const APIGetFileFailure = createAction(
  '[Reports API] LoadFileFailure',
  props<{ error: any }>(),
);

export const APIGetFileTreeSuccess = createAction(
  '[Reports Tree API] Get Success',
  props<{ file: FileResponse }>(),
);
export const APIGetFileTreeFailure = createAction(
  '[Reports Tree API] Get Failure',
  props<{ error: any }>(),
);

const all = union({
  LOAD_ROOT_FILE,
  ROUTE_SELECT_FILE,
  ROUTE_SELECT_DIRECTORY,
  TREE_SELECT_FILE,
  TREE_EXPAND_FILE,
  LIST_SELECT_FILE: FileListSelectFile,
  FILELIST_LOAD_FILE,
  FILELIST_LOAD_FILE_SUCCESS: APIGetFileSuccess,
  FILELIST_LOAD_FILE_FAILURE: APIGetFileFailure,
  FILELIST_LOAD_DIRECTORY,
  FILELIST_LOAD_DIRECTORY_SUCCESS,
  FILELIST_LOAD_DIRECTORY_FAILURE,
  FILETREE_LOAD,
  FILETREE_LOAD_SUCCESS: APIGetFileTreeSuccess,
  FILETREE_LOAD_FAILURE: APIGetFileTreeFailure,
  PageLoadPath,
  DownloadFile,
});

export type ReportsActionsUnion = typeof all;
