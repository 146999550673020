import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { EdiLocation } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private api: ApiService) { }

  list() {
    return this.api.get<EdiLocation[] | null>('/edi/locations');
  }

  get(code: string) {
    return this.api.get<EdiLocation>('/edi/locations/' + code);
  }

  create(location: EdiLocation) {
    return this.api.post<EdiLocation, EdiLocation>('/edi/locations', location);
  }

  update(location: EdiLocation) {
    return this.api.post<EdiLocation, EdiLocation>('/edi/locations/' + location.Code, location);
  }

  delete(code: string) {
    return this.api.delete<boolean>('/edi/locations/' + code);
  }
}
