import { EdiOrderResponse } from '../models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrderActions } from '../actions/edi';

export interface State extends EntityState<EdiOrderResponse> {
  loading: boolean;
  error: any | null;
  selectedOrderID: string | null;
}

const ordersAdapter: EntityAdapter<EdiOrderResponse> = createEntityAdapter<EdiOrderResponse>({
  selectId: (order) => order.ID,
  sortComparer: false,
});


export const initialState = ordersAdapter.getInitialState({
  selectedOrderID: null,
  loading: false,
  error: null,
});

export function reducer(state = initialState, action: OrderActions.ActionsUnion): State {
  switch (action.type) {
    case OrderActions.APIListSuccess.type:
      return ordersAdapter.addAll(action.orders, state);
    case OrderActions.APIListFailure.type:
      return {
        ...state,
        error: action.error,
      };
    case OrderActions.DetailPageLoad.type:
      return {
        ...state,
        selectedOrderID: action.id,
      };
    case OrderActions.APIGetSuccess.type:
      return {
        ...ordersAdapter.addOne(action.order, state),
      };
    case OrderActions.APIGetFailure.type:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export const selectSelectedOrderID = (state: State) => state.selectedOrderID;
export const selectOrdersLoading = (state: State) => state.loading;

export const {
  selectIds: selectOrderIDs,
  selectEntities: selectOrders,
  selectAll: selectOrdersList,
  selectTotal: selectOrdersTotal,
} = ordersAdapter.getSelectors();
