import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, of, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { tap, map, switchMap, distinctUntilChanged, catchError, take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { EdiLocation } from '../../../models';
import { DialogService } from '../../../../services/dialog.service';
import { LocationActions } from '../../../actions/edi';

import { selectEdiLocationSelected, selectEdiLocationsState } from '../../../reducers';

@Component({
  selector: 'app-locations-details',
  templateUrl: './locations-details.component.html',
  styleUrls: ['./locations-details.component.css']
})
export class LocationsDetailsComponent implements OnInit, OnDestroy {

  location$ = this.store.pipe(
    select(selectEdiLocationSelected),
  );

  editing$ = this.store.pipe(
    select(selectEdiLocationsState),
    map(state => state.editing),
  );

  id$ = this.route.paramMap.pipe(
    map(params => {
      return params.get('id');
    }),
  );

  error = null;

  initialLocation: EdiLocation;
  form = new FormControl({ value: {} });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        const edit = +params.get('edit') === 1;
        return { id, edit };
      }),
      distinctUntilChanged(),
      tap(params => {
        this.store.dispatch(LocationActions.DetailPageLoad(params));
      })
    ).subscribe();

    this.location$.subscribe((location) => {
      this.initialLocation = location;
      this.form.reset(this.initialLocation);
    });

  }

  ngOnDestroy() {
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.dirty) {
      return true;
    }

    return this.editing$.pipe(
      switchMap(editing => {
        if (editing) {
          return this.dialogService.confirm('Do you want to discard your changes?').pipe(
            tap((discard) => {
              if (discard) {
                this.form.reset(this.initialLocation);
              }
            })
          );
        }

        return of(true);
      })
    );
  }

  edit() {
    const params = {
      ...this.route.snapshot.params,
      edit: 1,
    };
    this.router.navigate(['./', params], { relativeTo: this.route });
  }

  cancel() {
    const params = {
      ...this.route.snapshot.params,
      edit: 0,
    };
    this.router.navigate(['./', params], { relativeTo: this.route });
  }

  save() {
    const updatedValue = this.form.value;
    this.store.dispatch(
      LocationActions.DetailPageSaveButton({ location: updatedValue }),
    );
  }

  delete(location: EdiLocation) {
    this.dialogService.confirm(`Are you sure you want to delete location ${location.Code}?`).pipe(
      take(1),
      tap( (cont) => {
        if (cont) {
          this.store.dispatch(LocationActions.DetailPageDeleteButton({ locations: [location] }));
        }
      }),
    ).subscribe();
  }


}
