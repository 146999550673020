var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as Anzio from './actions';
export var featureName = 'anzio';
export var initialState = {
    connectionState: 'disconnected',
    loading: true,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case Anzio.CONNECT:
            return __assign({}, state, { error: null });
        case Anzio.CONNECT_SUCCESS:
            return __assign({}, state, { session: action.payload });
        case Anzio.SESSION_STATUS:
            return __assign({}, state, { connectionState: action.payload, session: action.payload === 'disconnected' ? null : state.session });
        case Anzio.SESSION_ERROR:
            return __assign({}, state, { error: action.payload, session: null });
        default: {
            return state;
        }
    }
}
export var selectAnzioState = createFeatureSelector(featureName);
export var getConnected = createSelector(selectAnzioState, function (state) { return state.connectionState === 'connected'; });
export var getConnectionState = createSelector(selectAnzioState, function (state) { return state.connectionState; });
export var getError = createSelector(selectAnzioState, function (state) { return state.error; });
export var getSession = createSelector(selectAnzioState, function (state) { return state.session; });
