import { PipeTransform } from '@angular/core';
var CivilDatePipe = /** @class */ (function () {
    function CivilDatePipe() {
    }
    CivilDatePipe.prototype.transform = function (value) {
        if (value.HasTime) {
            return value.Date + " " + value.Time;
        }
        return value.Date;
    };
    return CivilDatePipe;
}());
export { CivilDatePipe };
