import { Pipe, PipeTransform } from '@angular/core';

export interface CivilDate {
  Date: string;
  Time: string;
  HasTime: boolean;
}

@Pipe({
  name: 'civilDate'
})
export class CivilDatePipe implements PipeTransform {

  transform(value: CivilDate): string {
    if (value.HasTime) {
      return `${value.Date} ${value.Time}`;
    }

    return value.Date;
  }

}
