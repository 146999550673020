import { Store } from '@ngrx/store';
import { AppState } from './reducers';
import { APP_ROUTES } from './app-routing.module';
import { LoadMenus } from './menu.actions';
function mapRouteToMenu(parentPath) {
    if (parentPath === void 0) { parentPath = ''; }
    return function (route) {
        if (!route.data) {
            return undefined;
        }
        var path = parentPath + route.path;
        return {
            name: route.data.menuName,
            path: path,
            requiredPermissions: route.data.requiredPermissions,
            children: mapRoutesToMenu(path, route.children),
        };
    };
}
function mapRoutesToMenu(parentPath, routes) {
    if (parentPath === void 0) { parentPath = ''; }
    return routes.map(mapRouteToMenu(parentPath)).filter(function (item) { return item !== undefined; });
}
export function initApplication(store) {
    return function () { return new Promise(function (resolve) {
        var menu = mapRoutesToMenu('', APP_ROUTES);
        store.dispatch(new LoadMenus({ data: menu }));
        resolve(true);
    }); };
}
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
