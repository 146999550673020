import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { ClarityModule, ClrFormsModule } from '@clr/angular';

import { TerminalComponent } from './components/terminal/terminal.component';

import { AnzioEffects } from './effects';

import { reducer, featureName } from './reducers';

import { AnzioWebComponent } from './containers/anzio-web/anzio-web.component';
import { RouterModule } from '@angular/router';
import { AnzioComponent } from './anzio.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ClarityModule,
    ClrFormsModule,
    RouterModule,
    StoreModule.forFeature(featureName, reducer),
    EffectsModule.forFeature([AnzioEffects])
  ],
  declarations: [
    TerminalComponent,
    AnzioWebComponent,
    AnzioComponent
  ],
})
export class AnzioModule { }
