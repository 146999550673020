<div class="model-header">
  <div class="clr-row">
    <div class="clr-col-12 clr-col-md-8">
      <h3>EDI Stock Translations
      </h3>
    </div>
  </div>
  <div class="clr-row">
    <div class="clr-col-12">
      <clr-button-group class="model-actions btn-primary btn-sm">
        <clr-button (click)="create()">Create</clr-button>
      </clr-button-group>
      <clr-button-group *ngIf="selectedIDs?.length > 0" class="model-actions btn-primary btn-sm">
        <clr-button (click)="delete(selectedIDs)">Delete ({{ selectedIDs.length }})</clr-button>
      </clr-button-group>
    </div>
  </div>
</div>
<clr-datagrid class="orders" [clrDgLoading]="loading$ | async" [clrDgSelected]='selectedIDs'
  (clrDgSelectedChange)="selectionChanged($event)">
  <clr-dg-column [clrDgSortBy]="'DebtorCode'">Debtor Code (Stock Code)</clr-dg-column>
  <clr-dg-column [clrDgSortBy]="'TUN'">TUN</clr-dg-column>
  <clr-dg-row *clrDgItems="let location of list$ | async" [clrDgItem]="location">
    <clr-dg-cell><a [routerLink]="detailLink(location)">{{location.DebtorCode}} ({{location.StockCode}})</a></clr-dg-cell>
    <clr-dg-cell>{{location.TUN}}</clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>{{(list$ | async)?.length}}</clr-dg-footer>
</clr-datagrid>
