import { StoreModule, ActionReducer, MetaReducer, Action, createFeatureSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { storeFreeze } from 'ngrx-store-freeze';
import { MenuItem } from './models';
import { MenuActions, MenuActionTypes } from './menu.actions';
import { environment } from '../environments/environment';

export const initialAppState = {
  menu: [],
};

export interface AppState {
  menu: MenuItem[];
  router: fromRouter.RouterReducerState;
}

export function menuReducer(state: MenuItem[] = initialAppState.menu, action: MenuActions): MenuItem[] {
  switch (action.type) {
    case MenuActionTypes.LoadMenus: {
      return action.payload.data;
    }
  }

  return state;
}

export const selectMenu = createFeatureSelector<MenuItem[]>('menu');

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log('state', state);
    console.log('action', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze, debug] : [];
