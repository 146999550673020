import { ApiService } from '../../services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api.service";
var LocationsService = /** @class */ (function () {
    function LocationsService(api) {
        this.api = api;
    }
    LocationsService.prototype.list = function () {
        return this.api.get('/edi/locations');
    };
    LocationsService.prototype.get = function (code) {
        return this.api.get('/edi/locations/' + code);
    };
    LocationsService.prototype.create = function (location) {
        return this.api.post('/edi/locations', location);
    };
    LocationsService.prototype.update = function (location) {
        return this.api.post('/edi/locations/' + location.Code, location);
    };
    LocationsService.prototype.delete = function (code) {
        return this.api.delete('/edi/locations/' + code);
    };
    LocationsService.ngInjectableDef = i0.defineInjectable({ factory: function LocationsService_Factory() { return new LocationsService(i0.inject(i1.ApiService)); }, token: LocationsService, providedIn: "root" });
    return LocationsService;
}());
export { LocationsService };
