import { ApiService } from '../../services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api.service";
var FilesService = /** @class */ (function () {
    function FilesService(api) {
        this.api = api;
    }
    FilesService.prototype.get = function (path) {
        return this.api.get('user/files/' + path);
    };
    FilesService.prototype.getTree = function (path) {
        return this.api.get('user/tree/' + path);
    };
    FilesService.prototype.downloadFile = function (file) {
        var f = new File([file.contents], file.name, {
            type: 'text/plain',
            lastModified: new Date(file.modified).valueOf(),
        });
        var url = window.URL.createObjectURL(f);
        var link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
    FilesService.ngInjectableDef = i0.defineInjectable({ factory: function FilesService_Factory() { return new FilesService(i0.inject(i1.ApiService)); }, token: FilesService, providedIn: "root" });
    return FilesService;
}());
export { FilesService };
