import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { FileResponse } from '../../services/files.service';

@Component({
  selector: 'app-fileview',
  templateUrl: './fileview.component.html',
  styleUrls: ['./fileview.component.css']
})
export class FileviewComponent implements OnInit {

  @Input() file: FileResponse;

  constructor() { }

  ngOnInit() {
  }

}
