import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { selectEdiLocations, selectEdiLocationList, selectEdiLocationsLoading } from '../../../reducers';
import { LocationActions } from '../../../actions/edi';
import { EdiLocation } from '../../../models';
import { DialogService } from '../../../../services/dialog.service';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.css']
})
export class LocationsListComponent implements OnInit {

  list$ = this.store.pipe(
    select(selectEdiLocationList),
  );
  loading$ = this.store.pipe(
    select(selectEdiLocationsLoading),
  );

  selectedIDs: EdiLocation[] = [];

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.store.dispatch(LocationActions.ListPageLoad());
  }

  selectionChanged(selected: EdiLocation[]) {
    this.selectedIDs = selected;
  }

  detailLink(location: EdiLocation) {
    return ['detail', {id: location.Code}];
  }

  create() {
    this.router.navigate(['create'], { relativeTo: this.route});
  }

  delete(locations: EdiLocation[] = []) {
    this.dialogService.confirm(`Are you sure you want to delete ${locations.length} locations?`).pipe(
      take(1),
      tap( (cont) => {
        console.log(cont);
        if (cont) {
          this.store.dispatch(LocationActions.ListPageDeleteButton({ locations }));
        }
      }),
    ).subscribe();
  }

}
