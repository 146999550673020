import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { OrdersService } from '../services/orders.service';

import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { OrderActions } from '../actions/edi';

@Injectable()
export class OrdersEffects {


  @Effect()
  loadOrderList$ = this.actions$
    .pipe(
      ofType(OrderActions.ListPageLoad.type),
      mergeMap(() => this.ordersService.list().pipe(
        map(orders => OrderActions.APIListSuccess({ orders })),
        catchError((error) => of(OrderActions.APIListFailure({ error }))),
      ))
    );

  @Effect()
  loadOrderDetail$ = this.actions$
    .pipe(
      ofType(OrderActions.DetailPageLoad.type),
      mergeMap((action) => {
        return this.ordersService.get(action.id).pipe(
          map(order => OrderActions.APIGetSuccess({ order })),
          catchError((error) => of(OrderActions.APIGetFailure({ error })))
        );
      }),
    );

  constructor(
    private actions$: Actions<OrderActions.ActionsUnion>,
    private ordersService: OrdersService
  ) { }

}
