import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { EdiOrderResponse } from '../models';

export interface OrderFilter {
  from?: Date;
  to?: Date;
  debtor?: string;
}

function convertFilterToParams(filter: OrderFilter = {}) {
  return {
    from: filter.from ? filter.from.toISOString() : undefined,
    to: filter.to ? filter.to.toISOString() : undefined,
    debtor: filter.debtor,
  };
}

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private api: ApiService) { }

  list(filter?: OrderFilter) {
    const options = filter ?
      {
        params: convertFilterToParams(filter),
      } : {};
    return this.api.get<EdiOrderResponse[]>('edi/orders', options);
  }

  get(id: string) {
    return this.api.get<EdiOrderResponse>('edi/orders/' + id);
  }

  fetchNewOrders() {
    return this.api.post('edi/rpc/fetch', {});
  }

  acknowledgeAll() {
    return this.api.post('edi/rpc/acknowledge', {});
  }

  acknowledge(id: string) {
    return this.api.post<EdiOrderResponse, {}>('edi/rpc/acknowledge/' + id, {});
  }

}
