var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, filter, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ReportsActions from './reports.actions';
import { Router } from '@angular/router';
import { FilesService } from './services/files.service';
var ReportsEffects = /** @class */ (function () {
    function ReportsEffects(actions$, router, fileService) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.fileService = fileService;
        this.selectFile = this.actions$.pipe(ofType(ReportsActions.FileListSelectFile.type), map(function (action) {
            var file = action.file;
            _this.router.navigate(["/reports" + file.path]);
        }));
        this.selectTreeFile = this.actions$.pipe(ofType(ReportsActions.TREE_SELECT_FILE.type), map(function (action) {
            var node = action.node;
            _this.router.navigate(["/reports" + node.file.path]);
        }));
        this.loadDirectoryTree = this.actions$.pipe(ofType(ReportsActions.APIGetFileSuccess.type), filter(function (action) { return action.file.isDirectory; }), switchMap(function (action) {
            return _this.fileService.getTree(action.file.path).pipe(map(function (file) { return ReportsActions.APIGetFileTreeSuccess({ file: file }); }), catchError(function (error) { return of(ReportsActions.APIGetFileTreeFailure({ error: error })); }));
        }));
        this.loadRootTree = this.actions$.pipe(ofType(ReportsActions.InitialPageLoad.type), switchMap(function () {
            return _this.fileService.getTree('/').pipe(map(function (file) { return ReportsActions.APIGetFileTreeSuccess({ file: file }); }), catchError(function (error) { return of(ReportsActions.APIGetFileTreeFailure({ error: error })); }));
        }));
        this.loadFromRoute = this.actions$.pipe(ofType(ReportsActions.PageLoadPath.type), mergeMap(function (action) {
            var path = action.path;
            return _this.fileService.get(path).pipe(map(function (file) { return ReportsActions.APIGetFileSuccess({ file: file }); }), catchError(function (error) { return of(ReportsActions.APIGetFileFailure({ error: error })); }));
        }));
        this.downloadFile$ = this.actions$.pipe(ofType(ReportsActions.DownloadFile.type), map(function (action) {
            var file = action.file;
            return _this.fileService.downloadFile(file);
        }));
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], ReportsEffects.prototype, "selectFile", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], ReportsEffects.prototype, "selectTreeFile", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ReportsEffects.prototype, "loadDirectoryTree", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ReportsEffects.prototype, "loadRootTree", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ReportsEffects.prototype, "loadFromRoute", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], ReportsEffects.prototype, "downloadFile$", void 0);
    return ReportsEffects;
}());
export { ReportsEffects };
