var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
var FiletreeComponent = /** @class */ (function () {
    function FiletreeComponent() {
        this.lazyLoad = new EventEmitter();
        this.expand = new EventEmitter();
        this.select = new EventEmitter();
    }
    FiletreeComponent.prototype.ngOnInit = function () {
        if (this.lazyLoad) {
            this.lazyLoad.emit();
        }
    };
    FiletreeComponent.prototype.ngOnChanges = function (changes) {
    };
    FiletreeComponent.prototype.isActive = function (node) {
        if (node === undefined || node === null) {
            return false;
        }
        if (!this.currentFile) {
            return false;
        }
        if (this.currentFile.isDirectory) {
            return this.currentFile.path === node.file.path;
        }
        var lastSep = this.currentFile.path.lastIndexOf('/');
        if (lastSep === -1) {
            return false;
        }
        if (lastSep === 0) {
            return node.file.path === '/';
        }
        return this.currentFile.path.substring(0, lastSep) === node.file.path;
    };
    FiletreeComponent.prototype.isExpanded = function (node) {
        if (node === undefined || node === null) {
            return false;
        }
        if (node.expanded !== undefined) {
            return node.expanded;
        }
        if (!this.currentFile) {
            return false;
        }
        return this.currentFile.path.indexOf(node.file.path) !== -1 ? true : node.expanded;
    };
    FiletreeComponent.prototype.isExpandable = function (node) {
        if (node === undefined || node === null) {
            return undefined;
        }
        if (node.file.path === '/') {
            return false;
        }
        return undefined;
    };
    FiletreeComponent.prototype.handleExpand = function (node, expanded) {
        this.expand.emit(__assign({}, node, { expanded: expanded }));
    };
    FiletreeComponent.prototype.handleSelect = function (node) {
        this.select.emit(node);
    };
    FiletreeComponent.prototype.getChildren = function (node) {
        return node.children;
    };
    return FiletreeComponent;
}());
export { FiletreeComponent };
