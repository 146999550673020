/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./warehouse.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@clr/angular";
import * as i3 from "../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./warehouse.component";
var styles_WarehouseComponent = [i0.styles];
var RenderType_WarehouseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WarehouseComponent, data: {} });
export { RenderType_WarehouseComponent as RenderType_WarehouseComponent };
function View_WarehouseComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "clr-icon", [["class", "nav-icon"], ["clrVerticalNavIcon", ""]], [[1, "shape", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), i1.ɵdid(2, 147456, null, 0, i2.ClrVerticalNavIcon, [i2.ɵee], null, null)], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.icon; _ck(_v, 0, 0, currVal_0); }); }
function View_WarehouseComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "clr-icon", [["class", "nav-icon"], ["clrVerticalNavIcon", ""]], [[1, "shape", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), i1.ɵdid(2, 147456, null, 0, i2.ClrVerticalNavIcon, [i2.ɵee], null, null)], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 0, 0, currVal_0); }); }
function View_WarehouseComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["class", "nav-link"], ["clrVerticalNavLink", ""], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).expandParentNavGroup() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ClrVerticalNavLink_0, i3.RenderType_ClrVerticalNavLink)), i1.ɵdid(1, 49152, null, 0, i2.ClrVerticalNavLink, [[2, i2.ɵeg]], null, null), i1.ɵdid(2, 671744, [[4, 4], [2, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_WarehouseComponent_5)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(8, 1, [" ", " "]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.link; _ck(_v, 2, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 3, 0, currVal_3); var currVal_4 = _v.context.$implicit.icon; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_5); }); }
function View_WarehouseComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "clr-vertical-nav-group", [["class", "nav-group"], ["routerLinkActive", "active"]], [[2, "is-expanded", null]], null, null, i3.View_ClrVerticalNavGroup_0, i3.RenderType_ClrVerticalNavGroup)), i1.ɵprd(512, null, i2.ɵcl, i2.ɵcl, []), i1.ɵprd(512, null, i2.ɵeg, i2.ɵeg, []), i1.ɵdid(3, 1228800, null, 0, i2.ClrVerticalNavGroup, [i2.ɵcl, i2.ɵef, i2.ɵeg, i2.ɵed, i2.ClrCommonStrings], { userExpandedInput: [0, "userExpandedInput"] }, null), i1.ɵdid(4, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_WarehouseComponent_3)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(9, 2, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, 3, 3, "clr-vertical-nav-group-children", [], null, null, null, i3.View_ClrVerticalNavGroupChildren_0, i3.RenderType_ClrVerticalNavGroupChildren)), i1.ɵdid(11, 49152, null, 0, i2.ClrVerticalNavGroupChildren, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_WarehouseComponent_4)), i1.ɵdid(13, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.expanded; _ck(_v, 3, 0, currVal_1); var currVal_2 = "active"; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.icon; _ck(_v, 8, 0, currVal_3); var currVal_5 = _v.parent.context.$implicit.children; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).expanded; _ck(_v, 0, 0, currVal_0); var currVal_4 = _v.parent.context.$implicit.name; _ck(_v, 9, 0, currVal_4); }); }
function View_WarehouseComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "clr-icon", [["class", "nav-icon"], ["clrVerticalNavIcon", ""]], [[1, "shape", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), i1.ɵdid(2, 147456, null, 0, i2.ClrVerticalNavIcon, [i2.ɵee], null, null)], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.icon; _ck(_v, 0, 0, currVal_0); }); }
function View_WarehouseComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["class", "nav-link"], ["clrVerticalNavLink", ""], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).expandParentNavGroup() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ClrVerticalNavLink_0, i3.RenderType_ClrVerticalNavLink)), i1.ɵdid(1, 49152, null, 0, i2.ClrVerticalNavLink, [[2, i2.ɵeg]], null, null), i1.ɵdid(2, 671744, [[6, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_WarehouseComponent_7)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(8, 1, [" ", " "]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.link; _ck(_v, 2, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 3, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.icon; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.parent.context.$implicit.name; _ck(_v, 8, 0, currVal_5); }); }
function View_WarehouseComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WarehouseComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["single", 2]], null, 0, null, View_WarehouseComponent_6))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.children; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_WarehouseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "clr-vertical-nav", [["class", "nav-trigger--bottom clr-vertical-nav"]], [[2, "is-collapsed", null], [2, "has-nav-groups", null], [2, "has-icons", null]], [[null, "clrVerticalNavCollapsedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clrVerticalNavCollapsedChange" === en)) {
        var pd_0 = ((_co.collapsed = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ClrVerticalNav_0, i3.RenderType_ClrVerticalNav)), i1.ɵprd(512, null, i2.ɵed, i2.ɵed, []), i1.ɵprd(512, null, i2.ɵee, i2.ɵee, []), i1.ɵprd(512, null, i2.ɵef, i2.ɵef, []), i1.ɵdid(7, 180224, null, 0, i2.ClrVerticalNav, [i2.ɵed, i2.ɵee, i2.ɵef, i2.ClrCommonStrings], { collapsible: [0, "collapsible"], collapsed: [1, "collapsed"] }, { _collapsedChanged: "clrVerticalNavCollapsedChange" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_WarehouseComponent_1)), i1.ɵdid(9, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_3 = true; var currVal_4 = _co.collapsed; _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = _co.menu; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).collapsed; var currVal_1 = i1.ɵnov(_v, 7).hasNavGroups; var currVal_2 = i1.ɵnov(_v, 7).hasIcons; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_WarehouseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-warehouse", [], null, null, null, View_WarehouseComponent_0, RenderType_WarehouseComponent)), i1.ɵdid(1, 114688, null, 0, i6.WarehouseComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WarehouseComponentNgFactory = i1.ɵccf("app-warehouse", i6.WarehouseComponent, View_WarehouseComponent_Host_0, {}, {}, []);
export { WarehouseComponentNgFactory as WarehouseComponentNgFactory };
