import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { getUser } from '../../auth';
import { take, map, shareReplay } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var Session = /** @class */ (function () {
    function Session(server, user, password) {
        this.server = server;
        this.user = user;
        this.password = password;
        this.authenticated = false;
        this._statusEvents = new BehaviorSubject('disconnected');
        this._stdout = new Subject();
        this.stdout = this._stdout.asObservable();
        this.status = this._statusEvents
            .asObservable()
            .pipe(shareReplay(1));
        var socketUrl = this.server + "/ssh?idToken=" + this.user.idToken;
        this.socket = new WebSocket(socketUrl);
        this._statusEvents.next('connecting');
        this.socket.onopen = this.onOpen.bind(this);
        this.socket.onmessage = this.onMessage.bind(this);
        this.socket.onerror = this.onError.bind(this);
        this.socket.onclose = this.onClose.bind(this);
    }
    Session.prototype.onMessage = function (event) {
        var message = JSON.parse(event.data);
        switch (message.type) {
            case 'auth_request':
                this.socket.send(JSON.stringify({
                    type: 'auth_response',
                    data: this.user.email.split('@')[0] + ':' + this.password
                }));
                break;
            case 'auth_success':
                this.authenticated = true;
                this._statusEvents.next('connected');
                break;
            case 'stdout':
                this._stdout.next(message.data);
                break;
            case 'error':
                this._statusEvents.next('disconnected');
                this._statusEvents.error(message.data);
                break;
        }
    };
    Session.prototype.onOpen = function (event) {
        this._statusEvents.next('authenticating');
    };
    Session.prototype.onError = function (event) {
        this._statusEvents.error(event);
    };
    Session.prototype.onClose = function (event) {
        this._statusEvents.next('disconnected');
        if (event.wasClean || event.code === 1000) {
            this._statusEvents.complete();
        }
        else {
            this._statusEvents.error(event.reason);
        }
        this._stdout.complete();
    };
    Session.prototype.send = function (data) {
        this.socket.send(JSON.stringify({ type: 'stdin', data: data }));
    };
    Session.prototype.close = function () {
        this.socket.close();
    };
    return Session;
}());
export { Session };
var TerminalService = /** @class */ (function () {
    function TerminalService(store) {
        this.store = store;
    }
    TerminalService.prototype.createSession = function (server, password) {
        return this.store.pipe(getUser, take(1), map(function (user) {
            return new Session(server, user, password);
        }));
    };
    TerminalService.ngInjectableDef = i0.defineInjectable({ factory: function TerminalService_Factory() { return new TerminalService(i0.inject(i1.Store)); }, token: TerminalService, providedIn: "root" });
    return TerminalService;
}());
export { TerminalService };
