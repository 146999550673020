/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order-detail.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/edi/orders/detail.component.ngfactory";
import * as i3 from "../../../components/edi/orders/detail.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i7 from "@clr/angular";
import * as i8 from "./order-detail.component";
import * as i9 from "@ngrx/store";
var styles_OrderDetailContainer = [i0.styles];
var RenderType_OrderDetailContainer = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderDetailContainer, data: {} });
export { RenderType_OrderDetailContainer as RenderType_OrderDetailContainer };
function View_OrderDetailContainer_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "model-detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "edi-orders-detail", [], null, null, null, i2.View_EdiOrdersDetailComponent_0, i2.RenderType_EdiOrdersDetailComponent)), i1.ɵdid(3, 114688, null, 0, i3.EdiOrdersDetailComponent, [], { order: [0, "order"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "pre", [["class", "notes"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(0, i4.JsonPipe, [])], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.ngIf)); _ck(_v, 5, 0, currVal_1); }); }
function View_OrderDetailContainer_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "spinner spinner-inline"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Loading... "]))], null, null); }
export function View_OrderDetailContainer_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "model-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["routerLink", "../"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["EDI Orders"])), (_l()(), i1.ɵted(5, null, [" / ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "clr-button-group", [["class", "model-actions btn-primary btn-sm"]], [[2, "btn-group", null]], [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onMouseClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ClrButtonGroup_0, i6.RenderType_ClrButtonGroup)), i1.ɵprd(512, null, i7.ɵdr, i7.ɵdr, []), i1.ɵdid(8, 1097728, null, 1, i7.ClrButtonGroup, [i7.ɵdr, i1.ElementRef, i7.ClrCommonStrings], null, null), i1.ɵqud(603979776, 1, { buttons: 1 }), (_l()(), i1.ɵeld(10, 0, null, null, 3, "clr-button", [], null, null, null, i6.View_ClrButton_0, i6.RenderType_ClrButton)), i1.ɵprd(6144, null, i7.LoadingListener, null, [i7.ClrButton]), i1.ɵdid(12, 4243456, [[1, 4]], 0, i7.ClrButton, [[3, i7.ɵdr]], null, null), (_l()(), i1.ɵted(-1, 0, ["Validate"])), (_l()(), i1.ɵeld(14, 0, null, null, 7, "clr-button-group", [["class", "model-actions btn-primary btn-sm"]], [[2, "btn-group", null]], [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onMouseClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ClrButtonGroup_0, i6.RenderType_ClrButtonGroup)), i1.ɵprd(512, null, i7.ɵdr, i7.ɵdr, []), i1.ɵdid(16, 1097728, null, 1, i7.ClrButtonGroup, [i7.ɵdr, i1.ElementRef, i7.ClrCommonStrings], null, null), i1.ɵqud(603979776, 2, { buttons: 1 }), (_l()(), i1.ɵeld(18, 0, null, null, 3, "clr-button", [], null, null, null, i6.View_ClrButton_0, i6.RenderType_ClrButton)), i1.ɵprd(6144, null, i7.LoadingListener, null, [i7.ClrButton]), i1.ɵdid(20, 4243456, [[2, 4]], 0, i7.ClrButton, [[3, i7.ɵdr]], null, null), (_l()(), i1.ɵted(-1, 0, ["Generate Pickslip"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_OrderDetailContainer_1)), i1.ɵdid(23, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_OrderDetailContainer_2))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "../"; _ck(_v, 3, 0, currVal_2); var currVal_6 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform(_co.order$)); var currVal_7 = i1.ɵnov(_v, 25); _ck(_v, 23, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.id; _ck(_v, 5, 0, currVal_3); var currVal_4 = true; _ck(_v, 6, 0, currVal_4); var currVal_5 = true; _ck(_v, 14, 0, currVal_5); }); }
export function View_OrderDetailContainer_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order-detail", [], null, null, null, View_OrderDetailContainer_0, RenderType_OrderDetailContainer)), i1.ɵdid(1, 245760, null, 0, i8.OrderDetailContainer, [i5.ActivatedRoute, i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrderDetailContainerNgFactory = i1.ɵccf("app-order-detail", i8.OrderDetailContainer, View_OrderDetailContainer_Host_0, {}, {}, []);
export { OrderDetailContainerNgFactory as OrderDetailContainerNgFactory };
