<div class="content-container">
  <router-outlet></router-outlet>
  <clr-vertical-nav [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed"
    class="nav-trigger--bottom">
    <ng-container *ngFor="let item of menu">
      <clr-vertical-nav-group *ngIf="item.children; else single"
        routerLinkActive="active"
        [clrVerticalNavGroupExpanded]="item.expanded">
        <clr-icon *ngIf="item.icon" clrVerticalNavIcon [attr.shape]="item.icon"></clr-icon>
        {{ item.name }}
        <clr-vertical-nav-group-children>
          <a *ngFor="let child of item.children" clrVerticalNavLink [routerLink]="child.link" routerLinkActive="active">
            <clr-icon *ngIf="child.icon" clrVerticalNavIcon [attr.shape]="child.icon"></clr-icon>
            {{ child.name }}
          </a>
        </clr-vertical-nav-group-children>
      </clr-vertical-nav-group>
      <ng-template #single>
        <a clrVerticalNavLink [routerLink]="item.link" routerLinkActive="active">
          <clr-icon *ngIf="item.icon" clrVerticalNavIcon [attr.shape]="item.icon"></clr-icon>
          {{ item.name }}
        </a>
      </ng-template>
    </ng-container>
  </clr-vertical-nav>
</div>
