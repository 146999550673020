import { Component, OnInit, Input } from '@angular/core';
import { EdiOrderResponse } from '../../../models';

@Component({
  selector: 'edi-orders-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class EdiOrdersListComponent implements OnInit {

  @Input() orders: EdiOrderResponse[];
  @Input() loading: boolean;

  constructor() { }

  ngOnInit() {
  }

  orderLink(order: EdiOrderResponse) {
    return [order.ID];
  }

}
