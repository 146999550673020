import { Injectable, NgZone } from '@angular/core';
import { from, Observable } from 'rxjs';

import { environment } from '../../environments/environment';

declare var gapi: any;

type GoogleAuth2 = gapi.auth2.GoogleAuth;

let scriptLoadingPromise: Promise<void>;

export function load() {

  if (scriptLoadingPromise) {
    return scriptLoadingPromise;
  }

  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  const callbackName = `googlePlatformOnLoad${new Date().getMilliseconds()}`;
  script.src = `https://apis.google.com/js/platform.js?onload=${callbackName}`;

  scriptLoadingPromise = new Promise<void>((resolve: Function, reject: Function) => {
    (<any>window)[callbackName] = () => {
      gapi.load('auth2', function () {
        gapi.auth2.init({
          client_id: environment.clientID
        }).then((auth2) => {
          resolve();
        });
      });
    };

    script.onerror = (error: Event) => { reject(error); };
  });

  document.body.appendChild(script);
  return scriptLoadingPromise;
}

@Injectable({
  providedIn: 'root'
})
export class GooglePlatformService {

  private auth2: GoogleAuth2;

  constructor(private ngZone: NgZone) {
    load().then(() => {
      this.auth2 = gapi.auth2.init({
        client_id: environment.clientID
      });
    });
  }

  currentUser() {
    return new Observable<gapi.auth2.GoogleUser>((observer) => {
      load().then(() => {
        // Start with the current user
        observer.next(this.auth2.currentUser.get());

        // Subscribe to changes.
        this.auth2.currentUser.listen((user) => {
          observer.next(user);
        });
      });
    });
  }

  login() {
    return from(load().then(() => {
      return this.auth2.signIn();
    }));
  }

  logout() {
    return from(load().then(() => {
      return this.auth2.signOut();
    }));
  }
}
