import { OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { LocationActions } from '../../../actions/edi';
import { selectEdiLocationsState } from '../../../reducers';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DialogService } from '../../../../services/dialog.service';
var CreateComponent = /** @class */ (function () {
    function CreateComponent(route, router, store, dialogService) {
        this.route = route;
        this.router = router;
        this.store = store;
        this.dialogService = dialogService;
        this.error$ = this.store.pipe(select(selectEdiLocationsState), map(function (state) { return state.error; }));
        this.saved$ = this.store.pipe(select(selectEdiLocationsState), map(function (state) { return state.saved; }));
        this.form = new FormControl({});
    }
    CreateComponent.prototype.ngOnInit = function () {
        this.store.dispatch(LocationActions.CreatePageLoad());
    };
    CreateComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(LocationActions.CreatePageUnload());
    };
    CreateComponent.prototype.canDeactivate = function () {
        var _this = this;
        if (!this.form.dirty) {
            return true;
        }
        return this.saved$.pipe(switchMap(function (saved) {
            if (!saved) {
                return _this.dialogService.confirm('Do you want to discard your changes?');
            }
            return of(true);
        }));
    };
    CreateComponent.prototype.cancel = function () {
        this.router.navigate(['../'], { relativeTo: this.route });
    };
    CreateComponent.prototype.save = function () {
        var updatedValue = this.form.value;
        this.store.dispatch(LocationActions.CreatePageCreateButton({ location: updatedValue }));
    };
    return CreateComponent;
}());
export { CreateComponent };
