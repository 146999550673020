var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LocationsService } from '../services/locations.service';
import { LocationActions } from '../actions/edi';
import { switchMap, map, catchError, tap, mergeMap, reduce } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { Router } from '@angular/router';
var LocationsEffects = /** @class */ (function () {
    function LocationsEffects(actions$, locationsService, router) {
        var _this = this;
        this.actions$ = actions$;
        this.locationsService = locationsService;
        this.router = router;
        this.loadLocationsList$ = this.actions$.pipe(ofType(LocationActions.ListPageLoad.type), switchMap(function () { return _this.locationsService.list().pipe(map(function (locations) { return LocationActions.APIListSuccess({ locations: locations || [] }); }), catchError(function (error) { return of(LocationActions.APIListFailure({ error: error })); })); }));
        this.loadLocation$ = this.actions$.pipe(ofType(LocationActions.DetailPageLoad.type), switchMap(function (action) { return _this.locationsService.get(action.id).pipe(map(function (location) { return LocationActions.APIGetSuccess({ location: location }); }), catchError(function (error) { return of(LocationActions.APIGetFailure({ error: error })); })); }));
        this.saveEdiLocation$ = this.actions$.pipe(ofType(LocationActions.DetailPageSaveButton.type), switchMap(function (action) { return _this.locationsService.update(action.location).pipe(map(function (location) { return LocationActions.APISaveSuccess({ location: location }); }), catchError(function (error) { return of(LocationActions.APISaveFailure({ error: error[0] })); })); }));
        this.createEdiLocation$ = this.actions$.pipe(ofType(LocationActions.ListPageCreateButton.type, LocationActions.CreatePageCreateButton.type), switchMap(function (action) { return _this.locationsService.create(action.location).pipe(map(function (location) { return LocationActions.APICreateSuccess({ location: location }); }), catchError(function (error) { return of(LocationActions.APICreateFailure({ error: error[0] })); })); }));
        this.updateLocationDetailPage$ = this.actions$.pipe(ofType(LocationActions.APICreateSuccess.type, LocationActions.APISaveSuccess.type), tap(function (_a) {
            var location = _a.location;
            _this.router.navigate(['warehouse/edi/locations/detail', { id: location.Code, edit: 0 }]);
        }));
        this.deleteLocations$ = this.actions$.pipe(ofType(LocationActions.ListPageDeleteButton.type, LocationActions.DetailPageDeleteButton.type), mergeMap(function (action) {
            return from(action.locations).pipe(tap(function (location) { console.log(location); }), mergeMap(function (location) { return _this.locationsService.delete(location.Code).pipe(map(function () { return location.Code; })); }), reduce(function (acc, val) {
                return acc.concat([val]);
            }, []), map(function (ids) { return LocationActions.APIDeleteSuccess({ locationIDs: ids }); }), catchError(function (error) { return of(LocationActions.APIDeleteFailure({ error: error })); }));
        }));
        this.redictedOnDelete$ = this.actions$.pipe(ofType(LocationActions.APIDeleteSuccess.type), tap(function () {
            _this.router.navigate(['warehouse/edi/locations']);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LocationsEffects.prototype, "loadLocationsList$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LocationsEffects.prototype, "loadLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LocationsEffects.prototype, "saveEdiLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LocationsEffects.prototype, "createEdiLocation$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LocationsEffects.prototype, "updateLocationDetailPage$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LocationsEffects.prototype, "deleteLocations$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LocationsEffects.prototype, "redictedOnDelete$", void 0);
    return LocationsEffects;
}());
export { LocationsEffects };
