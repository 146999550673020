import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthService } from './auth.service';
import { AuthGuardService } from './auth-guard.service';

import { AuthEffects } from './effects';

import { reducer, featureName } from './reducers';

import { LoginComponent } from './components/login.component';
import { LogoutComponent } from './components/logout.component';

import { AuthRoutingModule } from './auth-routing.module';


@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    StoreModule.forFeature(featureName, reducer),
    EffectsModule.forFeature([AuthEffects])
  ],
  declarations: [LoginComponent, LogoutComponent]
})
export class AuthModule { }

