import { ApiService } from '../../services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api.service";
function cleanCode(code) {
    return encodeURIComponent(encodeURIComponent(decodeURIComponent(code)));
}
var StocksService = /** @class */ (function () {
    function StocksService(api) {
        this.api = api;
    }
    StocksService.prototype.getUrl = function (id) {
        return '/edi/stocks/' + cleanCode(id.StockCode) + '/' + cleanCode(id.DebtorCode);
    };
    StocksService.prototype.list = function () {
        return this.api.get('/edi/stocks');
    };
    StocksService.prototype.get = function (id) {
        var url = this.getUrl(id);
        return this.api.get(url);
    };
    StocksService.prototype.create = function (stock) {
        return this.api.post('/edi/stocks', stock);
    };
    StocksService.prototype.update = function (stock) {
        var url = this.getUrl(stock);
        return this.api.post(url, stock);
    };
    StocksService.prototype.delete = function (id) {
        var url = this.getUrl(id);
        return this.api.delete(url);
    };
    StocksService.ngInjectableDef = i0.defineInjectable({ factory: function StocksService_Factory() { return new StocksService(i0.inject(i1.ApiService)); }, token: StocksService, providedIn: "root" });
    return StocksService;
}());
export { StocksService };
