<div class="content-container">
  <app-filetree [nodes]="(tree$ | async)" [currentFile]="currentFile$ | async" (expand)="handleTreeExpand($event)"
    (select)="handleTreeSelect($event)"></app-filetree>
  <div class="reports" *ngIf="currentFile$ | async as file">
    <div class="model-header">
      <div class="clr-row">
        <div class="clr-col-12 clr-col-md-8">
          <ng-container *ngIf="segments$ | async as segments">
            <h3 *ngIf="segments.length === 0">
              Reports
            </h3>
            <h3 *ngIf="segments.length > 0">
              <a [routerLink]="pathLink(segments, -1)">Reports</a>
              <span *ngFor="let segment of segments; index as i; last as isLast">
                /
                <a *ngIf="!isLast" [routerLink]="pathLink(segments, i)">{{ segment }}</a>
                <ng-container *ngIf="isLast">{{ segment }}</ng-container>
              </span>
            </h3>
          </ng-container>
        </div>
      </div>
      <div class="clr-row">
        <div class="clr-col-12">
          <ng-container *ngIf="!file.isDirectory">
            <clr-button-group class="model-actions btn-primary btn-sm">
              <clr-button (click)="download(file)">Download</clr-button>
            </clr-button-group>
          </ng-container>
          <ng-container *ngIf="file.isDirectory">
            <!-- <clr-button-group class="model-actions btn-primary btn-sm">
                  <clr-button>Delete ({{ selectedFiles?.length }})</clr-button>
              </clr-button-group> -->
          </ng-container>
        </div>
      </div>
    </div>
    <div class="model-detail files" *ngIf="currentFile$ | async as file">
      <app-filelist *ngIf="file.isDirectory" class="filelist" [files]="file.children" [(selectedFiles)]="selectedFiles">
      </app-filelist>
      <app-fileview *ngIf="!file.isDirectory" class="fileview" [file]="file"></app-fileview>
    </div>
    <ng-template #showError>
      <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
        There was an error getting the page.
      </clr-alert>
    </ng-template>
  </div>
</div>
