import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
function cleanCode(code) {
    return encodeURIComponent(encodeURIComponent(decodeURIComponent(code)));
}
var DebtorsService = /** @class */ (function () {
    function DebtorsService(api) {
        this.api = api;
    }
    DebtorsService.prototype.list = function (filter) {
        var options = filter ? {
            params: filter,
        } : {};
        return this.api.get('/debtors', options);
    };
    DebtorsService.prototype.get = function (code) {
        return this.api.get('/debtors/' + cleanCode(code));
    };
    DebtorsService.ngInjectableDef = i0.defineInjectable({ factory: function DebtorsService_Factory() { return new DebtorsService(i0.inject(i1.ApiService)); }, token: DebtorsService, providedIn: "root" });
    return DebtorsService;
}());
export { DebtorsService };
