import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { selectEdiStockTranslationList, selectEdiStockTranslationsLoading } from '../../../reducers';
import { StockTranslationActions } from '../../../actions/edi';
import { EncodeEdiStockTranslationID } from '../../../models';
import { DialogService } from '../../../../services/dialog.service';
import { take, tap } from 'rxjs/operators';
var ListComponent = /** @class */ (function () {
    function ListComponent(store, route, router, dialogService) {
        this.store = store;
        this.route = route;
        this.router = router;
        this.dialogService = dialogService;
        this.list$ = this.store.pipe(select(selectEdiStockTranslationList));
        this.loading$ = this.store.pipe(select(selectEdiStockTranslationsLoading));
        this.selectedIDs = [];
    }
    ListComponent.prototype.ngOnInit = function () {
        this.store.dispatch(StockTranslationActions.ListPageLoad());
    };
    ListComponent.prototype.selectionChanged = function (selected) {
        this.selectedIDs = selected;
    };
    ListComponent.prototype.detailLink = function (translation) {
        return ['detail', { id: EncodeEdiStockTranslationID(translation) }];
    };
    ListComponent.prototype.create = function () {
        this.router.navigate(['create'], { relativeTo: this.route });
    };
    ListComponent.prototype.delete = function (translations) {
        var _this = this;
        if (translations === void 0) { translations = []; }
        this.dialogService.confirm("Are you sure you want to delete " + translations.length + " locations?").pipe(take(1), tap(function (cont) {
            console.log(cont);
            if (cont) {
                _this.store.dispatch(StockTranslationActions.ListPageDeleteButton({ translations: translations }));
            }
        })).subscribe();
    };
    return ListComponent;
}());
export { ListComponent };
