<clr-datagrid class="orders"
    [clrDgLoading]="loading">
  <clr-dg-column [clrDgSortBy]="'ChainName'">Chain Name</clr-dg-column>
  <clr-dg-column [clrDgSortBy]="'OrderNumber'">Order Number</clr-dg-column>
  <clr-dg-column [clrDgSortBy]="'CreatedAt'">Created</clr-dg-column>
  <clr-dg-column [clrDgSortBy]="'UpdatedAt'">Updated</clr-dg-column>
  <clr-dg-column >Status</clr-dg-column>
  <clr-dg-row
    *clrDgItems="let order of orders"
    [clrDgItem]="order">
    <clr-dg-cell>{{order.ChainName}}</clr-dg-cell>
    <clr-dg-cell><a [routerLink]="orderLink(order)">{{order.OrderNumber}}</a></clr-dg-cell>
    <clr-dg-cell>{{order.CreatedAt | date:'medium'}}</clr-dg-cell>
    <clr-dg-cell>{{order.UpdatedAt | date:'medium'}}</clr-dg-cell>
    <clr-dg-cell>
        <ng-container *ngIf="order.Acknowledged">
          <span>Picking: {{ order.PickingSlip }}</span>
        </ng-container>
        <ng-container *ngIf="order.LastError !== ''">
          <span>Error: {{ order.LastError }}</span>
        </ng-container>
    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>{{orders.length}}</clr-dg-footer>
</clr-datagrid>
