var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { StockTranslationActions } from '../actions/edi';
import { createEntityAdapter } from '@ngrx/entity';
import { EncodeEdiStockTranslationID } from '../models';
export var adapter = createEntityAdapter({
    selectId: EncodeEdiStockTranslationID,
    sortComparer: false,
});
export var initialState = adapter.getInitialState({
    selectedID: null,
    selectedIDs: [],
    error: null,
    loading: false,
    editing: false,
    saved: true,
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case StockTranslationActions.ListPageLoad.type:
            return __assign({}, state, { loading: true });
        case StockTranslationActions.APIListSuccess.type:
            return __assign({}, adapter.addAll(action.translations, state), { loading: false });
        case StockTranslationActions.DetailPageLoad.type:
            return __assign({}, state, { selectedID: action.id, loading: true, editing: action.edit, saved: !action.edit });
        case StockTranslationActions.APIGetSuccess.type:
            return __assign({}, adapter.addOne(action.translation, state), { loading: false });
        case StockTranslationActions.APISaveSuccess.type:
            return __assign({}, adapter.updateOne({ id: EncodeEdiStockTranslationID(action.translation), changes: action.translation }, state), { saved: true });
        case StockTranslationActions.APIDeleteSuccess.type:
            return __assign({}, adapter.removeMany(action.ids, state));
        case StockTranslationActions.APICreateFailure.type:
            return __assign({}, state, { error: action.error });
        case StockTranslationActions.APIDeleteFailure.type:
            return __assign({}, state, { error: action.error });
        case StockTranslationActions.APICreateSuccess.type:
            return __assign({}, state, { saved: true });
        case StockTranslationActions.CreatePageLoad.type:
            return __assign({}, state, { error: undefined, saved: false });
        case StockTranslationActions.CreatePageUnload.type:
            return __assign({}, state, { error: undefined });
        default:
            return state;
    }
}
export var selectSelectedID = function (state) { return state.selectedID; };
export var selectLoading = function (state) { return state.loading; };
export var selectLocationIDs = (_a = adapter.getSelectors(), _a.selectIds), selectLocations = _a.selectEntities, selectLocationsList = _a.selectAll, selectLocationsTotal = _a.selectTotal;
