import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { map, tap } from 'rxjs/operators';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TreeNode } from '../../reports.models';
import { AppState } from '../../../reducers';
import { getTreeNodes, getCurrentFile, getcurrentPathLoading, getError, getTree } from '../../reports.reducer';
import { FileResponse } from '../../services/files.service';
import * as Actions from '../../reports.actions';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit, OnDestroy {

  tree$ = this.store.pipe(
    select(getTree),
  );

  currentFile$ = this.store.pipe(
    select(getCurrentFile)
  );

  loading$ = this.store.pipe(
    select(getcurrentPathLoading)
  );

  error$ = this.store.pipe(
    select(getError)
  );

  path$ = this.route.url.pipe(
    map(segments => segments.join('/'))
  );

  segments$ = this.route.url;

  selectedFiles: FileResponse[];

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.path$.pipe(
      tap(path => {
        this.store.dispatch(Actions.PageLoadPath({ path }));
      })
    ).subscribe();

    this.store.dispatch(Actions.InitialPageLoad());
  }

  ngOnDestroy() {
  }

  handleBreadcrumb(event, segments, index) {

  }

  pathLink(segments: UrlSegment[], index: number) {
    const tree = this.router.createUrlTree(
      ['../'.repeat(segments.length - index - 1)],
      {
        relativeTo: this.route,
      }
    );

    const link = this.router.serializeUrl(tree);

    return link;
  }

  handleListClick(file: FileResponse) {
    this.store.dispatch(Actions.FileListSelectFile({ file }));
  }

  handleTreeSelect(node: TreeNode) {
    this.store.dispatch(Actions.TREE_SELECT_FILE({ node }));
  }

  handleTreeExpand(node: TreeNode) {
    this.store.dispatch(Actions.TREE_EXPAND_FILE({ node }));
  }

  download(file: FileResponse) {
    this.store.dispatch(Actions.DownloadFile({ file }));
  }

}
