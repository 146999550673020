import { OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { getConnected, getError, getConnectionState, getSession } from '../../reducers';
import { Connect } from '../../actions';
import { environment } from '../../../../environments/environment';
import { getUser } from '../../../auth';
var AnzioWebComponent = /** @class */ (function () {
    function AnzioWebComponent(store) {
        var _this = this;
        this.store = store;
        this.server = environment.socketServer;
        this.session = this.store.pipe(select(getSession));
        this.status = this.store.pipe(select(getConnectionState));
        this.error = this.store.pipe(select(getError), tap(function () { _this.password = ''; }));
        this.showDialog = this.store.pipe(select(getConnected), map(function (connected) { return !connected; }));
        this.user = this.store.pipe(getUser);
    }
    AnzioWebComponent.prototype.ngOnInit = function () {
    };
    AnzioWebComponent.prototype.connect = function () {
        this.store.dispatch(new Connect({ server: this.server, password: this.password }));
    };
    return AnzioWebComponent;
}());
export { AnzioWebComponent };
