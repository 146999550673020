import { Action } from '@ngrx/store';
import { Session, StatusEvents, } from './services/terminal.service';

export const CONNECT = '[Terminal] Connect';
export const DISCONNECT = '[Terminal] Disonnect';
export const DISCONNECTED = '[Terminal] Disonnected';
export const CONNECT_FAILURE = '[Terminal] Connect Failure';
export const CONNECT_SUCCESS = '[Terminal] Connect Success';
export const CONNECT_ERROR = '[Terminal] Connect Error';
export const SESSION_STATUS = '[Session] Status Change';
export const SESSION_ERROR = '[Session] Error';

export class Connect implements Action {
  readonly type = CONNECT;

  constructor(public payload: { server: string, password: string }) { }
}

export class ConnectSuccess implements Action {
  readonly type = CONNECT_SUCCESS;

  constructor(public payload: Session) { }
}

export class ConnectFailure implements Action {
  readonly type = CONNECT_FAILURE;

  constructor(public payload: any) { }
}

export class Disconnect implements Action {
  readonly type = DISCONNECT;

  constructor() { }
}

export class Disconnected implements Action {
  readonly type = DISCONNECTED;

  constructor() { }
}

export class SessionStatus implements Action {
  readonly type = SESSION_STATUS;

  constructor(public payload: StatusEvents) { }
}

export class SessionError implements Action {
  readonly type = SESSION_ERROR;

  constructor(public payload: any) { }
}

export type Actions =
  | Connect
  | ConnectSuccess
  | ConnectFailure
  | Disconnect
  | Disconnected
  | SessionStatus
  | SessionError;
