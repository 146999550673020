import { OnInit, OnDestroy, OnChanges, ElementRef, NgZone, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { Session } from '../../services/terminal.service';
var TerminalComponent = /** @class */ (function () {
    function TerminalComponent(ngZone) {
        this.ngZone = ngZone;
        this.terminalInput = new Subject();
    }
    TerminalComponent.prototype.ngOnInit = function () {
        this.loadTerminal();
    };
    TerminalComponent.prototype.ngOnDestroy = function () {
        this.disconnect(this.session);
    };
    TerminalComponent.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            if (propName === 'session') {
                var change = changes[propName];
                if (change.previousValue) {
                    this.disconnect(change.previousValue);
                }
                if (change.currentValue) {
                    this.connect(change.currentValue);
                }
            }
        }
    };
    TerminalComponent.prototype.resetTerminal = function () {
        if (!this.term) {
            return;
        }
        this.term.reset();
    };
    TerminalComponent.prototype.focus = function () {
        if (this.session && this.term) {
            this.term.focus();
        }
    };
    TerminalComponent.prototype.connect = function (session) {
        var _this = this;
        this.stdoutSub = session.stdout.subscribe(function (data) {
            _this.receiveString(data);
        });
        this.stdinSub = this.terminalInput.subscribe(function (data) {
            _this.session.send(data);
        });
    };
    TerminalComponent.prototype.disconnect = function (session) {
        if (this.stdinSub) {
            this.stdinSub.unsubscribe();
        }
        if (this.stdoutSub) {
            this.stdoutSub.unsubscribe();
        }
        if (session) {
            session.close();
        }
    };
    TerminalComponent.prototype.loadTerminal = function () {
        var _this = this;
        if (this.term) {
            return;
        }
        this.ngZone.runOutsideAngular(function () {
            hterm.defaultStorage = new lib.Storage.Local();
            _this.term = new hterm.Terminal();
            _this.term.prefs_.set('font-family', 'Courier New');
            _this.term.prefs_.set('scrollbar-visible', true);
            _this.term.prefs_.set('ctrl-c-copy', true);
            _this.term.prefs_.set('ctrl-v-paste', true);
            _this.term.prefs_.set('use-default-window-copy', true);
            _this.term.prefs_.set('cursor-color', 'rgba(100, 100, 10, 1)');
            _this.term.prefs_.set('cursor-blink', true);
            _this.term.onTerminalReady = function () {
                _this.term.setCursorVisible(true);
                var io = _this.term.io.push().push();
                io.onVTKeystroke = function (str) {
                    _this.sendString(str);
                };
                io.sendString = function (str) {
                    _this.sendString(str);
                };
                io.onTerminalResize = function () {
                    var fontSize = _this.term.getFontSize();
                    if (fontSize !== _this.fontSize) {
                        _this.term.setFontSize(_this.fontSize);
                    }
                    if (_this.term.screenSize.height !== 25) {
                        _this.term.setHeight(25);
                    }
                    if (_this.term.screenSize.width !== 80) {
                        _this.term.setWidth(80);
                    }
                };
                if (_this.buffer && _this.buffer !== '') {
                    io.writeUTF16(_this.buffer);
                    _this.buffer = '';
                }
            };
            _this.term.decorate(document.getElementById('terminal'));
            _this.term.installKeyboard();
            _this.resize(window);
        });
    };
    TerminalComponent.prototype.receiveString = function (data) {
        if (!this.term) {
            this.buffer += data;
            return;
        }
        this.term.io.writeUTF16(data);
        return;
    };
    TerminalComponent.prototype.sendString = function (str) {
        this.terminalInput.next(str);
    };
    TerminalComponent.prototype.resize = function (window) {
        if (!this.term) {
            return;
        }
        var maxForHeight = ((window.innerHeight - 80) / 25);
        var scaledMaxForHeight = Math.floor(maxForHeight - (maxForHeight * 0.18) - 1);
        var maxForWidth = ((window.innerWidth) / 80) * 1.61;
        var fontSize = this.fontSize;
        if (scaledMaxForHeight > maxForWidth) {
            fontSize = Math.floor(maxForWidth);
        }
        else {
            fontSize = Math.floor(scaledMaxForHeight);
        }
        console.log(scaledMaxForHeight, maxForWidth, fontSize);
        this.fontSize = fontSize;
        this.term.setFontSize(this.fontSize);
    };
    TerminalComponent.prototype.onResize = function (event) {
        this.resize(event.target);
    };
    return TerminalComponent;
}());
export { TerminalComponent };
