import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '../reducers';

import * as reducers from './reducers';

export const featureName = 'auth';

export interface AppState extends fromRoot.AppState {
  auth: reducers.AuthState;
}

export * from './actions';
export * from './reducers';
export { User } from './models';
