/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@clr/angular";
import * as i5 from "../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i6 from "./app.component";
import * as i7 from "@ngrx/store";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "nav-link nav-text"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(5, null, [" ", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.path; _ck(_v, 1, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_4); }); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "header-nav"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i4.ClrNavLevel, [i4.ɵds, i1.ElementRef], { _level: [0, "_level"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_3)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "header-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "nav-link nav-text"], ["routerLink", "/logout"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Log Out"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = 1; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.menu)); _ck(_v, 3, 0, currVal_1); var currVal_4 = "/logout"; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 7).target; var currVal_3 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_2, currVal_3); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "clr-main-container", [], [[2, "main-container", null]], null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i4.ClrMainContainer, [i1.ElementRef, i4.ɵds], null, null), i1.ɵdid(2, 8404992, null, 0, i4.MainContainerWillyWonka, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 12, "clr-header", [], [[2, "header", null]], null, null, i5.View_ClrHeader_0, i5.RenderType_ClrHeader)), i1.ɵdid(4, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(5, 180224, null, 0, i4.ClrHeader, [i4.ɵds], null, null), i1.ɵdid(6, 2244608, null, 0, i4.NavDetectionOompaLoompa, [i1.ChangeDetectorRef, [2, i4.MainContainerWillyWonka], i4.ɵds], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "div", [["class", "branding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["class", "nav-link"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_AppComponent_1)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["loggedInBlock", 2]], 0, 0, null, View_AppComponent_2)), (_l()(), i1.ɵeld(16, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(17, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = _co.headerStyle; _ck(_v, 4, 0, currVal_2); var currVal_5 = "/"; _ck(_v, 9, 0, currVal_5); var currVal_7 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.loggedIn)); var currVal_8 = i1.ɵnov(_v, 15); _ck(_v, 13, 0, currVal_7, currVal_8); _ck(_v, 17, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = true; _ck(_v, 3, 0, currVal_1); var currVal_3 = i1.ɵnov(_v, 9).target; var currVal_4 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_6 = _co.title; _ck(_v, 11, 0, currVal_6); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppComponent, [i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
