<div class="model-header">
    <div class="clr-row">
        <div class="clr-col-12 clr-col-md-8">
          <h3><a routerLink="../">EDI Locations</a> / New</h3>
        </div>
      </div>
      <div class="clr-row">
        <div class="clr-col-12">
          <clr-button-group class="model-actions btn-primary btn-sm">
            <clr-button (click)="save()">Create</clr-button>
            <clr-button (click)="cancel()" class="btn-danger">Cancel</clr-button>
          </clr-button-group>
        </div>
      </div>
</div>
<div class="model-detail">
  <clr-alert *ngIf="error$ | async as error" clrAlertType="danger"> {{ error.title }}</clr-alert>
  <app-edi-stock-translations-detail-form [formControl]="form" [create]="true" [edit]="true"></app-edi-stock-translations-detail-form>
</div>
