import { StockTranslationActions } from '../actions/edi';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EdiStockTranslation, EncodeEdiStockTranslationID } from '../models';

export interface State extends EntityState<EdiStockTranslation> {
  loading: boolean;
  error: any | null;
  selectedID: string | null;
  editing: boolean;
  saved: boolean;
}

export const adapter: EntityAdapter<EdiStockTranslation> = createEntityAdapter<EdiStockTranslation>({
  selectId: EncodeEdiStockTranslationID,
  sortComparer: false,
});

export const initialState = adapter.getInitialState({
  selectedID: null,
  selectedIDs: [],
  error: null,
  loading: false,
  editing: false,
  saved: true,
});

export function reducer(state = initialState, action: StockTranslationActions.StockTranslationsActionsUnion) {
  switch (action.type) {
    case StockTranslationActions.ListPageLoad.type:
      return {
        ...state,
        loading: true,
      };
    case StockTranslationActions.APIListSuccess.type:
      return {
        ...adapter.addAll(action.translations, state),
        loading: false,
      };
    case StockTranslationActions.DetailPageLoad.type:
      return {
        ...state,
        selectedID: action.id,
        loading: true,
        editing: action.edit,
        saved: !action.edit,
      };
    case StockTranslationActions.APIGetSuccess.type:
      return {
        ...adapter.addOne(action.translation, state),
        loading: false,
      };
    case StockTranslationActions.APISaveSuccess.type:
      return {
        ...adapter.updateOne({ id: EncodeEdiStockTranslationID(action.translation), changes: action.translation }, state),
        saved: true,
      };
    case StockTranslationActions.APIDeleteSuccess.type:
      return {
        ...adapter.removeMany(action.ids, state),
      };
    case StockTranslationActions.APICreateFailure.type:
      return {
        ...state,
        error: action.error
      };
    case StockTranslationActions.APIDeleteFailure.type:
      return {
        ...state,
        error: action.error
      };
    case StockTranslationActions.APICreateSuccess.type:
      return {
        ...state,
        saved: true,
      };
    case StockTranslationActions.CreatePageLoad.type:
      return {
        ...state,
        error: undefined,
        saved: false,
      };
    case StockTranslationActions.CreatePageUnload.type:
      return {
        ...state,
        error: undefined,
      };
    default:
      return state;
  }
}

export const selectSelectedID = (state: State) => state.selectedID;

export const selectLoading = (state: State) => state.loading;

export const {
  selectIds: selectLocationIDs,
  selectEntities: selectLocations,
  selectAll: selectLocationsList,
  selectTotal: selectLocationsTotal,
} = adapter.getSelectors();

