import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
  combineReducers
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as EDI from './edi.reducer';


export interface State {
  edi: EDI.State;
}

const combinedReducers = combineReducers({
  edi: EDI.reducer,
});

export function reducers(state, action) {
  return combinedReducers(state, action);
}

export interface AppState {
  warehouse: State;
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];


export const selectWarehouse = createFeatureSelector<AppState, State>('warehouse');

export const selectEdi = createSelector(selectWarehouse, (state) => state.edi);

export const selectEdiOrdersState = createSelector(selectEdi, EDI.selectOrdersState);
export const selectEdiOrders = createSelector(selectEdiOrdersState, EDI.Orders.selectOrders);
export const selectEdiOrdersLoading = createSelector(selectEdiOrdersState, EDI.Orders.selectOrdersLoading);
export const selectEdiOrdersSelectedID = createSelector(selectEdiOrdersState, EDI.Orders.selectSelectedOrderID);
export const selectEdiList = createSelector(selectEdiOrdersState, EDI.Orders.selectOrdersList);
export const selectEdiDetail = createSelector(selectEdiOrders, selectEdiOrdersSelectedID, (orders, selectedID) => orders[selectedID]);

export const selectEdiLocationsState = createSelector(selectEdi, (state) => state.locations);
export const selectEdiLocations = createSelector(selectEdiLocationsState, EDI.Locations.selectLocations);
export const selectEdiLocationsLoading = createSelector(selectEdiLocationsState, EDI.Locations.selectLocationsLoading);
export const selectEdiLocationsSelectedID = createSelector(selectEdiLocationsState, EDI.Locations.selectSelectedLocationID);
export const selectEdiLocationList = createSelector(selectEdiLocationsState, EDI.Locations.selectLocationsList);
export const selectEdiLocationSelected = createSelector(selectEdiLocations, selectEdiLocationsSelectedID,
  (locations, selectedID) => locations[selectedID]);

export const selectEdiStockTranslationsState = createSelector(selectEdi, (state) => state.translations);
export const selectEdiStockTranslations = createSelector(selectEdiStockTranslationsState, EDI.StockTranslations.selectLocations);
export const selectEdiStockTranslationsLoading = createSelector(selectEdiStockTranslationsState, EDI.StockTranslations.selectLoading);
export const selectEdiStockTranslationsSelectedID = createSelector(selectEdiStockTranslationsState, EDI.StockTranslations.selectSelectedID);
export const selectEdiStockTranslationList = createSelector(selectEdiStockTranslationsState, EDI.StockTranslations.selectLocationsList);
export const selectEdiStockTranslationSelected = createSelector(selectEdiStockTranslations, selectEdiStockTranslationsSelectedID,
  (locations, selectedID) => locations[selectedID]);
