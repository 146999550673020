
import { combineReducers } from '@ngrx/store';
import * as Locations from './locations';
import * as Orders from './orders';
import * as StockTranslations from './translations';

export interface State {
  locations: Locations.State;
  orders: Orders.State;
  translations: StockTranslations.State;
}

export const initialState: State = {
  locations: Locations.initialState,
  orders: Orders.initialState,
  translations: StockTranslations.initialState,
};


export const reducer = combineReducers({
  locations: Locations.locationsReducer,
  orders: Orders.reducer,
  translations: StockTranslations.reducer,
});


export const selectLocationsState = (state: State) => state.locations;
export const selectOrdersState = (state: State) => state.orders;
export const selectStockTranslationsState = (state: State) => state.translations;

export {
  Locations, Orders, StockTranslations
};
