/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filetree.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i3 from "@clr/angular";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./filetree.component";
var styles_FiletreeComponent = [i0.styles];
var RenderType_FiletreeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FiletreeComponent, data: {} });
export { RenderType_FiletreeComponent as RenderType_FiletreeComponent };
function View_FiletreeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "clr-tree-node", [], [[2, "clr-tree-node", null], [1, "role", 0], [1, "aria-multiselectable", 0], [1, "aria-selected", 0]], [[null, "clrExpandedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clrExpandedChange" === en)) {
        var pd_0 = (_co.handleExpand(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ClrTreeNode_0, i2.RenderType_ClrTreeNode)), i1.ɵprd(6144, null, i3.LoadingListener, null, [i3.ɵcl]), i1.ɵprd(1024, null, i3.ɵcn, i3.ɵco, []), i1.ɵprd(1024, null, i3.ɵdm, i3.ɵdn, [[3, i3.ɵdm]]), i1.ɵprd(512, null, i3.ɵcl, i3.ɵcl, []), i1.ɵdid(5, 245760, null, 0, i3.ClrTreeNode, [i3.ɵcn, [3, i3.ClrTreeNode], i3.ɵdm, i3.ɵcl, i3.ClrCommonStrings, i1.Injector], { expandable: [0, "expandable"], expanded: [1, "expanded"] }, { expandedChange: "clrExpandedChange" }), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "button", [["class", "clr-treenode-link"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "clr-icon", [], [[1, "shape", 0]], null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i3.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isExpandable(_v.context.$implicit); var currVal_5 = _co.isExpanded(_v.context.$implicit); _ck(_v, 5, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 5).treeNodeRole; var currVal_2 = i1.ɵnov(_v, 5).rootAriaMultiSelectable; var currVal_3 = i1.ɵnov(_v, 5).ariaSelected; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.isActive(_v.context.$implicit); _ck(_v, 6, 0, currVal_6); var currVal_7 = _v.context.$implicit.file.icon; _ck(_v, 7, 0, currVal_7); var currVal_8 = _v.context.$implicit.file.name; _ck(_v, 9, 0, currVal_8); }); }
function View_FiletreeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "clr-tree", [], [[8, "hidden", 0]], null, null, i2.View_ClrTree_0, i2.RenderType_ClrTree)), i1.ɵprd(1024, null, i3.ɵdm, i3.ɵdn, [[3, i3.ɵdm]]), i1.ɵdid(2, 49152, null, 0, i3.ClrTree, [i3.ɵdm], null, null), (_l()(), i1.ɵand(0, null, 0, 1, null, View_FiletreeComponent_2)), i1.ɵdid(4, 540672, null, 0, i3.ClrRecursiveForOf, [i1.TemplateRef, i3.ɵdm], { nodes: [0, "nodes"], getChildren: [1, "getChildren"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.nodes; var currVal_2 = _co.getChildren; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collapsed; _ck(_v, 0, 0, currVal_0); }); }
export function View_FiletreeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "clr-vertical-nav", [["class", "nav-trigger--bottom clr-vertical-nav"]], [[2, "is-collapsed", null], [2, "has-nav-groups", null], [2, "has-icons", null]], [[null, "clrVerticalNavCollapsedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clrVerticalNavCollapsedChange" === en)) {
        var pd_0 = ((_co.collapsed = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ClrVerticalNav_0, i2.RenderType_ClrVerticalNav)), i1.ɵprd(512, null, i3.ɵed, i3.ɵed, []), i1.ɵprd(512, null, i3.ɵee, i3.ɵee, []), i1.ɵprd(512, null, i3.ɵef, i3.ɵef, []), i1.ɵdid(4, 180224, null, 0, i3.ClrVerticalNav, [i3.ɵed, i3.ɵee, i3.ɵef, i3.ClrCommonStrings], { collapsible: [0, "collapsible"], collapsed: [1, "collapsed"] }, { _collapsedChanged: "clrVerticalNavCollapsedChange" }), (_l()(), i1.ɵeld(5, 0, null, 0, 9, "a", [["class", "nav-link"], ["clrVerticalNavLink", ""], ["routerLink", "/reports"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).expandParentNavGroup() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ClrVerticalNavLink_0, i2.RenderType_ClrVerticalNavLink)), i1.ɵdid(6, 49152, null, 0, i3.ClrVerticalNavLink, [[2, i3.ɵeg]], null, null), i1.ɵdid(7, 671744, [[2, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(8, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "clr-icon", [["class", "nav-icon"], ["clrVerticalNavIcon", ""], ["shape", "tree-view"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i3.ClrIconCustomTag, [], null, null), i1.ɵdid(13, 147456, null, 0, i3.ClrVerticalNavIcon, [i3.ɵee], null, null), (_l()(), i1.ɵted(-1, 1, [" Reports "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FiletreeComponent_1)), i1.ɵdid(16, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = true; var currVal_4 = _co.collapsed; _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_7 = "/reports"; _ck(_v, 7, 0, currVal_7); var currVal_8 = "active"; _ck(_v, 8, 0, currVal_8); var currVal_9 = _co.nodes; _ck(_v, 16, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).collapsed; var currVal_1 = i1.ɵnov(_v, 4).hasNavGroups; var currVal_2 = i1.ɵnov(_v, 4).hasIcons; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i1.ɵnov(_v, 7).target; var currVal_6 = i1.ɵnov(_v, 7).href; _ck(_v, 5, 0, currVal_5, currVal_6); }); }
export function View_FiletreeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filetree", [], null, null, null, View_FiletreeComponent_0, RenderType_FiletreeComponent)), i1.ɵdid(1, 638976, null, 0, i6.FiletreeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FiletreeComponentNgFactory = i1.ɵccf("app-filetree", i6.FiletreeComponent, View_FiletreeComponent_Host_0, { nodes: "nodes", currentFile: "currentFile" }, { lazyLoad: "lazyLoad", expand: "expand", select: "select" }, []);
export { FiletreeComponentNgFactory as FiletreeComponentNgFactory };
