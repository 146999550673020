var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { StockTranslationActions } from '../actions/edi';
import { switchMap, map, catchError, tap, mergeMap, reduce } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { Router } from '@angular/router';
import { StocksService } from '../services/stocks.service';
import { EncodeEdiStockTranslationID, DecodeEdiStockTranslationID } from '../models';
var StockTranslationsEffects = /** @class */ (function () {
    function StockTranslationsEffects(actions$, stocksService, router) {
        var _this = this;
        this.actions$ = actions$;
        this.stocksService = stocksService;
        this.router = router;
        this.loadStockTranslationsList$ = this.actions$.pipe(ofType(StockTranslationActions.ListPageLoad.type), switchMap(function () { return _this.stocksService.list().pipe(map(function (translations) { return StockTranslationActions.APIListSuccess({ translations: translations || [] }); }), catchError(function (error) { return of(StockTranslationActions.APIListFailure({ error: error })); })); }));
        this.loadLocation$ = this.actions$.pipe(ofType(StockTranslationActions.DetailPageLoad.type), switchMap(function (action) { return _this.stocksService.get(DecodeEdiStockTranslationID(action.id)).pipe(map(function (translation) { return StockTranslationActions.APIGetSuccess({ translation: translation }); }), catchError(function (error) { return of(StockTranslationActions.APIGetFailure({ error: error })); })); }));
        this.saveEdiLocation$ = this.actions$.pipe(ofType(StockTranslationActions.DetailPageSaveButton.type), switchMap(function (action) { return _this.stocksService.update(action.translation).pipe(map(function (translation) { return StockTranslationActions.APISaveSuccess({ translation: translation }); }), catchError(function (error) { return of(StockTranslationActions.APISaveFailure({ error: error[0] })); })); }));
        this.createEdiLocation$ = this.actions$.pipe(ofType(StockTranslationActions.ListPageCreateButton.type, StockTranslationActions.CreatePageCreateButton.type), switchMap(function (action) { return _this.stocksService.create(action.translation).pipe(map(function (translation) { return StockTranslationActions.APICreateSuccess({ translation: translation }); }), catchError(function (error) { return of(StockTranslationActions.APICreateFailure({ error: error[0] })); })); }));
        this.updateLocationDetailPage$ = this.actions$.pipe(ofType(StockTranslationActions.APICreateSuccess.type, StockTranslationActions.APISaveSuccess.type), tap(function (_a) {
            var translation = _a.translation;
            _this.router.navigate(['warehouse/edi/translations/detail',
                { id: EncodeEdiStockTranslationID(translation), edit: 0 }]);
        }));
        this.deleteMultipleTranslations$ = this.actions$.pipe(ofType(StockTranslationActions.ListPageDeleteButton.type), mergeMap(function (action) {
            return from(action.translations).pipe(mergeMap(function (translation) { return _this.stocksService.delete(translation).pipe(map(function () { return EncodeEdiStockTranslationID(translation); })); }), reduce(function (acc, val) {
                return acc.concat([val]);
            }, []), map(function (ids) { return StockTranslationActions.APIDeleteSuccess({ ids: ids }); }), catchError(function (error) { return of(StockTranslationActions.APIDeleteFailure({ error: error })); }));
        }));
        this.deleteTranslation$ = this.actions$.pipe(ofType(StockTranslationActions.DetailPageDeleteButton.type), mergeMap(function (action) { return _this.stocksService.delete(action.translation).pipe(map(function (success) { return StockTranslationActions.APIDeleteSuccess({ ids: [EncodeEdiStockTranslationID(action.translation)] }); }), catchError(function (error) { return of(StockTranslationActions.APIDeleteFailure({ error: error })); })); }));
        this.redictedOnDelete$ = this.actions$.pipe(ofType(StockTranslationActions.APIDeleteSuccess.type), tap(function () {
            _this.router.navigate(['warehouse/edi/translations']);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], StockTranslationsEffects.prototype, "loadStockTranslationsList$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], StockTranslationsEffects.prototype, "loadLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], StockTranslationsEffects.prototype, "saveEdiLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], StockTranslationsEffects.prototype, "createEdiLocation$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], StockTranslationsEffects.prototype, "updateLocationDetailPage$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], StockTranslationsEffects.prototype, "deleteMultipleTranslations$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], StockTranslationsEffects.prototype, "deleteTranslation$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], StockTranslationsEffects.prototype, "redictedOnDelete$", void 0);
    return StockTranslationsEffects;
}());
export { StockTranslationsEffects };
