import { Injectable } from '@angular/core';
import { ApiService, FilterOptionKeys } from './api.service';

function cleanCode(code: string) {
  return encodeURIComponent(encodeURIComponent(decodeURIComponent(code)));
}


export interface Debtor {
  code: string;
  trading: string;
}

type DebtorFilterKeys = 'search' | 'code' | 'trading';

export type DebtorFilter = {
  [key in DebtorFilterKeys | FilterOptionKeys]?: string;
};

@Injectable({
  providedIn: 'root'
})
export class DebtorsService {

  constructor(private api: ApiService) {

  }

  list(filter?: DebtorFilter) {
    const options = filter ? {
      params: filter,
    } : {};
    return this.api.get<Debtor[]>('/debtors', options);
  }

  get(code: string) {
    return this.api.get<Debtor>('/debtors/' + cleanCode(code));
  }
}
