import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Login } from '..';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(store) {
        this.store = store;
    }
    LoginComponent.prototype.ngOnInit = function () {
    };
    LoginComponent.prototype.login = function () {
        this.store.dispatch(new Login());
    };
    return LoginComponent;
}());
export { LoginComponent };
