<div class="clr-row">
  <div class="clr-col-12 clr-col-md-6">
    <div class="clr-row">
      <label class="clr-col-12 clr-col-md-4 p4">ID</label>
      <div class="clr-col-12 clr-col-md-8 p3">{{ order.ID }}</div>
    </div>
    <div class="clr-row">
      <label class="clr-col-12 clr-col-md-4 p4">Chain</label>
      <div class="clr-col-12 clr-col-md-8 p3">{{ order.ChainName }}</div>
    </div>
    <div class="clr-row">
      <label class="clr-col-12 clr-col-md-4 p4">Not Before</label>
      <div class="clr-col-12 clr-col-md-8 p3">{{ order.NotBefore | civilDate }}</div>
    </div>
    <div class="clr-row">
      <label class="clr-col-12 clr-col-md-4 p4">Delivery Date</label>
      <div class="clr-col-12 clr-col-md-8 p3">{{ order.NotAfter | civilDate }}</div>
    </div>
    <div class="clr-row">
      <label class="clr-col-12 clr-col-md-4 p4">DC</label>
      <div class="clr-col-12 clr-col-md-8 p3">{{ order.DC }}</div>
    </div>
  </div>
  <div class="clr-col-12 clr-col-md-6">
    <div class="clr-row">
      <label class="clr-col-12 clr-col-md-4 p4">Created</label>
      <div class="clr-col-12 clr-col-md-8 p3">{{ order.CreatedAt | date }}</div>
    </div>
    <div class="clr-row">
      <label class="clr-col-12 clr-col-md-4 p4">Updated</label>
      <div class="clr-col-12 clr-col-md-8 p3">{{ order.UpdatedAt | date }}</div>
    </div>
    <div class="clr-row">
      <label class="clr-col-12 clr-col-md-4 p4">Picking Slip</label>
      <div class="clr-col-12 clr-col-md-8 p3">{{ order.PickingSlip }}</div>
    </div>
    <div class="clr-row">
      <label class="clr-col-12 clr-col-md-4 p4">Invoice</label>
      <div class="clr-col-12 clr-col-md-8 p3">{{ order.InvoiceNumber }}</div>
    </div>
  </div>
</div>
<div class="clr-row">
  <h4>Order Lines</h4>
  <table class="table">
    <thead>
      <tr>
        <th class="left">Barcode</th>
        <th class="right">Quantity</th>
        <th class="right">Price</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let line of order.Lines">
        <td class="left">{{ line.Barcode }}</td>
        <td class="right">{{ line.Quantity }}</td>
        <td class="right">{{ line.Price | currency }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="clr-row">
  <h4>Notes</h4>
  <div class="clr-col-12">
    <p>
      {{ order.Notes }}
    </p>
  </div>
</div>
