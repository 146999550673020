import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LocationsService } from '../services/locations.service';
import { LocationActions } from '../actions/edi';
import { switchMap, map, catchError, tap, flatMap, mergeMap, scan, reduce } from 'rxjs/operators';
import { of, merge, from } from 'rxjs';
import { Router } from '@angular/router';



@Injectable()
export class LocationsEffects {


  @Effect()
  loadLocationsList$ = this.actions$.pipe(
    ofType(LocationActions.ListPageLoad.type),
    switchMap(() => this.locationsService.list().pipe(
      map(locations => LocationActions.APIListSuccess({ locations: locations || [] })),
      catchError((error) => of(LocationActions.APIListFailure({ error: error }))),
    ))
  );

  @Effect()
  loadLocation$ = this.actions$.pipe(
    ofType(LocationActions.DetailPageLoad.type),
    switchMap((action) => this.locationsService.get(action.id).pipe(
      map(location => LocationActions.APIGetSuccess({ location })),
      catchError((error) => of(LocationActions.APIGetFailure({ error: error }))),
    ))
  );

  @Effect()
  saveEdiLocation$ = this.actions$.pipe(
    ofType(LocationActions.DetailPageSaveButton.type),
    switchMap(action => this.locationsService.update(action.location).pipe(
      map(location => LocationActions.APISaveSuccess({ location })),
      catchError((error) => of(LocationActions.APISaveFailure({ error: error[0] }))),
    ))
  );

  @Effect()
  createEdiLocation$ = this.actions$.pipe(
    ofType(LocationActions.ListPageCreateButton.type, LocationActions.CreatePageCreateButton.type),
    switchMap(action => this.locationsService.create(action.location).pipe(
      map(location => LocationActions.APICreateSuccess({ location })),
      catchError(error => of(LocationActions.APICreateFailure({ error: error[0] }))),
    ))
  );

  @Effect({ dispatch: false })
  updateLocationDetailPage$ = this.actions$.pipe(
    ofType(LocationActions.APICreateSuccess.type, LocationActions.APISaveSuccess.type),
    tap(({ location }) => {
      this.router.navigate(['warehouse/edi/locations/detail', { id: location.Code, edit: 0 }]);
    })
  );

  @Effect()
  deleteLocations$ = this.actions$.pipe(
    ofType(LocationActions.ListPageDeleteButton.type, LocationActions.DetailPageDeleteButton.type),
    mergeMap(action =>
      from(action.locations).pipe(
        tap(location => { console.log(location); }),
        mergeMap(location => this.locationsService.delete(location.Code).pipe(
          map(() => location.Code),
        )),
        reduce((acc: string[], val: string) => {
          return [...acc, val];
        }, []),
        map((ids) => LocationActions.APIDeleteSuccess({ locationIDs: ids })),
        catchError(error => of(LocationActions.APIDeleteFailure({ error }))),
      ),
    ),
  );

  @Effect({ dispatch: false })
  redictedOnDelete$ = this.actions$.pipe(
    ofType(LocationActions.APIDeleteSuccess.type),
    tap(() => {
      this.router.navigate(['warehouse/edi/locations']);
    })
  );

  constructor(
    private actions$: Actions<LocationActions.LocationsActionsUnion>,
    private locationsService: LocationsService,
    private router: Router,
  ) { }

}
