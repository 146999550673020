<div class="content-container">
  <app-terminal [session]="session | async"></app-terminal>
  <div class="modal" *ngIf="showDialog | async">
    <div class="modal status">
      <div class="modal-dialog" role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">Connect to Anzio</h3>
          </div>
          <div class="modal-body">
            <clr-alert *ngIf="error | async" [clrAlertType]="'danger'" [clrAlertClosable]="false">
              <div clr-alert-item class="alert-item">
                <span class="alert-text">
                  {{ error | async }}
                </span>
              </div>
            </clr-alert>
            <div *ngIf="(status | async) != 'disconnected'">
              {{ status | async }}
            </div>
            <div *ngIf="(status | async) == 'disconnected'">
              <form (ngSubmit)="connect()" clrForm>
                <div>Your password is required to login</div>
                <clr-password-container>
                  <label for="password" class="clr-control-label">Password:</label>
                  <input clrPassword placeholder="Password" name="password" [(ngModel)]="password" />
                </clr-password-container>
                <div class="modal-footer">
                  <button class="btn btn-primary" type="submit">Connect</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showDialog | async" class="modal-backdrop status"></div>
</div>
