import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as Anzio from './actions';
import { Session, StatusEvents } from './services/terminal.service';

export const featureName = 'anzio';

export interface AnzioState {
  connectionState: StatusEvents;
  session?: Session;
  error?: any;
  loading: boolean;
}

export const initialState: AnzioState = {
  connectionState: 'disconnected',
  loading: true,
};


export function reducer(state = initialState, action: Anzio.Actions): AnzioState {
  switch (action.type) {
    case Anzio.CONNECT:
      return {
        ...state,
        error: null
      };
    case Anzio.CONNECT_SUCCESS:
      return {
        ...state,
        session: action.payload
      };
    case Anzio.SESSION_STATUS:
      return {
        ...state,
        connectionState: action.payload,
        session: action.payload === 'disconnected' ? null : state.session
      };
    case Anzio.SESSION_ERROR:
      return {
        ...state,
        error: action.payload,
        session: null
      };
    default: {
      return state;
    }
  }
}

export const selectAnzioState = createFeatureSelector<AnzioState>(featureName);
export const getConnected = createSelector(selectAnzioState, (state: AnzioState) => state.connectionState === 'connected');
export const getConnectionState = createSelector(selectAnzioState, (state: AnzioState) => state.connectionState);
export const getError = createSelector(selectAnzioState, (state) => state.error);
export const getSession = createSelector(selectAnzioState, (state) => state.session);
