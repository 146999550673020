import { OnInit } from '@angular/core';
var EdiOrdersListComponent = /** @class */ (function () {
    function EdiOrdersListComponent() {
    }
    EdiOrdersListComponent.prototype.ngOnInit = function () {
    };
    EdiOrdersListComponent.prototype.orderLink = function (order) {
        return [order.ID];
    };
    return EdiOrdersListComponent;
}());
export { EdiOrdersListComponent };
