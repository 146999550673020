var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector, createFeatureSelector, select } from '@ngrx/store';
import { intersection, flatMap } from 'lodash';
import { AuthActionTypes } from './actions';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectMenu } from '../reducers';
export var featureName = 'auth';
export var initialState = {
    user: null,
    loginURL: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthActionTypes.UPDATE_USER:
            return __assign({}, state, { user: action.payload });
        case AuthActionTypes.LOGIN_REDIRECT:
            return __assign({}, state, { loginURL: action.payload });
        case AuthActionTypes.LOGIN_SUCCESS:
            return __assign({}, state, { user: action.payload });
        case AuthActionTypes.LOGOUT_SUCCESS:
            return __assign({}, state, { user: null });
        default: {
            return state;
        }
    }
}
export var selectAuth = createFeatureSelector(featureName);
export var selectLoggedIn = createSelector(selectAuth, function (state) { return state.user !== null; });
function getEffectivePermissions(user) {
    var groupPermissions = flatMap(user.groups, function (group) { return group.permissions; });
    return user.permissions.concat(groupPermissions);
}
function filterMenu(items, user) {
    return items
        .filter(function (item) {
        return item.requiredPermissions === undefined ||
            item.requiredPermissions.length === 0 ||
            intersection(item.requiredPermissions, getEffectivePermissions(user)).length > 0;
    })
        .map(function (item) {
        return __assign({}, item, { children: filterMenu(item.children, user) });
    });
}
export var selectUserMenu = createSelector(selectAuth, selectMenu, function (auth, menu) {
    return filterMenu(menu, auth.user);
});
export var getUser = pipe(select(selectAuth), map(function (auth) { return auth.user; }), filter(function (user) { return user !== null; }));
