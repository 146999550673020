import { OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectLoggedIn, selectUserMenu } from './auth/reducers';
import { Login, Logout } from './auth/actions';
import { environment } from '../environments/environment';
var AppComponent = /** @class */ (function () {
    function AppComponent(store) {
        this.store = store;
        this.title = environment.appName;
        this.headerStyle = {
            'background-color': environment.headerColour
        };
        this.loggedIn = this.store.pipe(select(selectLoggedIn));
        this.menu = this.store.pipe(select(selectUserMenu));
    }
    AppComponent.prototype.ngOnInit = function () {
    };
    AppComponent.prototype.login = function () {
        this.store.dispatch(new Login());
    };
    AppComponent.prototype.logout = function () {
        this.store.dispatch(new Logout());
    };
    return AppComponent;
}());
export { AppComponent };
