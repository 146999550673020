import { Action } from '@ngrx/store';
import { MenuItem } from './models';

export enum MenuActionTypes {
  LoadMenus = '[Menu] Load Menus',
}

export class LoadMenus implements Action {
  readonly type = MenuActionTypes.LoadMenus;

  constructor(public payload: { data: MenuItem[] }) {}
}


export type MenuActions = LoadMenus;
