import { Store } from '@ngrx/store';
import { GooglePlatformService } from '../services/google-platform.service';
import { UpdateUser } from './actions';
import { AnonymousUser } from './models';
import { ApiService } from '../services/api.service';
import { of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/google-platform.service";
import * as i2 from "../services/api.service";
import * as i3 from "@ngrx/store";
var AuthService = /** @class */ (function () {
    function AuthService(googlePlatform, api, store) {
        var _this = this;
        this.googlePlatform = googlePlatform;
        this.api = api;
        this.store = store;
        this.googlePlatform.currentUser().pipe(flatMap(function (user) { return _this.apiLogin(user); })).subscribe(function (user) {
            _this._user = user;
            _this.store.dispatch(new UpdateUser(user));
        });
    }
    AuthService.prototype.currentUser = function () {
        return this._user;
    };
    AuthService.prototype.apiLogin = function (googleUser) {
        if (!googleUser.isSignedIn()) {
            return of(AnonymousUser);
        }
        var authResponse = googleUser.getAuthResponse();
        var idToken = authResponse.id_token;
        return this.api.login(idToken);
    };
    AuthService.prototype.login = function () {
        var _this = this;
        return this.googlePlatform.login().pipe(flatMap(function (user) { return _this.apiLogin(user); }));
    };
    AuthService.prototype.logout = function () {
        return this.googlePlatform.logout();
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.GooglePlatformService), i0.inject(i2.ApiService), i0.inject(i3.Store)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
