var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { OrdersService } from '../services/orders.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { OrderActions } from '../actions/edi';
var OrdersEffects = /** @class */ (function () {
    function OrdersEffects(actions$, ordersService) {
        var _this = this;
        this.actions$ = actions$;
        this.ordersService = ordersService;
        this.loadOrderList$ = this.actions$
            .pipe(ofType(OrderActions.ListPageLoad.type), mergeMap(function () { return _this.ordersService.list().pipe(map(function (orders) { return OrderActions.APIListSuccess({ orders: orders }); }), catchError(function (error) { return of(OrderActions.APIListFailure({ error: error })); })); }));
        this.loadOrderDetail$ = this.actions$
            .pipe(ofType(OrderActions.DetailPageLoad.type), mergeMap(function (action) {
            return _this.ordersService.get(action.id).pipe(map(function (order) { return OrderActions.APIGetSuccess({ order: order }); }), catchError(function (error) { return of(OrderActions.APIGetFailure({ error: error })); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrdersEffects.prototype, "loadOrderList$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrdersEffects.prototype, "loadOrderDetail$", void 0);
    return OrdersEffects;
}());
export { OrdersEffects };
