<div *ngIf="edit" clrForm [formGroup]="form">
  <clr-input-container>
    <label>Code</label>
    <input  *ngIf="create" clrInput formControlName="Code" name="Code" type="text" />
    <p *ngIf="!create" clrInput readonly>{{ form.value.Code }}</p>
  </clr-input-container>
  <clr-input-container>
    <label>Name</label>
    <input clrInput formControlName="Name" name="Name" type="text" />
  </clr-input-container>
  <clr-input-container>
    <label>Debtor Code</label>
    <app-debtor-autocomplete clrInput formControlName="Account"></app-debtor-autocomplete>
  </clr-input-container>
  <clr-input-container>
    <label>Delivery Code</label>
    <input clrInput formControlName="DeliveryCode" name="DeliveryCode" type="text" />
  </clr-input-container>
  <clr-input-container>
    <label>Order Template</label>
    <input clrInput formControlName="OrderTemplate" name="OrderTemplate" type="text" />
  </clr-input-container>
</div>
<div clrForm *ngIf="!edit">
    <clr-input-container >
      <label>Code</label>
      <p clrInput readonly>{{ form.value.Code }}</p>
    </clr-input-container>
    <clr-input-container>
      <label>Name</label>
      <p clrInput readonly>{{ form.value.Name }}</p>
    </clr-input-container>
    <clr-input-container>
      <label>Debtor Code</label>
      <p clrInput readonly>{{ form.value.Account }}</p>
    </clr-input-container>
    <clr-input-container>
      <label>Delivery Code</label>
      <p clrInput readonly>{{ form.value.DeliveryCode }}</p>
    </clr-input-container>
    <clr-input-container>
      <label>Order Template</label>
      <p clrInput readonly>{{ form.value.OrderTemplate }}</p>
    </clr-input-container>
  </div>
