import {
  Component, Input, OnInit, OnDestroy, OnChanges, ViewChild, ElementRef, NgZone, SimpleChanges
} from '@angular/core';

import { Subscription, Subject } from 'rxjs';
import { Session } from '../../services/terminal.service';

declare var hterm: any;
declare var lib: any;

@Component({
  selector: 'app-terminal',
  templateUrl: 'terminal.component.html',
  styleUrls: ['terminal.component.scss'],
})
export class TerminalComponent implements OnInit, OnChanges, OnDestroy {

  private buffer: string;
  private term: any;
  private fontSize: number;
  private stdinSub: Subscription;
  private stdoutSub: Subscription;
  private statusSub: Subscription;
  private terminalInput = new Subject<string>();

  @Input() session: Session;

  @ViewChild('terminal') terminalNode: ElementRef;

  constructor(private ngZone: NgZone) {
  }

  ngOnInit() {
    this.loadTerminal();
  }

  ngOnDestroy() {
    this.disconnect(this.session);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'session') {
        const change = changes[propName];
        if (change.previousValue) {
          this.disconnect(change.previousValue);
        }

        if (change.currentValue) {
          this.connect(change.currentValue);
        }
      }
    }
  }

  resetTerminal() {
    if (!this.term) {
      return;
    }

    this.term.reset();
  }

  focus() {
    if (this.session && this.term) {
      this.term.focus();
    }
  }

  connect(session: Session) {
    this.stdoutSub = session.stdout.subscribe((data) => {
      this.receiveString(data);
    });

    this.stdinSub = this.terminalInput.subscribe(data => {
      this.session.send(data);
    });
  }

  disconnect(session: Session) {
    if (this.stdinSub) {
      this.stdinSub.unsubscribe();
    }
    if (this.stdoutSub) {
      this.stdoutSub.unsubscribe();
    }

    if (session) {
      session.close();
    }

  }

  loadTerminal() {
    if (this.term) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      hterm.defaultStorage = new lib.Storage.Local();
      this.term = new hterm.Terminal();
      this.term.prefs_.set('font-family', 'Courier New');
      this.term.prefs_.set('scrollbar-visible', true);
      this.term.prefs_.set('ctrl-c-copy', true);
      this.term.prefs_.set('ctrl-v-paste', true);
      this.term.prefs_.set('use-default-window-copy', true);
      this.term.prefs_.set('cursor-color', 'rgba(100, 100, 10, 1)');
      this.term.prefs_.set('cursor-blink', true);

      this.term.onTerminalReady = () => {
        this.term.setCursorVisible(true);
        const io = this.term.io.push().push();

        io.onVTKeystroke = (str) => {
          this.sendString(str);
        };

        io.sendString = (str) => {
          this.sendString(str);
        };

        io.onTerminalResize = () => {

          const fontSize = this.term.getFontSize();

          if (fontSize !== this.fontSize) {

            this.term.setFontSize(this.fontSize);
          }

          if (this.term.screenSize.height !== 25) {
            this.term.setHeight(25);
          }

          if (this.term.screenSize.width !== 80) {
            this.term.setWidth(80);
          }
        };

        if (this.buffer && this.buffer !== '') {
          io.writeUTF16(this.buffer);
          this.buffer = '';
        }
      };


      this.term.decorate(document.getElementById('terminal'));

      this.term.installKeyboard();

      this.resize(window);
    });


  }

  receiveString(data: string) {
    if (!this.term) {
      this.buffer += data;
      return;
    }
    this.term.io.writeUTF16(data);
    return;
  }

  sendString(str: string) {
    this.terminalInput.next(str);
  }

  private resize(window: {
    innerHeight: number;
    innerWidth: number;
  }) {

    if (!this.term) {
      return;
    }

    const maxForHeight = ((window.innerHeight - 80) / 25);
    const scaledMaxForHeight = Math.floor(maxForHeight - (maxForHeight * 0.18) - 1);
    const maxForWidth = ((window.innerWidth) / 80) * 1.61;
    let fontSize = this.fontSize;

    if (scaledMaxForHeight > maxForWidth) {
      fontSize = Math.floor(maxForWidth);
    } else {
      fontSize = Math.floor(scaledMaxForHeight);
    }

    console.log(scaledMaxForHeight, maxForWidth, fontSize);

    this.fontSize = fontSize;
    this.term.setFontSize(this.fontSize);
  }

  onResize(event) {
    this.resize(event.target);

  }
}
