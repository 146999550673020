<div *ngIf="edit" clrForm [formGroup]="form">
  <clr-input-container>
    <label>Debtor Code</label>
    <p *ngIf="!create" clrInput readonly>{{ form.value.DebtorCode }}</p>
    <app-debtor-autocomplete *ngIf="create" clrInput formControlName="DebtorCode"></app-debtor-autocomplete>
  </clr-input-container>
  <clr-input-container>
    <label>Debtor Stock Code</label>
    <p *ngIf="!create" clrInput readonly>{{ form.value.StockCode }}</p>
    <input  *ngIf="create" clrInput formControlName="StockCode" name="StockCode" type="text" />
  </clr-input-container>
  <clr-input-container>
    <label>TUN</label>
    <input clrInput formControlName="TUN" name="TUN" type="text" />
  </clr-input-container>
</div>
<div clrForm *ngIf="!edit">
    <clr-input-container >
      <label>Debtor Code</label>
      <p clrInput readonly>{{ form.value.DebtorCode }}</p>
    </clr-input-container>
    <clr-input-container>
      <label>Debtor Stock Code</label>
      <p clrInput readonly>{{ form.value.StockCode }}</p>
    </clr-input-container>
    <clr-input-container>
      <label>TUN</label>
      <p clrInput readonly>{{ form.value.TUN }}</p>
    </clr-input-container>
  </div>
