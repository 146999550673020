/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terminal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./terminal.component";
var styles_TerminalComponent = [i0.styles];
var RenderType_TerminalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TerminalComponent, data: {} });
export { RenderType_TerminalComponent as RenderType_TerminalComponent };
export function View_TerminalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { terminalNode: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "terminal-wrapper"]], null, [["window", "resize"], ["window", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (_co.onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:click" === en)) {
        var pd_1 = (_co.focus() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["terminal", 1]], null, 0, "div", [["id", "terminal"]], null, null, null, null, null))], null, null); }
export function View_TerminalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terminal", [], null, null, null, View_TerminalComponent_0, RenderType_TerminalComponent)), i1.ɵdid(1, 770048, null, 0, i2.TerminalComponent, [i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TerminalComponentNgFactory = i1.ɵccf("app-terminal", i2.TerminalComponent, View_TerminalComponent_Host_0, { session: "session" }, {}, []);
export { TerminalComponentNgFactory as TerminalComponentNgFactory };
