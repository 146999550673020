import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { concatMap, map, switchMap, catchError, filter, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import * as ReportsActions from './reports.actions';
import { Router } from '@angular/router';
import { FilesService } from './services/files.service';
import { Action } from '@ngrx/store';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';


@Injectable()
export class ReportsEffects {


  constructor(
    private actions$: Actions<ReportsActions.ReportsActionsUnion>,
    private router: Router,
    private fileService: FilesService,
  ) { }


  @Effect({ dispatch: false })
  selectFile = this.actions$.pipe(
    ofType(ReportsActions.FileListSelectFile.type),
    map(action => {
      const file = action.file;

      this.router.navigate([`/reports${file.path}`]);
    })
  );

  @Effect({ dispatch: false })
  selectTreeFile = this.actions$.pipe(
    ofType(ReportsActions.TREE_SELECT_FILE.type),
    map(action => {
      const node = action.node;

      this.router.navigate([`/reports${node.file.path}`]);
    })
  );

  @Effect()
  loadDirectoryTree = this.actions$.pipe(
    ofType(ReportsActions.APIGetFileSuccess.type),
    filter( action => action.file.isDirectory),
    switchMap((action) => {
      return this.fileService.getTree(action.file.path).pipe(
        map(file => ReportsActions.APIGetFileTreeSuccess({ file })),
        catchError(error => of(ReportsActions.APIGetFileTreeFailure({ error })))
      );
    })
  );

  @Effect()
  loadRootTree = this.actions$.pipe(
    ofType(ReportsActions.InitialPageLoad.type),
    switchMap(() => {
      return this.fileService.getTree('/').pipe(
        map(file => ReportsActions.APIGetFileTreeSuccess({ file })),
        catchError(error => of(ReportsActions.APIGetFileTreeFailure({ error })))
      );
    })
  );

  @Effect()
  loadFromRoute = this.actions$.pipe(
    ofType(ReportsActions.PageLoadPath.type),
    mergeMap((action) => {
      const path = action.path;
      return this.fileService.get(path).pipe(
        map((file) => ReportsActions.APIGetFileSuccess({ file })),
        catchError( (error) => of(ReportsActions.APIGetFileFailure({error}))),
      );
    }),
  );

  @Effect({ dispatch: false })
  downloadFile$ = this.actions$.pipe(
    ofType(ReportsActions.DownloadFile.type),
    map((action) => {
      const { file } = action;

      return this.fileService.downloadFile(file);
    }),
  );

}
