import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logout } from '..';
var LogoutComponent = /** @class */ (function () {
    function LogoutComponent(store) {
        this.store = store;
    }
    LogoutComponent.prototype.ngOnInit = function () {
        this.store.dispatch(new Logout());
    };
    return LogoutComponent;
}());
export { LogoutComponent };
