import { createAction, props, union } from '@ngrx/store';
import { EdiStockTranslation } from '../../models';


export const APIListSuccess = createAction(
  '[EDI Stock Translations API] List Success',
  props<{ translations: EdiStockTranslation[] }>(),
);
export const APIListFailure = createAction(
  '[EDI Stock Translations API] List Failure',
  props<{ error: any }>(),
);

export const APIGetSuccess = createAction(
  '[EDI Stock Translations API] Get Success',
  props<{ translation: EdiStockTranslation }>(),
);
export const APIGetFailure = createAction(
  '[EDI Stock Translations API] Get Failure',
  props<{ error: any }>(),
);

export const APISaveSuccess = createAction(
  '[EDI Stock Translations API] Save Success',
  props<{ translation: EdiStockTranslation }>(),
);

export const APISaveFailure = createAction(
  '[EDI Stock Translations API] Save Failure',
  props<{ error: any }>(),
);

export const APICreateSuccess = createAction(
  '[EDI Stock Translations API] Create Success',
  props<{ translation: EdiStockTranslation }>(),
);

export const APICreateFailure = createAction(
  '[EDI Stock Translations API] Create Failure',
  props<{ error: any }>(),
);

export const APIDeleteSuccess = createAction(
  '[EDI Stock Translations API] Delete Success',
  props<{ ids: string[] }>(),
);

export const APIDeleteFailure = createAction(
  '[EDI Stock Translations API] Delete Failure',
  props<{ error: any }>(),
);

export const DetailPageLoad = createAction(
  '[EDI Stock Translations Detail Page] Page Loaded',
  props<{ id: string, edit: boolean }>(),
);

export const DetailPageSaveButton = createAction(
  '[EDI Stock Translations Detail Page] Save Button',
  props<{ translation: EdiStockTranslation }>(),
);

export const DetailPageEditButton = createAction(
  '[EDI Stock Translations Detail Page] Cancel Button',
);

export const DetailPageDeleteButton = createAction(
  '[EDI Stock Translations Detail Page] Delete Button',
  props<{ translation: EdiStockTranslation }>(),
);

export const ListPageLoad = createAction(
  '[EDI Stock Translations List Page] Page Loaded',
);

export const ListPageCreateButton = createAction(
  '[EDI Stock Translations List Page] Create Button',
  props<{ translation: EdiStockTranslation }>(),
);

export const ListPageDeleteButton = createAction(
  '[EDI Stock Translations List Page] Delete Button',
  props<{ translations: EdiStockTranslation[] }>(),
);

export const CreatePageLoad = createAction(
  '[EDI Stock Translations Create Page] Page Loaded',
);

export const CreatePageUnload = createAction(
  '[EDI Stock Translations Create Page] Page Unloaded',
);


export const CreatePageCreateButton = createAction(
  '[EDI Stock Translations Create Page] Create Button',
  props<{ translation: EdiStockTranslation }>(),
);

export const CreatePageCancelButton = createAction(
  '[EDI Stock Translations Create Page] Cancel Button',
);



const all = union({
  ListPageLoad,
  APIListSuccess,
  APIListFailure,
  APIGetSuccess,
  APIGetFailure,
  DetailPageLoad,
  DetailPageEditButton,
  DetailPageSaveButton,
  DetailPageDeleteButton,
  APISaveSuccess,
  APISaveFailure,
  ListPageCreateButton,
  ListPageDeleteButton,
  APICreateSuccess,
  APICreateFailure,
  CreatePageLoad,
  CreatePageUnload,
  CreatePageCreateButton,
  CreatePageCancelButton,
  APIDeleteSuccess,
  APIDeleteFailure,
});

export type StockTranslationsActionsUnion = typeof all;
