import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { selectEdiStockTranslationList, selectEdiStockTranslationsLoading } from '../../../reducers';
import { StockTranslationActions } from '../../../actions/edi';
import { EdiStockTranslation, EncodeEdiStockTranslationID } from '../../../models';
import { DialogService } from '../../../../services/dialog.service';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-edi-stock-translations-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  list$ = this.store.pipe(
    select(selectEdiStockTranslationList),
  );
  loading$ = this.store.pipe(
    select(selectEdiStockTranslationsLoading),
  );

  selectedIDs: EdiStockTranslation[] = [];

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.store.dispatch(StockTranslationActions.ListPageLoad());
  }

  selectionChanged(selected: EdiStockTranslation[]) {
    this.selectedIDs = selected;
  }

  detailLink(translation: EdiStockTranslation) {
    return ['detail', {id: EncodeEdiStockTranslationID(translation)}];
  }

  create() {
    this.router.navigate(['create'], { relativeTo: this.route});
  }

  delete(translations: EdiStockTranslation[] = []) {
    this.dialogService.confirm(`Are you sure you want to delete ${translations.length} locations?`).pipe(
      take(1),
      tap( (cont) => {
        console.log(cont);
        if (cont) {
          this.store.dispatch(StockTranslationActions.ListPageDeleteButton({ translations }));
        }
      }),
    ).subscribe();
  }

}
