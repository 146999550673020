var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, OnDestroy } from '@angular/core';
import { of, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { tap, map, switchMap, distinctUntilChanged, take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { EdiStockTranslation, EncodeEdiStockTranslationID } from '../../../models';
import { DialogService } from '../../../../services/dialog.service';
import { StockTranslationActions } from '../../../actions/edi';
import { selectEdiStockTranslationsState, selectEdiStockTranslationSelected } from '../../../reducers';
var DetailsComponent = /** @class */ (function () {
    function DetailsComponent(route, router, store, dialogService) {
        this.route = route;
        this.router = router;
        this.store = store;
        this.dialogService = dialogService;
        this.translation$ = this.store.pipe(select(selectEdiStockTranslationSelected));
        this.editing$ = this.store.pipe(select(selectEdiStockTranslationsState), map(function (state) { return state.editing; }));
        this.id$ = this.route.paramMap.pipe(map(function (params) {
            return params.get('id');
        }));
        this.error = null;
        this.form = new FormControl({ value: {} });
    }
    DetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap.pipe(map(function (params) {
            var id = params.get('id');
            var edit = +params.get('edit') === 1;
            return { id: id, edit: edit };
        }), distinctUntilChanged(), tap(function (params) {
            _this.store.dispatch(StockTranslationActions.DetailPageLoad(params));
        })).subscribe();
        this.translation$.subscribe(function (location) {
            _this.initialTranslation = location;
            _this.form.reset(_this.initialTranslation);
        });
    };
    DetailsComponent.prototype.ngOnDestroy = function () {
    };
    DetailsComponent.prototype.canDeactivate = function () {
        var _this = this;
        if (!this.form.dirty) {
            return true;
        }
        return this.editing$.pipe(switchMap(function (editing) {
            if (editing) {
                return _this.dialogService.confirm('Do you want to discard your changes?').pipe(tap(function (discard) {
                    if (discard) {
                        _this.form.reset(_this.initialTranslation);
                    }
                }));
            }
            return of(true);
        }));
    };
    DetailsComponent.prototype.edit = function () {
        var params = __assign({}, this.route.snapshot.params, { edit: 1 });
        this.router.navigate(['./', params], { relativeTo: this.route });
    };
    DetailsComponent.prototype.cancel = function () {
        var params = __assign({}, this.route.snapshot.params, { edit: 0 });
        this.router.navigate(['./', params], { relativeTo: this.route });
    };
    DetailsComponent.prototype.save = function () {
        var updatedValue = this.form.value;
        this.store.dispatch(StockTranslationActions.DetailPageSaveButton({ translation: updatedValue }));
    };
    DetailsComponent.prototype.getTranslationID = function (translation) {
        return EncodeEdiStockTranslationID(translation);
    };
    DetailsComponent.prototype.delete = function (translation) {
        var _this = this;
        this.dialogService.confirm("Are you sure you want to delete location " + translation.DebtorCode + " - " + translation.StockCode + "?").pipe(take(1), tap(function (cont) {
            if (cont) {
                _this.store.dispatch(StockTranslationActions.DetailPageDeleteButton({ translation: translation }));
            }
        })).subscribe();
    };
    return DetailsComponent;
}());
export { DetailsComponent };
