export var AuthActionTypes;
(function (AuthActionTypes) {
    AuthActionTypes["UPDATE_USER"] = "[Auth] Update User";
    AuthActionTypes["LOGIN"] = "[Auth] Login";
    AuthActionTypes["LOGIN_SUCCESS"] = "[Auth] Login Success";
    AuthActionTypes["LOGIN_FAILURE"] = "[Auth] Login Failure";
    AuthActionTypes["LOGIN_REDIRECT"] = "[Auth] Login Redirect";
    AuthActionTypes["LOGOUT"] = "[Auth] Logout";
    AuthActionTypes["LOGOUT_SUCCESS"] = "[Auth] Logout Success";
})(AuthActionTypes || (AuthActionTypes = {}));
var UpdateUser = /** @class */ (function () {
    function UpdateUser(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.UPDATE_USER;
    }
    return UpdateUser;
}());
export { UpdateUser };
var Login = /** @class */ (function () {
    function Login() {
        this.type = AuthActionTypes.LOGIN;
    }
    return Login;
}());
export { Login };
var LoginSuccess = /** @class */ (function () {
    function LoginSuccess(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.LOGIN_SUCCESS;
    }
    return LoginSuccess;
}());
export { LoginSuccess };
var LoginFailure = /** @class */ (function () {
    function LoginFailure(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.LOGIN_FAILURE;
    }
    return LoginFailure;
}());
export { LoginFailure };
var LoginRedirect = /** @class */ (function () {
    function LoginRedirect(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.LOGIN_REDIRECT;
    }
    return LoginRedirect;
}());
export { LoginRedirect };
var Logout = /** @class */ (function () {
    function Logout() {
        this.type = AuthActionTypes.LOGOUT;
    }
    return Logout;
}());
export { Logout };
var LogoutSuccess = /** @class */ (function () {
    function LogoutSuccess() {
        this.type = AuthActionTypes.LOGOUT_SUCCESS;
    }
    return LogoutSuccess;
}());
export { LogoutSuccess };
