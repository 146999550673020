export var CONNECT = '[Terminal] Connect';
export var DISCONNECT = '[Terminal] Disonnect';
export var DISCONNECTED = '[Terminal] Disonnected';
export var CONNECT_FAILURE = '[Terminal] Connect Failure';
export var CONNECT_SUCCESS = '[Terminal] Connect Success';
export var CONNECT_ERROR = '[Terminal] Connect Error';
export var SESSION_STATUS = '[Session] Status Change';
export var SESSION_ERROR = '[Session] Error';
var Connect = /** @class */ (function () {
    function Connect(payload) {
        this.payload = payload;
        this.type = CONNECT;
    }
    return Connect;
}());
export { Connect };
var ConnectSuccess = /** @class */ (function () {
    function ConnectSuccess(payload) {
        this.payload = payload;
        this.type = CONNECT_SUCCESS;
    }
    return ConnectSuccess;
}());
export { ConnectSuccess };
var ConnectFailure = /** @class */ (function () {
    function ConnectFailure(payload) {
        this.payload = payload;
        this.type = CONNECT_FAILURE;
    }
    return ConnectFailure;
}());
export { ConnectFailure };
var Disconnect = /** @class */ (function () {
    function Disconnect() {
        this.type = DISCONNECT;
    }
    return Disconnect;
}());
export { Disconnect };
var Disconnected = /** @class */ (function () {
    function Disconnected() {
        this.type = DISCONNECTED;
    }
    return Disconnected;
}());
export { Disconnected };
var SessionStatus = /** @class */ (function () {
    function SessionStatus(payload) {
        this.payload = payload;
        this.type = SESSION_STATUS;
    }
    return SessionStatus;
}());
export { SessionStatus };
var SessionError = /** @class */ (function () {
    function SessionError(payload) {
        this.payload = payload;
        this.type = SESSION_ERROR;
    }
    return SessionError;
}());
export { SessionError };
