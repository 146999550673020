import { Component, OnInit, Input } from '@angular/core';
import { EdiOrderResponse } from '../../../models';

@Component({
  selector: 'edi-orders-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class EdiOrdersDetailComponent implements OnInit {

  @Input() order: EdiOrderResponse;

  constructor() { }

  ngOnInit() {
  }

}
