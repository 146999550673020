import { OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectEdiList, selectEdiOrdersLoading } from '../../../reducers';
import { OrderActions } from '../../../actions/edi';
var OrderListContainer = /** @class */ (function () {
    function OrderListContainer(store) {
        this.store = store;
        this.loading$ = this.store.pipe(select(selectEdiOrdersLoading));
        this.list$ = this.store.pipe(select(selectEdiList));
    }
    OrderListContainer.prototype.ngOnInit = function () {
        this.store.dispatch(OrderActions.ListPageLoad());
    };
    OrderListContainer.prototype.orderLink = function (order) {
        return [order.ID];
    };
    return OrderListContainer;
}());
export { OrderListContainer };
