import { ApiService } from '../../services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api.service";
function convertFilterToParams(filter) {
    if (filter === void 0) { filter = {}; }
    return {
        from: filter.from ? filter.from.toISOString() : undefined,
        to: filter.to ? filter.to.toISOString() : undefined,
        debtor: filter.debtor,
    };
}
var OrdersService = /** @class */ (function () {
    function OrdersService(api) {
        this.api = api;
    }
    OrdersService.prototype.list = function (filter) {
        var options = filter ?
            {
                params: convertFilterToParams(filter),
            } : {};
        return this.api.get('edi/orders', options);
    };
    OrdersService.prototype.get = function (id) {
        return this.api.get('edi/orders/' + id);
    };
    OrdersService.prototype.fetchNewOrders = function () {
        return this.api.post('edi/rpc/fetch', {});
    };
    OrdersService.prototype.acknowledgeAll = function () {
        return this.api.post('edi/rpc/acknowledge', {});
    };
    OrdersService.prototype.acknowledge = function (id) {
        return this.api.post('edi/rpc/acknowledge/' + id, {});
    };
    OrdersService.ngInjectableDef = i0.defineInjectable({ factory: function OrdersService_Factory() { return new OrdersService(i0.inject(i1.ApiService)); }, token: OrdersService, providedIn: "root" });
    return OrdersService;
}());
export { OrdersService };
