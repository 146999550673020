import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnzioWebComponent } from './containers/anzio-web/anzio-web.component';

export const ANZIO_ROUTES: Routes = [
  { path: '', redirectTo: 'terminal', pathMatch: 'full' },
  {
    path: 'terminal',
    component: AnzioWebComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ANZIO_ROUTES)
  ],
  exports: [RouterModule]
})
export class AnzioRoutingModule { }
