import { createAction, props, union } from '@ngrx/store';
import { EdiOrderResponse } from '../../models';

export const DetailPageLoad = createAction(
  '[EDI Order Detail Page] Page Load',
  props<{ id: string }>(),
);

export const ListPageLoad = createAction(
  '[EDI Order List Page] Page Load',
);

export const APIGetSuccess = createAction(
  '[EDI Orders API] Get Success',
  props<{ order: EdiOrderResponse }>(),
);

export const APIGetFailure = createAction(
  '[EDI Orders API] Get Failure',
  props<{ error: any }>(),
);

export const APIListSuccess = createAction(
  '[EDI Orders API] List Success',
  props<{ orders: EdiOrderResponse[] }>(),
);

export const APIListFailure = createAction(
  '[EDI Orders API] List Failure',
  props<{ error: any }>(),
);

const all = union({
  DetailPageLoad,
  ListPageLoad,
  APIGetSuccess,
  APIGetFailure,
  APIListSuccess,
  APIListFailure,
});

export type ActionsUnion = typeof all;

