import { OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup } from '@angular/forms';
import { EdiStockTranslation } from '../../../models';
var DetailFormComponent = /** @class */ (function () {
    function DetailFormComponent() {
        this.edit = false;
        this.create = false;
        this.form = new FormGroup({
            StockCode: new FormControl(''),
            DebtorCode: new FormControl(''),
            TUN: new FormControl(''),
        });
    }
    DetailFormComponent.prototype.writeValue = function (value) {
        console.log(value);
        if (value) {
            this.form.patchValue(value, {
                emitEvent: false,
            });
        }
    };
    DetailFormComponent.prototype.registerOnChange = function (fn) {
        this.form.valueChanges.subscribe(fn);
    };
    DetailFormComponent.prototype.registerOnTouched = function () {
    };
    DetailFormComponent.prototype.ngOnInit = function () {
    };
    DetailFormComponent.prototype.ngOnDestroy = function () {
    };
    return DetailFormComponent;
}());
export { DetailFormComponent };
