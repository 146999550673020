import { OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { OrderActions } from '../../../actions/edi';
import { selectEdiDetail } from '../../../reducers';
var OrderDetailContainer = /** @class */ (function () {
    function OrderDetailContainer(route, store) {
        this.route = route;
        this.store = store;
        this.order$ = this.store.pipe(select(selectEdiDetail));
    }
    OrderDetailContainer.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap.pipe(tap(function (params) {
            var id = params.get('id');
            _this.id = id;
            _this.store.dispatch(OrderActions.DetailPageLoad({ id: id }));
        })).subscribe();
    };
    OrderDetailContainer.prototype.ngOnDestroy = function () {
    };
    return OrderDetailContainer;
}());
export { OrderDetailContainer };
