import { OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { getCurrentFile, getcurrentPathLoading, getError, getTree } from '../../reports.reducer';
import * as Actions from '../../reports.actions';
var FilesComponent = /** @class */ (function () {
    function FilesComponent(store, route, router) {
        this.store = store;
        this.route = route;
        this.router = router;
        this.tree$ = this.store.pipe(select(getTree));
        this.currentFile$ = this.store.pipe(select(getCurrentFile));
        this.loading$ = this.store.pipe(select(getcurrentPathLoading));
        this.error$ = this.store.pipe(select(getError));
        this.path$ = this.route.url.pipe(map(function (segments) { return segments.join('/'); }));
        this.segments$ = this.route.url;
    }
    FilesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.path$.pipe(tap(function (path) {
            _this.store.dispatch(Actions.PageLoadPath({ path: path }));
        })).subscribe();
        this.store.dispatch(Actions.InitialPageLoad());
    };
    FilesComponent.prototype.ngOnDestroy = function () {
    };
    FilesComponent.prototype.handleBreadcrumb = function (event, segments, index) {
    };
    FilesComponent.prototype.pathLink = function (segments, index) {
        var tree = this.router.createUrlTree(['../'.repeat(segments.length - index - 1)], {
            relativeTo: this.route,
        });
        var link = this.router.serializeUrl(tree);
        return link;
    };
    FilesComponent.prototype.handleListClick = function (file) {
        this.store.dispatch(Actions.FileListSelectFile({ file: file }));
    };
    FilesComponent.prototype.handleTreeSelect = function (node) {
        this.store.dispatch(Actions.TREE_SELECT_FILE({ node: node }));
    };
    FilesComponent.prototype.handleTreeExpand = function (node) {
        this.store.dispatch(Actions.TREE_EXPAND_FILE({ node: node }));
    };
    FilesComponent.prototype.download = function (file) {
        this.store.dispatch(Actions.DownloadFile({ file: file }));
    };
    return FilesComponent;
}());
export { FilesComponent };
