import { OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, concat, of } from 'rxjs';
import { DebtorsService } from '../../services/debtors.service';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
var DebtorAutocompleteComponent = /** @class */ (function () {
    function DebtorAutocompleteComponent(debtorsService) {
        this.debtorsService = debtorsService;
        this.typeahead$ = new Subject();
        this.input = new FormControl('');
        this._onChange = function (value) { };
    }
    DebtorAutocompleteComponent.prototype.writeValue = function (value) {
        if (value !== undefined) {
            this.input.setValue(value, { emitEvent: false });
        }
    };
    DebtorAutocompleteComponent.prototype.registerOnChange = function (fn) {
        this.input.valueChanges.subscribe(fn);
    };
    DebtorAutocompleteComponent.prototype.registerOnTouched = function () {
    };
    DebtorAutocompleteComponent.prototype.ngOnDestroy = function () {
    };
    DebtorAutocompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        var initialValues = [];
        this.options$ = concat(of(initialValues), this.typeahead$.pipe(debounceTime(200), distinctUntilChanged(), switchMap(function (search) { return _this.debtorsService.list({ search: search, limit: '10' }).pipe(map(function (debtors) { return debtors.map(function (debtor) { return debtor.code; }); }), catchError(function () { return of(initialValues); })); })));
    };
    return DebtorAutocompleteComponent;
}());
export { DebtorAutocompleteComponent };
