
import * as ReportActions from './reports.actions';
import { FileResponse } from './services/files.service';
import { TreeNode, FileMeta, FileMap, FileExplorerState } from './reports.models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const initialState: FileExplorerState = {
  files: {},
  treeExpanded: {},
  loading: true,
  currentPath: '/',
};

function mapFileToTreeNode(currentPath?: string) {
  return (file: FileResponse): TreeNode => {
    if (file === undefined) {
      return;
    }
    const children = file.children === undefined ? undefined : file.children
      .filter(child => child.isDirectory).map(mapFileToTreeNode(currentPath));

    return {
      file: mapFileResponseToFileMeta(file),
      loading: false,
      active: file.path === currentPath,
      children,
    };
  };
}

export function mapFileResponseToFileMeta(file: FileResponse): FileMeta {
  if (file === undefined) {
    return;
  }

  let icon = 'file';
  if (file.isDirectory) {
    icon = file.name === 'Trash' ? 'trash' : 'folder';
  }

  return {
    name: file.name,
    path: file.path,
    icon: icon,
    modified: file.modified,
    isDirectory: file.isDirectory
  };
}

function updateTree(path: string, updateFn: (node: TreeNode) => TreeNode) {
  return (node: TreeNode): TreeNode => {
    if (node === undefined) {
      return;
    }

    if (node.file.path === path) {
      return updateFn(node);
    }

    if (path.indexOf(node.file.path) !== -1) {
      return {
        ...node,
        children: node.children.map(updateTree(path, updateFn)),
      };
    }

    return node;
  };
}

const updateTreeExpanded = (path: string, expanded: boolean) => updateTree(path, (node) => ({ ...node, expanded }));

function updateTreeFile(currentPath?: string, newFile?: FileResponse) {
  return (node: TreeNode) => {

    if (node === undefined || node.file.path === newFile.path) {
      const newNode = mapFileToTreeNode(currentPath)(newFile);

      return newNode;
    }

    const children = node.children !== undefined ? node.children.map(updateTreeFile(currentPath, newFile)) : undefined;

    return {
      ...node,
      children,
    };
  };
}


function updateFiles(files: { [path: string]: FileResponse }, file: FileResponse): { [path: string]: FileResponse } {
  const newFiles = {
    ...files,
  };
  newFiles[file.path] = file;
  return newFiles;
}

export function reducer(state = initialState, action: ReportActions.ReportsActionsUnion): FileExplorerState {
  switch (action.type) {

    case ReportActions.PageLoadPath.type:
      return {
        ...state,
        loading: true,
        error: undefined,
        currentPath: action.path,
      };
    case ReportActions.APIGetFileTreeSuccess.type:
      const tree = action.file;
      return {
        ...state,
        tree: updateTreeFile(state.currentPath, tree)(state.tree),
      };
    case ReportActions.TREE_EXPAND_FILE.type:
      const node = action.node;
      return {
        ...state,
        treeExpanded: {
          ...state.treeExpanded,
          [node.file.path]: node.expanded,
        }
      };
    case ReportActions.APIGetFileSuccess.type:
      const file = action.file;
      return {
        ...state,
        currentPath: file.path,
        loading: false,
        files: updateFiles(state.files, file),
      };
    case ReportActions.APIGetFileFailure.type:
      const error = action.error;
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
}


export const selectReportsState = createFeatureSelector<FileExplorerState>('reports');
export const getError = createSelector(selectReportsState, (state) => state.error);
export const getTreeNodes = createSelector(selectReportsState, (state) => state.tree);
export const getTreeExpanded = createSelector(selectReportsState, (state) => state.treeExpanded);
export const getTree = createSelector(getTreeNodes, getTreeExpanded, (root, expanded): TreeNode => {
  if (root === undefined) {
    return undefined;
  }
  const getExpanded = (node: TreeNode): boolean | undefined => {
    if (!!!node) {
      return undefined;
    }

    return expanded[node.file.path];
  };

  const mapNode = (node: TreeNode): TreeNode => {
    return {
      ...node,
      expanded: getExpanded(node),
      children: node.children !== undefined ? node.children.map(mapNode) : undefined,
    };
  };

  return {
    ...root,
    expanded: true,
    children: root.children !== undefined ? root.children.map(mapNode) : undefined,
  };
});

export const getFiles = createSelector(selectReportsState, (state) => state.files);

export const getCurrentFile = createSelector(selectReportsState, (state) => {
  if (state.currentPath !== undefined) {
    const currentFile = state.files[state.currentPath];

    return currentFile;
  }
});

export const getcurrentPathLoading = createSelector(selectReportsState, (state) => {
  return state.loading;
});
