<clr-datagrid class="filelist"
  [(clrDgSelected)]="selectedFiles"
  [clrDgLoading]="loading">
  <clr-dg-column [style.min-width.px]="40" [style.width.px]="40"></clr-dg-column>
  <clr-dg-column [clrDgSortBy]="fileNameComparator">Name</clr-dg-column>
  <clr-dg-column [clrDgSortBy]="'modified'">Modified</clr-dg-column>

  <clr-dg-row
    *clrDgItems="let file of files"
    [clrDgItem]="file">
    <clr-dg-cell [style.min-width.px]="40">
      <clr-icon [attr.shape]="getIcon(file)"></clr-icon>
    </clr-dg-cell>
    <clr-dg-cell><a routerLink="/reports/{{file.path}}">{{file.name}}</a></clr-dg-cell>
    <clr-dg-cell>{{file.modified | date:'medium'}}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>{{files?.length}}</clr-dg-footer>
</clr-datagrid>
