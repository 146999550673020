var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { LocationActions } from '../actions/edi';
import { createEntityAdapter } from '@ngrx/entity';
export var locationsAdapter = createEntityAdapter({
    selectId: function (location) { return location.Code; },
    sortComparer: false,
});
export var initialState = locationsAdapter.getInitialState({
    selectedLocationID: null,
    selectedIDs: [],
    error: null,
    loading: false,
    editing: false,
    saved: true,
});
export function locationsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LocationActions.ListPageLoad.type:
            return __assign({}, state, { loading: true });
        case LocationActions.APIListSuccess.type:
            return __assign({}, locationsAdapter.addAll(action.locations, state), { loading: false });
        case LocationActions.DetailPageLoad.type:
            return __assign({}, state, { selectedLocationID: action.id, loading: true, editing: action.edit, saved: !action.edit });
        case LocationActions.APIGetSuccess.type:
            return __assign({}, locationsAdapter.addOne(action.location, state), { selectedLocationID: action.location.Code });
        case LocationActions.APISaveSuccess.type:
            return __assign({}, locationsAdapter.updateOne({ id: action.location.Code, changes: action.location }, state), { saved: true });
        case LocationActions.APIDeleteSuccess.type:
            return __assign({}, locationsAdapter.removeMany(action.locationIDs, state));
        case LocationActions.APICreateFailure.type:
            return __assign({}, state, { error: action.error });
        case LocationActions.APIDeleteFailure.type:
            return __assign({}, state, { error: action.error });
        case LocationActions.APICreateSuccess.type:
            return __assign({}, state, { saved: true });
        case LocationActions.CreatePageLoad.type:
            return __assign({}, state, { error: undefined, saved: false });
        case LocationActions.CreatePageUnload.type:
            return __assign({}, state, { error: undefined });
        default:
            return state;
    }
}
export var selectSelectedLocationID = function (state) { return state.selectedLocationID; };
export var selectLocationsLoading = function (state) { return state.loading; };
export var selectLocationIDs = (_a = locationsAdapter.getSelectors(), _a.selectIds), selectLocations = _a.selectEntities, selectLocationsList = _a.selectAll, selectLocationsTotal = _a.selectTotal;
