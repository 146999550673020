import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { selectLoggedIn, selectUserMenu } from './auth/reducers';
import { Login, Logout } from './auth/actions';

import { AppState } from './reducers';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = environment.appName;
  headerStyle = {
    'background-color': environment.headerColour
  };

  loggedIn = this.store.pipe(
    select(selectLoggedIn),
  );

  menu = this.store.pipe(
    select(selectUserMenu)
  );

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
  }

  login() {
    this.store.dispatch(new Login());
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
