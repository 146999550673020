import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';

export interface FileResponse {
  path: string;
  name: string;
  modified: string;
  isDirectory: boolean;
  size: number;
  contents?: string;
  children?: FileResponse[];
}

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private api: ApiService) { }

  get(path?: string) {
    return this.api.get<FileResponse>('user/files/' + path);
  }

  getTree(path?: string) {
    return this.api.get<FileResponse>('user/tree/' + path);
  }

  downloadFile(file: FileResponse) {
    const f = new File([file.contents], file.name, {
      type: 'text/plain',
      lastModified: new Date(file.modified).valueOf(),
    });

    const url = window.URL.createObjectURL(f);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
}
