import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { GooglePlatformService } from '../services/google-platform.service';

import { UpdateUser } from './actions';

import { User, AnonymousUser } from './models';
import { ApiService } from '../services/api.service';
import { of, Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user: User;

  constructor(private googlePlatform: GooglePlatformService, private api: ApiService, private store: Store<any>) {
    this.googlePlatform.currentUser().pipe(
      flatMap(user => this.apiLogin(user))
    ).subscribe( user => {
      this._user = user;
      this.store.dispatch(new UpdateUser(user));
    });
  }

  currentUser() {
    return this._user;
  }

  private apiLogin(googleUser: gapi.auth2.GoogleUser): Observable<User> {
    if (!googleUser.isSignedIn()) {
      return of(AnonymousUser);
    }

    const authResponse = googleUser.getAuthResponse();
    const idToken = authResponse.id_token;

    return this.api.login(idToken);
  }

  login() {
    return this.googlePlatform.login().pipe(
      flatMap(user => this.apiLogin(user))
    );
  }

  logout() {
    return this.googlePlatform.logout();
  }
}
