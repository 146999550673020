var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createEntityAdapter } from '@ngrx/entity';
import { OrderActions } from '../actions/edi';
var ɵ0 = function (order) { return order.ID; };
var ordersAdapter = createEntityAdapter({
    selectId: ɵ0,
    sortComparer: false,
});
export var initialState = ordersAdapter.getInitialState({
    selectedOrderID: null,
    loading: false,
    error: null,
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case OrderActions.APIListSuccess.type:
            return ordersAdapter.addAll(action.orders, state);
        case OrderActions.APIListFailure.type:
            return __assign({}, state, { error: action.error });
        case OrderActions.DetailPageLoad.type:
            return __assign({}, state, { selectedOrderID: action.id });
        case OrderActions.APIGetSuccess.type:
            return __assign({}, ordersAdapter.addOne(action.order, state));
        case OrderActions.APIGetFailure.type:
            return __assign({}, state, { error: action.error });
        default:
            return state;
    }
}
export var selectSelectedOrderID = function (state) { return state.selectedOrderID; };
export var selectOrdersLoading = function (state) { return state.loading; };
export var selectOrderIDs = (_a = ordersAdapter.getSelectors(), _a.selectIds), selectOrders = _a.selectEntities, selectOrdersList = _a.selectAll, selectOrdersTotal = _a.selectTotal;
export { ɵ0 };
