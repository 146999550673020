import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromReports from './reports.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ReportsEffects } from './reports.effects';
import { FilelistComponent } from './components/filelist/filelist.component';
import { FiletreeComponent } from './components/filetree/filetree.component';
import { FilesComponent } from './containers/files/files.component';
import { ClarityModule } from '@clr/angular';
import { RouterModule } from '@angular/router';
import { FileviewComponent } from './components/fileview/fileview.component';
import { ReportsComponent } from './reports.component';

@NgModule({
  declarations: [
    FilelistComponent,
    FiletreeComponent,
    FileviewComponent,
    FilesComponent,
    ReportsComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    RouterModule,
    StoreModule.forFeature('reports', fromReports.reducer),
    EffectsModule.forFeature([ReportsEffects])
  ]
})
export class ReportsModule { }
