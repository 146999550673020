import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { StoreModule, Store } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { ClarityModule } from '@clr/angular';

import { AuthModule } from './auth/auth.module';

import { AppComponent } from './app.component';
import { AnzioModule } from './anzio/anzio.module';

import { metaReducers, initialAppState, AppState, menuReducer } from './reducers';
import { AppRoutingModule, APP_ROUTES } from './app-routing.module';
import { PageNotFoundComponent } from './components/pagenotfound.component';
import { HomeComponent } from './components/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { WarehouseModule } from './warehouse/warehouse.module';
import { LoadMenus } from './menu.actions';
import { MenuItem } from './models';
import { Route } from '@angular/router';
import { ReportsModule } from './reports/reports.module';
import { DebtorAutocompleteComponent } from './components/debtor-autocomplete/debtor-autocomplete.component';
import { NgSelectModule } from '@ng-select/ng-select';

function mapRouteToMenu(parentPath = '') {
  return (route: Route): MenuItem | undefined => {
    if (!route.data) {
      return undefined;
    }
    const path = parentPath + route.path;
    return {
      name: route.data.menuName,
      path,
      requiredPermissions: route.data.requiredPermissions,
      children: mapRoutesToMenu(path, route.children),
    };
  };
}

function mapRoutesToMenu(parentPath = '', routes: Route[]) {
  return routes.map(mapRouteToMenu(parentPath)).filter( item => item !== undefined);
}

export function initApplication(store: Store<AppState>) {
  return () => new Promise((resolve) => {
    const menu: MenuItem[] = mapRoutesToMenu('', APP_ROUTES);
    store.dispatch(new LoadMenus({ data: menu }));
    resolve(true);
  });
}


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ClarityModule,
    NgSelectModule,
    HttpClientModule,
    StoreModule.forRoot(
      {
        router: routerReducer,
        menu: menuReducer,
      },
      { metaReducers, initialState: initialAppState }
    ),
    EffectsModule.forRoot([
    ]),
    AuthModule,
    AnzioModule,
    WarehouseModule,
    ReportsModule,
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initApplication,
    deps: [Store],
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
