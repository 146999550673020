import { Routes } from '@angular/router';
import { AnzioWebComponent } from './containers/anzio-web/anzio-web.component';
export var ANZIO_ROUTES = [
    { path: '', redirectTo: 'terminal', pathMatch: 'full' },
    {
        path: 'terminal',
        component: AnzioWebComponent,
    }
];
var AnzioRoutingModule = /** @class */ (function () {
    function AnzioRoutingModule() {
    }
    return AnzioRoutingModule;
}());
export { AnzioRoutingModule };
