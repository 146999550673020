import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { catchError, map, mergeMap, tap, withLatestFrom, flatMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

import { LoginSuccess, LogoutSuccess, AuthActionTypes, LoginFailure } from './actions';
import { selectAuth } from './reducers';
import { AppState } from '.';
import { ApiService } from '../services/api.service';


@Injectable()
export class AuthEffects {

  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.LOGIN),
    flatMap(() =>
      this.auth.login().pipe(
        map((user) => new LoginSuccess(user)),
        catchError(error => of(new LoginFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    withLatestFrom(this.store),
    tap(([action, state]) => {
      const url = selectAuth(state).loginURL || '/anzio';

       this.router.navigate([url]);
    })
  );

  @Effect()
  logout$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.LOGOUT),
    mergeMap(() => this.auth.logout()),
    map((user) => {
      this.router.navigate(['/login']);
      return new LogoutSuccess();
    })
  );

  @Effect({ dispatch: false })
  loginRedirect$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.LOGIN_REDIRECT),
    tap(loggedIn => {
      this.router.navigate(['/login']);
    })
  );

  constructor(
    private auth: AuthService,
    private api: ApiService,
    private store: Store<AppState>,
    private router: Router,
    private actions$: Actions
  ) { }
}
