var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, first, flatMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { getUser } from '../auth';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
var defaultRequestOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    }),
    observe: 'body',
    responseType: 'json',
    withCredentials: true,
};
var setAuthorizationHeader = function (headers, token) {
    if (headers === undefined) {
        headers = new HttpHeaders();
    }
    if (!token) {
        return headers;
    }
    if (headers instanceof HttpHeaders) {
        return headers.set('Authorization', 'Bearer ' + token);
    }
    return __assign({}, headers, { Authorization: 'Bearer ' + token });
};
var ɵ0 = setAuthorizationHeader;
function handleError(error) {
    console.log(error);
    return throwError(error.error.errors);
}
var ApiService = /** @class */ (function () {
    function ApiService(http, store) {
        this.http = http;
        this.store = store;
    }
    ApiService.prototype.isLoginURL = function (url) {
        return url === environment.apiServer + '/login/jwt';
    };
    ApiService.prototype.login = function (idToken) {
        return this.http.post(environment.apiServer + '/login/jwt', { id_token: idToken }).pipe(map(function (response) {
            return __assign({}, response.data.user, { idToken: response.data.token, signedIn: true });
        }));
    };
    ApiService.prototype.get = function (path, options) {
        var _this = this;
        if (options === void 0) { options = defaultRequestOptions; }
        return this.store.pipe(getUser, first(), flatMap(function (user) {
            var token = user.idToken;
            options.headers = setAuthorizationHeader(options.headers, token);
            return _this.http.get(environment.apiServer + '/' + path, options).pipe(map(function (response) {
                return response.data;
            }), catchError(handleError));
        }));
    };
    ApiService.prototype.post = function (path, data, options) {
        var _this = this;
        if (options === void 0) { options = defaultRequestOptions; }
        return this.store.pipe(getUser, first(), flatMap(function (user) {
            var token = user.idToken;
            options.headers = setAuthorizationHeader(options.headers, token);
            return _this.http.post(environment.apiServer + '/' + path, data, options).pipe(map(function (response) {
                return response.data;
            }), catchError(handleError));
        }));
    };
    ApiService.prototype.delete = function (path, options) {
        var _this = this;
        if (options === void 0) { options = defaultRequestOptions; }
        return this.store.pipe(getUser, first(), flatMap(function (user) {
            var token = user.idToken;
            options.headers = setAuthorizationHeader(options.headers, token);
            return _this.http.delete(environment.apiServer + '/' + path, options).pipe(map(function (response) {
                return response.data;
            }), catchError(handleError));
        }));
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient), i0.inject(i2.Store)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
export { ɵ0 };
