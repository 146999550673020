<div class="model-header">
  <h3>EDI Orders</h3>
  <clr-button-group class="model-actions btn-primary btn-sm">
    <clr-button>Check for Orders</clr-button>
  </clr-button-group>
</div>
<div class="model-list">
  <edi-orders-list [orders]="list$ | async" [loading]="loading$ | async"></edi-orders-list>
</div>

