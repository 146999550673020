import { UserRole } from '../services/api.service';

export const AnonymousUser: User = {
  type: 'user',
  id: '',
  name: 'Anonymous',
  description: 'Anonymous User',
  email: '',
  groups: [],
  parents: [],
  signedIn: false,
  permissions: [],
};

export interface User extends UserRole {
  idToken?: string;
  signedIn: boolean;
}
