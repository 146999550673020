/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fileview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./fileview.component";
var styles_FileviewComponent = [i0.styles];
var RenderType_FileviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileviewComponent, data: {} });
export { RenderType_FileviewComponent as RenderType_FileviewComponent };
export function View_FileviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "file-contents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file.contents; _ck(_v, 2, 0, currVal_0); }); }
export function View_FileviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fileview", [], null, null, null, View_FileviewComponent_0, RenderType_FileviewComponent)), i1.ɵdid(1, 114688, null, 0, i2.FileviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileviewComponentNgFactory = i1.ɵccf("app-fileview", i2.FileviewComponent, View_FileviewComponent_Host_0, { file: "file" }, {}, []);
export { FileviewComponentNgFactory as FileviewComponentNgFactory };
