<clr-vertical-nav [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed" class="nav-trigger--bottom">
  <a clrVerticalNavLink routerLink="/reports" routerLinkActive="active">
    <clr-icon clrVerticalNavIcon shape="tree-view"></clr-icon>
    Reports
  </a>
  <clr-tree [hidden]="collapsed" *ngIf="nodes">
    <clr-tree-node *clrRecursiveFor="let node of nodes; getChildren: getChildren" [clrExpandable]="isExpandable(node)"
      [clrExpanded]="isExpanded(node)" (clrExpandedChange)="handleExpand(node, $event)">
      <button (click)="handleSelect(node)" class="clr-treenode-link" [class.active]="isActive(node)">
        <clr-icon [attr.shape]="node.file.icon"></clr-icon>
        {{ node.file.name }}
      </button>
    </clr-tree-node>
  </clr-tree>
</clr-vertical-nav>
