import { Action } from '@ngrx/store';

import { User } from './models';

export enum AuthActionTypes {
  UPDATE_USER = '[Auth] Update User',

  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGIN_REDIRECT = '[Auth] Login Redirect',

  LOGOUT = '[Auth] Logout',
  LOGOUT_SUCCESS = '[Auth] Logout Success',

}

export class UpdateUser implements Action {
  readonly type = AuthActionTypes.UPDATE_USER;

  constructor( public payload: User) {}
}

export class Login implements Action {
  readonly type = AuthActionTypes.LOGIN;
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;

  constructor( public payload: User) {}
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;

  constructor( public payload: any) {}
}

export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LOGIN_REDIRECT;

  constructor( public payload: string) {}
}

export class Logout implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class LogoutSuccess implements Action {
  readonly type = AuthActionTypes.LOGOUT_SUCCESS;
}

export type Actions =
    Login
  | LoginRedirect
  | LoginSuccess
  | LoginFailure
  | Logout
  | LogoutSuccess
  | UpdateUser;
