import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { LoginRedirect } from './actions';
import { getUser } from './reducers';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(store, router) {
        this.store = store;
        this.router = router;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        var _this = this;
        var url = state.url;
        return this.store.pipe(getUser, map(function (user) {
            if (!user.signedIn) {
                _this.store.dispatch(new LoginRedirect(url));
                return false;
            }
            return true;
        }), take(1));
    };
    AuthGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.inject(i1.Store), i0.inject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
