import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, first, flatMap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { getUser, User } from '../auth';

const defaultRequestOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'body' as 'body',
  responseType: 'json' as 'json',
  withCredentials: true,
};

interface RequestOptions {
  headers?: HttpHeaders | { [header: string]: string | string[]; };
  params?: HttpParams | { [param: string]: string | string[]; };
  observe?: 'body';
  responseType?: 'json';
  withCredentials?: boolean;
}

export type FilterOptionKeys = 'limit' | 'offset';

export interface ApiResponse<T> {
  data: T;
  meta?: any;
  links?: {[key: string]: string };
}

export interface ApiError {
  'request-id': string;
  code: number;
  title: string;
  detail: string;
}

export interface ErrorResponse {
  errors: ApiError[];
}

export interface Role {
  id: string;
  name: string;
  description: string;
  email: string;
  type: 'user' | 'group';
  parents: string[];
  permissions: string[];
}

export interface UserRole extends Role {
  type: 'user';
  groups: Group[];
}

export interface Group extends Role {
  type: 'group';
}

const setAuthorizationHeader = (headers?: HttpHeaders | { [header: string]: string | string[]; }, token?: string) => {
  if (headers === undefined) {
    headers = new HttpHeaders();
  }

  if (!token) {
    return headers;
  }

  if (headers instanceof HttpHeaders) {
    return headers.set('Authorization', 'Bearer ' + token);
  }

  return {
    ...headers,
    Authorization: 'Bearer ' + token,
  };
};

function handleError(error: HttpErrorResponse) {
  console.log(error);
  return throwError(error.error.errors);
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private store: Store<AppState>) { }

  isLoginURL(url: string): boolean {
    return url === environment.apiServer + '/login/jwt';
  }

  login(idToken: string): Observable<User> {

    return this.http.post<ApiResponse<{token: string, user: User}>>(
      environment.apiServer + '/login/jwt',
      { id_token: idToken }
      ).pipe(
      map( response => {
        return {
          ...response.data.user,
          idToken: response.data.token,
          signedIn: true
        };
      })
    );
  }

  get<T>(path: string, options: RequestOptions = defaultRequestOptions ) {
    return this.store.pipe(
      getUser,
      first(),
      flatMap( (user) => {
        const token = user.idToken;
        options.headers = setAuthorizationHeader(options.headers, token);

        return this.http.get<ApiResponse<T>>(environment.apiServer + '/' + path, options).pipe(
          map( (response) => {
            return response.data;
          }),
          catchError(handleError),
        );
      }
    ));
  }

  post<T, T2>(path: string, data: T2, options: RequestOptions = defaultRequestOptions ) {
    return this.store.pipe(
      getUser,
      first(),
      flatMap( (user) => {
        const token = user.idToken;
        options.headers = setAuthorizationHeader(options.headers, token);
        return this.http.post<ApiResponse<T>>(environment.apiServer + '/' + path, data, options).pipe(
          map( (response) => {
            return response.data;
          }),
          catchError(handleError),
        );
      })
    );
  }

  delete<T>(path: string, options: RequestOptions = defaultRequestOptions ) {
    return this.store.pipe(
      getUser,
      first(),
      flatMap( (user) => {
        const token = user.idToken;
        options.headers = setAuthorizationHeader(options.headers, token);

        return this.http.delete<ApiResponse<T>>(environment.apiServer + '/' + path, options).pipe(
          map( (response) => {
            return response.data;
          }),
          catchError(handleError),
        );
      }
    ));
  }
}
