import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { WarehouseComponent } from './warehouse.component';

import { StoreModule } from '@ngrx/store';
import * as fromWarehouse from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { OrdersEffects } from './effects/orders.effects';
import { ClarityModule } from '@clr/angular';
import { CivilDatePipe } from '../pipes/civil-date.pipe';
import { OrderListContainer as EdiOrderListContainer } from './containers/edi/orders/order-list.component';
import { OrderDetailContainer } from './containers/edi/orders/order-detail.component';
import { DashboardComponent } from './containers/dashboard.component';
import { EdiComponent } from './containers/edi.component';
import { LocationsListComponent } from './containers/edi/locations/locations-list.component';
import { RouterModule } from '@angular/router';
import { LocationsEffects } from './effects/locations.effects';
import { StockTranslationsEffects } from './effects/stock-translations.effects';

import { LocationsDetailsComponent } from './containers/edi/locations/locations-details.component';
import { LocationsDetailFormComponent } from './components/edi/locations/locations-detail-form.component';
import { DebtorAutocompleteComponent } from '../components/debtor-autocomplete/debtor-autocomplete.component';
import { CreateComponent } from './containers/edi/locations/create.component';
import { EdiOrdersListComponent } from './components/edi/orders/list.component';
import { EdiOrdersDetailComponent } from './components/edi/orders/detail.component';
import * as EdiStockTranslationContainers from './containers/edi/translations';
import * as EdiStockTranslationComponents from './components/edi/translations';

@NgModule({
  imports: [
    CommonModule,
    ClarityModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    StoreModule.forFeature('warehouse', fromWarehouse.reducers, { metaReducers: fromWarehouse.metaReducers }),
    EffectsModule.forFeature([OrdersEffects, LocationsEffects, StockTranslationsEffects])
  ],
  declarations: [
    CivilDatePipe,
    WarehouseComponent,
    DashboardComponent,
    EdiComponent,
    EdiOrderListContainer,
    OrderDetailContainer,
    LocationsListComponent,
    LocationsDetailsComponent,
    LocationsDetailFormComponent,
    DebtorAutocompleteComponent,
    CreateComponent,
    EdiOrdersListComponent,
    EdiOrdersDetailComponent,
    EdiStockTranslationContainers.CreateComponent,
    EdiStockTranslationContainers.DetailsComponent,
    EdiStockTranslationContainers.ListComponent,
    EdiStockTranslationComponents.DetailFormComponent,
    EdiStockTranslationComponents.ListComponent,
  ]
})
export class WarehouseModule { }
