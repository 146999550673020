import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { selectEdiLocationList, selectEdiLocationsLoading } from '../../../reducers';
import { LocationActions } from '../../../actions/edi';
import { DialogService } from '../../../../services/dialog.service';
import { take, tap } from 'rxjs/operators';
var LocationsListComponent = /** @class */ (function () {
    function LocationsListComponent(store, route, router, dialogService) {
        this.store = store;
        this.route = route;
        this.router = router;
        this.dialogService = dialogService;
        this.list$ = this.store.pipe(select(selectEdiLocationList));
        this.loading$ = this.store.pipe(select(selectEdiLocationsLoading));
        this.selectedIDs = [];
    }
    LocationsListComponent.prototype.ngOnInit = function () {
        this.store.dispatch(LocationActions.ListPageLoad());
    };
    LocationsListComponent.prototype.selectionChanged = function (selected) {
        this.selectedIDs = selected;
    };
    LocationsListComponent.prototype.detailLink = function (location) {
        return ['detail', { id: location.Code }];
    };
    LocationsListComponent.prototype.create = function () {
        this.router.navigate(['create'], { relativeTo: this.route });
    };
    LocationsListComponent.prototype.delete = function (locations) {
        var _this = this;
        if (locations === void 0) { locations = []; }
        this.dialogService.confirm("Are you sure you want to delete " + locations.length + " locations?").pipe(take(1), tap(function (cont) {
            console.log(cont);
            if (cont) {
                _this.store.dispatch(LocationActions.ListPageDeleteButton({ locations: locations }));
            }
        })).subscribe();
    };
    return LocationsListComponent;
}());
export { LocationsListComponent };
