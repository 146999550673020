import { Routes } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { PageNotFoundComponent } from './components/pagenotfound.component';
import { WAREHOUSE_ROUTES } from './warehouse/warehouse-routing.module';
import { ANZIO_ROUTES } from './anzio/anzio-routing.module';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { AnzioComponent } from './anzio/anzio.component';
import { REPORTS_ROUTES } from './reports/reports.routes';
import { ReportsComponent } from './reports/reports.component';
export var APP_ROUTES = [
    { path: '', redirectTo: '/anzio/terminal', pathMatch: 'full', canActivate: [AuthGuardService] },
    {
        path: 'anzio',
        canActivate: [AuthGuardService],
        component: AnzioComponent,
        data: {
            requiredPermissions: ['anzio.login'],
            menuName: 'Anzio',
        },
        children: ANZIO_ROUTES
    },
    {
        path: 'warehouse',
        canActivate: [AuthGuardService],
        component: WarehouseComponent,
        data: {
            requiredPermissions: ['warehouse.module'],
            menuName: 'Warehouse',
        },
        children: WAREHOUSE_ROUTES
    },
    {
        path: 'reports',
        canActivate: [AuthGuardService],
        component: ReportsComponent,
        data: {
            requiredPermissions: ['anzio.login'],
            menuName: 'Reports',
        },
        children: REPORTS_ROUTES,
    },
    { path: '**', component: PageNotFoundComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
