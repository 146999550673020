import { NgZone } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
var scriptLoadingPromise;
export function load() {
    if (scriptLoadingPromise) {
        return scriptLoadingPromise;
    }
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    var callbackName = "googlePlatformOnLoad" + new Date().getMilliseconds();
    script.src = "https://apis.google.com/js/platform.js?onload=" + callbackName;
    scriptLoadingPromise = new Promise(function (resolve, reject) {
        window[callbackName] = function () {
            gapi.load('auth2', function () {
                gapi.auth2.init({
                    client_id: environment.clientID
                }).then(function (auth2) {
                    resolve();
                });
            });
        };
        script.onerror = function (error) { reject(error); };
    });
    document.body.appendChild(script);
    return scriptLoadingPromise;
}
var GooglePlatformService = /** @class */ (function () {
    function GooglePlatformService(ngZone) {
        var _this = this;
        this.ngZone = ngZone;
        load().then(function () {
            _this.auth2 = gapi.auth2.init({
                client_id: environment.clientID
            });
        });
    }
    GooglePlatformService.prototype.currentUser = function () {
        var _this = this;
        return new Observable(function (observer) {
            load().then(function () {
                // Start with the current user
                observer.next(_this.auth2.currentUser.get());
                // Subscribe to changes.
                _this.auth2.currentUser.listen(function (user) {
                    observer.next(user);
                });
            });
        });
    };
    GooglePlatformService.prototype.login = function () {
        var _this = this;
        return from(load().then(function () {
            return _this.auth2.signIn();
        }));
    };
    GooglePlatformService.prototype.logout = function () {
        var _this = this;
        return from(load().then(function () {
            return _this.auth2.signOut();
        }));
    };
    GooglePlatformService.ngInjectableDef = i0.defineInjectable({ factory: function GooglePlatformService_Factory() { return new GooglePlatformService(i0.inject(i0.NgZone)); }, token: GooglePlatformService, providedIn: "root" });
    return GooglePlatformService;
}());
export { GooglePlatformService };
