var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as ReportActions from './reports.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export var initialState = {
    files: {},
    treeExpanded: {},
    loading: true,
    currentPath: '/',
};
function mapFileToTreeNode(currentPath) {
    return function (file) {
        if (file === undefined) {
            return;
        }
        var children = file.children === undefined ? undefined : file.children
            .filter(function (child) { return child.isDirectory; }).map(mapFileToTreeNode(currentPath));
        return {
            file: mapFileResponseToFileMeta(file),
            loading: false,
            active: file.path === currentPath,
            children: children,
        };
    };
}
export function mapFileResponseToFileMeta(file) {
    if (file === undefined) {
        return;
    }
    var icon = 'file';
    if (file.isDirectory) {
        icon = file.name === 'Trash' ? 'trash' : 'folder';
    }
    return {
        name: file.name,
        path: file.path,
        icon: icon,
        modified: file.modified,
        isDirectory: file.isDirectory
    };
}
function updateTree(path, updateFn) {
    return function (node) {
        if (node === undefined) {
            return;
        }
        if (node.file.path === path) {
            return updateFn(node);
        }
        if (path.indexOf(node.file.path) !== -1) {
            return __assign({}, node, { children: node.children.map(updateTree(path, updateFn)) });
        }
        return node;
    };
}
var updateTreeExpanded = function (path, expanded) { return updateTree(path, function (node) { return (__assign({}, node, { expanded: expanded })); }); };
var ɵ0 = updateTreeExpanded;
function updateTreeFile(currentPath, newFile) {
    return function (node) {
        if (node === undefined || node.file.path === newFile.path) {
            var newNode = mapFileToTreeNode(currentPath)(newFile);
            return newNode;
        }
        var children = node.children !== undefined ? node.children.map(updateTreeFile(currentPath, newFile)) : undefined;
        return __assign({}, node, { children: children });
    };
}
function updateFiles(files, file) {
    var newFiles = __assign({}, files);
    newFiles[file.path] = file;
    return newFiles;
}
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a;
    switch (action.type) {
        case ReportActions.PageLoadPath.type:
            return __assign({}, state, { loading: true, error: undefined, currentPath: action.path });
        case ReportActions.APIGetFileTreeSuccess.type:
            var tree = action.file;
            return __assign({}, state, { tree: updateTreeFile(state.currentPath, tree)(state.tree) });
        case ReportActions.TREE_EXPAND_FILE.type:
            var node = action.node;
            return __assign({}, state, { treeExpanded: __assign({}, state.treeExpanded, (_a = {}, _a[node.file.path] = node.expanded, _a)) });
        case ReportActions.APIGetFileSuccess.type:
            var file = action.file;
            return __assign({}, state, { currentPath: file.path, loading: false, files: updateFiles(state.files, file) });
        case ReportActions.APIGetFileFailure.type:
            var error = action.error;
            return __assign({}, state, { error: error, loading: false });
        default:
            return state;
    }
}
export var selectReportsState = createFeatureSelector('reports');
export var getError = createSelector(selectReportsState, function (state) { return state.error; });
export var getTreeNodes = createSelector(selectReportsState, function (state) { return state.tree; });
export var getTreeExpanded = createSelector(selectReportsState, function (state) { return state.treeExpanded; });
export var getTree = createSelector(getTreeNodes, getTreeExpanded, function (root, expanded) {
    if (root === undefined) {
        return undefined;
    }
    var getExpanded = function (node) {
        if (!!!node) {
            return undefined;
        }
        return expanded[node.file.path];
    };
    var mapNode = function (node) {
        return __assign({}, node, { expanded: getExpanded(node), children: node.children !== undefined ? node.children.map(mapNode) : undefined });
    };
    return __assign({}, root, { expanded: true, children: root.children !== undefined ? root.children.map(mapNode) : undefined });
});
export var getFiles = createSelector(selectReportsState, function (state) { return state.files; });
export var getCurrentFile = createSelector(selectReportsState, function (state) {
    if (state.currentPath !== undefined) {
        var currentFile = state.files[state.currentPath];
        return currentFile;
    }
});
export var getcurrentPathLoading = createSelector(selectReportsState, function (state) {
    return state.loading;
});
export { ɵ0 };
