import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FileResponse } from '../../services/files.service';
import { TreeNode } from '../../reports.models';

@Component({
  selector: 'app-filetree',
  templateUrl: './filetree.component.html',
  styleUrls: ['./filetree.component.css']
})
export class FiletreeComponent implements OnInit, OnChanges {

  @Input() nodes: TreeNode[];
  @Input() currentFile: FileResponse;

  collapsed: boolean;

  @Output() lazyLoad = new EventEmitter<null>();

  @Output() expand = new EventEmitter<TreeNode>();
  @Output() select = new EventEmitter<TreeNode>();

  constructor() { }

  ngOnInit() {
    if (this.lazyLoad) {
      this.lazyLoad.emit();
    }
  }

  ngOnChanges(changes: SimpleChanges) {

  }


  isActive(node: TreeNode) {

    if (node === undefined || node === null) {
      return false;
    }

    if (!this.currentFile) {
      return false;
    }

    if (this.currentFile.isDirectory) {
      return this.currentFile.path === node.file.path;
    }

    const lastSep = this.currentFile.path.lastIndexOf('/');

    if (lastSep === -1) {
      return false;
    }

    if (lastSep === 0) {
      return node.file.path === '/';
    }

    return this.currentFile.path.substring(0, lastSep) === node.file.path;
  }

  isExpanded(node: TreeNode) {

    if (node === undefined || node === null) {
      return false;
    }

    if (node.expanded !== undefined) {
      return node.expanded;
    }

    if (!this.currentFile) {
      return false;
    }

    return this.currentFile.path.indexOf(node.file.path) !== -1 ? true : node.expanded;
  }

  isExpandable(node: TreeNode): boolean | undefined {

    if (node === undefined || node === null) {
      return undefined;
    }

    if ( node.file.path === '/') {
      return false;
    }

    return undefined;
  }

  handleExpand(node: TreeNode, expanded: boolean) {

    this.expand.emit({
      ...node,
      expanded,
    });
  }

  handleSelect(node: TreeNode) {
    this.select.emit(node);
  }

  getChildren(node: TreeNode) {
    return node.children;
  }

}
