var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom, flatMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoginSuccess, LogoutSuccess, AuthActionTypes, LoginFailure } from './actions';
import { selectAuth } from './reducers';
import { ApiService } from '../services/api.service';
var AuthEffects = /** @class */ (function () {
    function AuthEffects(auth, api, store, router, actions$) {
        var _this = this;
        this.auth = auth;
        this.api = api;
        this.store = store;
        this.router = router;
        this.actions$ = actions$;
        this.login$ = this.actions$.pipe(ofType(AuthActionTypes.LOGIN), flatMap(function () {
            return _this.auth.login().pipe(map(function (user) { return new LoginSuccess(user); }), catchError(function (error) { return of(new LoginFailure(error)); }));
        }));
        this.loginSuccess$ = this.actions$.pipe(ofType(AuthActionTypes.LOGIN_SUCCESS), withLatestFrom(this.store), tap(function (_a) {
            var action = _a[0], state = _a[1];
            var url = selectAuth(state).loginURL || '/anzio';
            _this.router.navigate([url]);
        }));
        this.logout$ = this.actions$.pipe(ofType(AuthActionTypes.LOGOUT), mergeMap(function () { return _this.auth.logout(); }), map(function (user) {
            _this.router.navigate(['/login']);
            return new LogoutSuccess();
        }));
        this.loginRedirect$ = this.actions$.pipe(ofType(AuthActionTypes.LOGIN_REDIRECT), tap(function (loggedIn) {
            _this.router.navigate(['/login']);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AuthEffects.prototype, "login$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AuthEffects.prototype, "loginSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AuthEffects.prototype, "logout$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], AuthEffects.prototype, "loginRedirect$", void 0);
    return AuthEffects;
}());
export { AuthEffects };
