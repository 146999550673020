<div class="model-header">
  <h3><a routerLink="../">EDI Orders</a> / {{ id }}</h3>
  <clr-button-group class="model-actions btn-primary btn-sm">
    <clr-button>Validate</clr-button>
  </clr-button-group>
  <clr-button-group class="model-actions btn-primary btn-sm">
    <clr-button>Generate Pickslip</clr-button>
  </clr-button-group>
</div>
<ng-container *ngIf="(order$ | async) as order; else loading">
  <div class="model-detail">
    <edi-orders-detail [order]="order"></edi-orders-detail>
    <pre class="notes">{{order | json }}</pre>
  </div>
</ng-container>
<ng-template #loading>
  <div class="loading">
    <span class="spinner spinner-inline">
      Loading...
    </span>
  </div>
</ng-template>
