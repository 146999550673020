import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AppState } from '..';
import { getUser, User } from '../../auth';
import { take, map, shareReplay } from 'rxjs/operators';

type SessionStatus = 'connecting' | 'authenticating' | 'connected' | 'disconnected' | 'error' | 'connection_lost' | 'connection_error';
export type StatusEvents = 'connecting' | 'authenticating' | 'connected' | 'disconnected';

export class Session {
  private socket: WebSocket;
  private authenticated = false;

  private _statusEvents = new BehaviorSubject<StatusEvents>('disconnected');
  private _stdout = new Subject<string>();

  stdout: Observable<string> = this._stdout.asObservable();

  status: Observable<StatusEvents> = this._statusEvents
    .asObservable()
    .pipe(
      shareReplay(1)
    );

  constructor(private server: string, private user: User, private password: string) {

    const socketUrl = `${this.server}/ssh?idToken=${this.user.idToken}`;
    this.socket = new WebSocket(socketUrl);
    this._statusEvents.next('connecting');
    this.socket.onopen = this.onOpen.bind(this);
    this.socket.onmessage = this.onMessage.bind(this);
    this.socket.onerror = this.onError.bind(this);
    this.socket.onclose = this.onClose.bind(this);

  }

  private onMessage(event: MessageEvent) {
    const message = JSON.parse(event.data);

    switch (message.type) {
      case 'auth_request':
        this.socket.send(JSON.stringify({
          type: 'auth_response',
          data: this.user.email.split('@')[0] + ':' + this.password
        }));
        break;
      case 'auth_success':
        this.authenticated = true;
        this._statusEvents.next('connected');
        break;
      case 'stdout':
        this._stdout.next(message.data);
        break;
      case 'error':
        this._statusEvents.next('disconnected');
        this._statusEvents.error(message.data);
        break;
    }
  }
  private onOpen(event: Event) {
    this._statusEvents.next('authenticating');
  }
  private onError(event: Event) {
    this._statusEvents.error(event);
  }

  private onClose(event: CloseEvent) {
    this._statusEvents.next('disconnected');
    if (event.wasClean || event.code === 1000) {
      this._statusEvents.complete();
    } else {
      this._statusEvents.error(event.reason);
    }

    this._stdout.complete();
  }

  send(data: string) {
    this.socket.send(JSON.stringify({ type: 'stdin', data: data }));
  }

  close() {
    this.socket.close();
  }
}

@Injectable({
  providedIn: 'root'
})
export class TerminalService {

  constructor(private store: Store<AppState>) {
  }

  createSession(server: string, password: string) {
    return this.store.pipe(
      getUser,
      take(1),
      map((user) => {
        return new Session(server, user, password);
      })
    );

  }

}
