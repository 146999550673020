import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { LocationActions } from '../../../actions/edi';
import { selectEdiLocationsState, selectEdi } from '../../../reducers';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DialogService } from '../../../../services/dialog.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit, OnDestroy {


  error$ = this.store.pipe(
    select(selectEdiLocationsState),
    map( state => state.error ),
  );

  saved$ = this.store.pipe(
    select(selectEdiLocationsState),
    map( state => state.saved ),
  );

  form = new FormControl({});

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.store.dispatch(LocationActions.CreatePageLoad());
  }

  ngOnDestroy() {
    this.store.dispatch(LocationActions.CreatePageUnload());
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.dirty) {
      return true;
    }

    return this.saved$.pipe(
      switchMap( saved => {

        if (!saved) {
          return this.dialogService.confirm('Do you want to discard your changes?');
        }

        return of(true);
      }),
    );
  }

  cancel() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  save() {
    const updatedValue = this.form.value;
    this.store.dispatch(
      LocationActions.CreatePageCreateButton({ location: updatedValue }),
    );
  }
}
