import { Routes } from '@angular/router';
import { DashboardComponent } from './containers/dashboard.component';
import { PageNotFoundComponent } from '../components/pagenotfound.component';
import { EdiComponent } from './containers/edi.component';
import { OrderListContainer } from './containers/edi/orders/order-list.component';
import { OrderDetailContainer } from './containers/edi/orders/order-detail.component';
import * as EdiLocationContainers from './containers/edi/locations';
import * as EdiStockTranslationContainers from './containers/edi/translations';
import { CanDeactivateGuard } from '../services/can-deactivate.guard';
export var WAREHOUSE_ROUTES = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: 'dashboard',
        component: DashboardComponent,
    },
    {
        path: 'edi',
        component: EdiComponent,
        children: [
            { path: 'orders', component: OrderListContainer },
            { path: 'orders/:id', component: OrderDetailContainer },
            { path: 'locations', component: EdiLocationContainers.LocationsListComponent },
            {
                path: 'locations/create',
                component: EdiLocationContainers.CreateComponent,
                canDeactivate: [CanDeactivateGuard],
            },
            {
                path: 'locations/detail',
                component: EdiLocationContainers.LocationsDetailsComponent,
                canDeactivate: [CanDeactivateGuard],
            },
            { path: 'translations', component: EdiStockTranslationContainers.ListComponent },
            {
                path: 'translations/create',
                component: EdiStockTranslationContainers.CreateComponent,
                canDeactivate: [CanDeactivateGuard],
            },
            {
                path: 'translations/detail',
                component: EdiStockTranslationContainers.DetailsComponent,
                canDeactivate: [CanDeactivateGuard],
            },
        ]
    },
    { path: '**', component: PageNotFoundComponent }
];
var WarehouseRoutingModule = /** @class */ (function () {
    function WarehouseRoutingModule() {
    }
    return WarehouseRoutingModule;
}());
export { WarehouseRoutingModule };
