import { Routes } from '@angular/router';
import { LoginComponent } from './components/login.component';
import { LogoutComponent } from './components/logout.component';
var authRoutes = [
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent }
];
var AuthRoutingModule = /** @class */ (function () {
    function AuthRoutingModule() {
    }
    return AuthRoutingModule;
}());
export { AuthRoutingModule };
