import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { map, tap } from 'rxjs/operators';

import * as fromRoot from '../../../reducers';

import { selectAnzioState, getConnected, AnzioState, getError, getConnectionState, getSession } from '../../reducers';
import { Connect, Disconnect } from '../../actions';

import { environment } from '../../../../environments/environment';

import { getUser } from '../../../auth';

@Component({
  selector: 'app-anzio-web',
  templateUrl: 'anzio-web.component.html',
  styleUrls: ['anzio-web.component.scss'],
})
export class AnzioWebComponent implements OnInit {

  password: string;

  server = environment.socketServer;
  session = this.store.pipe(
    select(getSession)
  );
  status = this.store.pipe(
    select(getConnectionState)
  );
  error = this.store.pipe(
    select(getError),
    tap(() => {this.password = ''; })
  );

  showDialog = this.store.pipe(
    select(getConnected),
    map( (connected) => !connected)
  );

  user = this.store.pipe(
    getUser
  );

  constructor(private store: Store<fromRoot.AppState>) {
  }

  ngOnInit() {
  }

  connect() {
    this.store.dispatch(new Connect({server: this.server, password: this.password}));
  }

}
