<ng-container *ngIf="(translation$ | async) as location; else loading">
  <div class="model-header">
      <div class="clr-row">
          <div class="clr-col-12 clr-col-md-8">
            <h3><a routerLink="../">EDI Stock Translations</a> / {{ getTranslationID(location) }}</h3>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-12">
            <clr-button-group *ngIf="!(editing$ | async)" class="model-actions btn-primary btn-sm">
              <clr-button (click)="edit()">Edit</clr-button>
              <clr-button (click)="delete(location)">Delete</clr-button>
            </clr-button-group>
            <clr-button-group *ngIf="editing$ | async" class="model-actions btn-primary btn-sm">
              <clr-button (click)="save()">Save</clr-button>
              <clr-button (click)="cancel()" class="btn-danger">Cancel</clr-button>
            </clr-button-group>
          </div>
        </div>
  </div>
  <div class="model-detail">
    <app-edi-stock-translations-detail-form [formControl]="form" [edit]="editing$ | async"></app-edi-stock-translations-detail-form>
  </div>
</ng-container>
<ng-template #loading>
  <div class="loading">
    <span class="spinner spinner-inline">
      Loading...
    </span>
  </div>
</ng-template>
