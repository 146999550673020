import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, of, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { tap, map, switchMap, distinctUntilChanged, catchError, take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { EdiLocation, EdiStockTranslation, EncodeEdiStockTranslationID } from '../../../models';
import { DialogService } from '../../../../services/dialog.service';
import { StockTranslationActions } from '../../../actions/edi';

import { selectEdiStockTranslationsState, selectEdiStockTranslationSelected } from '../../../reducers';

@Component({
  selector: 'app-edi-stock-translations-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit, OnDestroy {

  translation$ = this.store.pipe(
    select(selectEdiStockTranslationSelected),
  );

  editing$ = this.store.pipe(
    select(selectEdiStockTranslationsState),
    map(state => state.editing),
  );

  id$ = this.route.paramMap.pipe(
    map(params => {
      return params.get('id');
    }),
  );

  error = null;

  initialTranslation: EdiStockTranslation;
  form = new FormControl({ value: {} });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        const edit = +params.get('edit') === 1;
        return { id, edit };
      }),
      distinctUntilChanged(),
      tap(params => {
        this.store.dispatch(StockTranslationActions.DetailPageLoad(params));
      })
    ).subscribe();

    this.translation$.subscribe((location) => {
      this.initialTranslation = location;
      this.form.reset(this.initialTranslation);
    });

  }

  ngOnDestroy() {
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.dirty) {
      return true;
    }

    return this.editing$.pipe(
      switchMap(editing => {
        if (editing) {
          return this.dialogService.confirm('Do you want to discard your changes?').pipe(
            tap((discard) => {
              if (discard) {
                this.form.reset(this.initialTranslation);
              }
            })
          );
        }

        return of(true);
      })
    );
  }

  edit() {
    const params = {
      ...this.route.snapshot.params,
      edit: 1,
    };
    this.router.navigate(['./', params], { relativeTo: this.route });
  }

  cancel() {
    const params = {
      ...this.route.snapshot.params,
      edit: 0,
    };
    this.router.navigate(['./', params], { relativeTo: this.route });
  }

  save() {
    const updatedValue = this.form.value;
    this.store.dispatch(
      StockTranslationActions.DetailPageSaveButton({ translation: updatedValue }),
    );
  }

  getTranslationID(translation: EdiStockTranslation) {
    return EncodeEdiStockTranslationID(translation);
  }

  delete(translation: EdiStockTranslation) {
    this.dialogService.confirm(`Are you sure you want to delete location ${translation.DebtorCode} - ${translation.StockCode}?`).pipe(
      take(1),
      tap( (cont) => {
        if (cont) {
          this.store.dispatch(StockTranslationActions.DetailPageDeleteButton({ translation: translation }));
        }
      }),
    ).subscribe();
  }


}
