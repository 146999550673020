<clr-main-container>
  <clr-header [ngStyle]="headerStyle">
    <div class="branding">
      <a routerLink="/" class="nav-link">
        <span class="title">{{ title }}</span>
      </a>
    </div>
    <div *ngIf="(loggedIn | async); then loggedInBlock"></div>
    <ng-template #loggedInBlock>
      <div class="header-nav" [clr-nav-level]="1">
        <a *ngFor="let item of (menu | async)" [routerLink]="item.path"  routerLinkActive="active" class="nav-link nav-text"> {{ item.name }}</a>
      </div>
      <div class="header-actions">
        <a routerLink="/logout" class="nav-link nav-text">Log Out</a>
      </div>
    </ng-template>
  </clr-header>
  <router-outlet></router-outlet>
</clr-main-container>
