import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EdiLocation, EdiStockTranslation } from '../../../models';

@Component({
  selector: 'app-edi-stock-translations-detail-form',
  templateUrl: './details-form.component.html',
  styleUrls: ['./details-form.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DetailFormComponent),
    multi: true
  }],
})
export class DetailFormComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() edit = false;
  @Input() create = false;

  form = new FormGroup({
    StockCode: new FormControl(''),
    DebtorCode: new FormControl(''),
    TUN: new FormControl(''),
  });

  constructor() { }

  writeValue(value: EdiStockTranslation) {
    console.log(value);
    if (value) {
      this.form.patchValue(value, {
        emitEvent: false,
      });
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched() {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

}
