import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { OrderActions } from '../../../actions/edi';
import { selectEdiDetail, AppState } from '../../../reducers';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
// tslint:disable-next-line:component-class-suffix
export class OrderDetailContainer implements OnInit, OnDestroy {

  id: string;

  order$ = this.store.pipe(
    select(selectEdiDetail),
  );

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(
      tap( (params) => {
        const id = params.get('id');
        this.id = id;
        this.store.dispatch(OrderActions.DetailPageLoad({ id }));
      })
    ).subscribe();
  }

  ngOnDestroy() {
  }

}
