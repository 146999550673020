import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

interface MenuItem {
  icon: string;
  link?: string;
  name: string;
  expanded?: boolean;
  children?: MenuItem[];
}

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit {

  collapsed = false;
  menu: MenuItem[] = [
    {
      icon: 'dashboard',
      link: './dashboard',
      name: 'Dashboard'
    },
    {
      icon: 'cog',
      name: 'EDI',
      children: [
        {
          icon: 'list',
          link: 'edi/orders',
          name: 'Orders'
        },
        {
          icon: 'map',
          link: 'edi/locations',
          name: 'Locations'
        },
        {
          icon: 'bar-code',
          link: 'edi/translations',
          name: 'Stock Translations'
        },
      ]
    }
  ];

  constructor(private router: Router) { }

  ngOnInit() {
    const childActive = (items) => {
      if (items ===  undefined) {
        return false;
      }

      return items.filter( item => this.router.url.indexOf(item.link) !== -1 ).length > 0;
    };
    this.menu = this.menu.map( (item) => {
      return {
        ...item,
        expanded: childActive(item.children),
      };
    });
  }

}
