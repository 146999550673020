import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { StockTranslationActions } from '../actions/edi';
import { switchMap, map, catchError, tap, flatMap, mergeMap, scan, reduce } from 'rxjs/operators';
import { of, merge, from } from 'rxjs';
import { Router } from '@angular/router';
import { StocksService } from '../services/stocks.service';
import { EdiStockTranslationID, EncodeEdiStockTranslationID, DecodeEdiStockTranslationID } from '../models';


@Injectable()
export class StockTranslationsEffects {


  @Effect()
  loadStockTranslationsList$ = this.actions$.pipe(
    ofType(StockTranslationActions.ListPageLoad.type),
    switchMap(() => this.stocksService.list().pipe(
      map(translations => StockTranslationActions.APIListSuccess({ translations: translations || [] })),
      catchError((error) => of(StockTranslationActions.APIListFailure({ error: error }))),
    ))
  );

  @Effect()
  loadLocation$ = this.actions$.pipe(
    ofType(StockTranslationActions.DetailPageLoad.type),
    switchMap((action) => this.stocksService.get(DecodeEdiStockTranslationID(action.id)).pipe(
      map(translation => StockTranslationActions.APIGetSuccess({ translation })),
      catchError((error) => of(StockTranslationActions.APIGetFailure({ error: error }))),
    ))
  );

  @Effect()
  saveEdiLocation$ = this.actions$.pipe(
    ofType(StockTranslationActions.DetailPageSaveButton.type),
    switchMap(action => this.stocksService.update(action.translation).pipe(
      map(translation => StockTranslationActions.APISaveSuccess({ translation })),
      catchError((error) => of(StockTranslationActions.APISaveFailure({ error: error[0] }))),
    ))
  );

  @Effect()
  createEdiLocation$ = this.actions$.pipe(
    ofType(StockTranslationActions.ListPageCreateButton.type, StockTranslationActions.CreatePageCreateButton.type),
    switchMap(action => this.stocksService.create(action.translation).pipe(
      map(translation => StockTranslationActions.APICreateSuccess({ translation })),
      catchError(error => of(StockTranslationActions.APICreateFailure({ error: error[0] }))),
    ))
  );

  @Effect({ dispatch: false })
  updateLocationDetailPage$ = this.actions$.pipe(
    ofType(StockTranslationActions.APICreateSuccess.type, StockTranslationActions.APISaveSuccess.type),
    tap(({ translation }) => {
      this.router.navigate(
        ['warehouse/edi/translations/detail',
          { id: EncodeEdiStockTranslationID(translation), edit: 0 }]
      );
    })
  );

  @Effect()
  deleteMultipleTranslations$ = this.actions$.pipe(
    ofType(StockTranslationActions.ListPageDeleteButton.type),
    mergeMap(action =>
      from(action.translations).pipe(
        mergeMap(translation => this.stocksService.delete(translation).pipe(
          map(() => EncodeEdiStockTranslationID(translation)),
        )),
        reduce((acc: string[], val: string) => {
          return [...acc, val];
        }, []),
        map((ids) => StockTranslationActions.APIDeleteSuccess({ ids })),
        catchError(error => of(StockTranslationActions.APIDeleteFailure({ error }))),
      ),
    ),
  );

  @Effect()
  deleteTranslation$ = this.actions$.pipe(
    ofType(StockTranslationActions.DetailPageDeleteButton.type),
    mergeMap(action => this.stocksService.delete(action.translation).pipe(
      map((success) => StockTranslationActions.APIDeleteSuccess({ ids: [EncodeEdiStockTranslationID(action.translation)] })),
      catchError(error => of(StockTranslationActions.APIDeleteFailure({ error }))),
    )),
  );

  @Effect({ dispatch: false })
  redictedOnDelete$ = this.actions$.pipe(
    ofType(StockTranslationActions.APIDeleteSuccess.type),
    tap(() => {
      this.router.navigate(['warehouse/edi/translations']);
    })
  );

  constructor(
    private actions$: Actions<StockTranslationActions.StockTranslationsActionsUnion>,
    private stocksService: StocksService,
    private router: Router,
  ) { }

}
