import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { switchMap, map, catchError, filter} from 'rxjs/operators';


import * as AnzioActions from './actions';
import { AppState } from '../reducers';
import { Action, Store } from '@ngrx/store';

import { FilesService } from '../reports/services/files.service';
import { TerminalService } from './services/terminal.service';

import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Router } from '@angular/router';

@Injectable()
export class AnzioEffects {

  constructor(
    private actions$: Actions,
    private terminalService: TerminalService,
    private fileService: FilesService,
    private router: Router,
    private store: Store<AppState>,
  ) { }

  @Effect()
  connect = this.actions$.pipe(
    ofType<AnzioActions.Connect>(AnzioActions.CONNECT),
    switchMap( (action) => {
      return this.terminalService.
        createSession(action.payload.server, action.payload.password).pipe(
        map( (session) => new AnzioActions.ConnectSuccess(session))
      );
    })
  );

  @Effect()
  connectionStatus = this.actions$.pipe(
    ofType<AnzioActions.ConnectSuccess>(AnzioActions.CONNECT_SUCCESS),
    switchMap(( action ) => {
      return action.payload.status.pipe(
        map( status => new AnzioActions.SessionStatus(status)),
        catchError( (error) => of(new AnzioActions.SessionError(error)))
      );
    })
  );

}
