import { Component, OnInit, Input, Output, EventEmitter, forwardRef, OnDestroy, Self } from '@angular/core';
import { EdiLocation } from '../../../models';
import { DebtorsService, Debtor } from '../../../../services/debtors.service';
import { FormGroup, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Subject, of, concat, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-locations-detail-form',
  templateUrl: './locations-detail-form.component.html',
  styleUrls: ['./locations-detail-form.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LocationsDetailFormComponent),
    multi: true
  }],
})
export class LocationsDetailFormComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() edit = false;
  @Input() create = false;

  form = new FormGroup({
    Code: new FormControl(''),
    Name: new FormControl(''),
    Account: new FormControl(''),
    DeliveryCode: new FormControl(''),
    OrderTemplate: new FormControl(''),
  });

  private valuesSub: Subscription;

  private _onChange = (value: any) => { };
  private _onTouched = () => {};
  constructor() { }

  writeValue(value: EdiLocation) {
    if (value) {
      this.form.patchValue(value, {
        emitEvent: false,
      });
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched() {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

}
