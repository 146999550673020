import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
var LocationsDetailFormComponent = /** @class */ (function () {
    function LocationsDetailFormComponent() {
        this.edit = false;
        this.create = false;
        this.form = new FormGroup({
            Code: new FormControl(''),
            Name: new FormControl(''),
            Account: new FormControl(''),
            DeliveryCode: new FormControl(''),
            OrderTemplate: new FormControl(''),
        });
        this._onChange = function (value) { };
        this._onTouched = function () { };
    }
    LocationsDetailFormComponent.prototype.writeValue = function (value) {
        if (value) {
            this.form.patchValue(value, {
                emitEvent: false,
            });
        }
    };
    LocationsDetailFormComponent.prototype.registerOnChange = function (fn) {
        this.form.valueChanges.subscribe(fn);
    };
    LocationsDetailFormComponent.prototype.registerOnTouched = function () {
    };
    LocationsDetailFormComponent.prototype.ngOnInit = function () {
    };
    LocationsDetailFormComponent.prototype.ngOnDestroy = function () {
    };
    return LocationsDetailFormComponent;
}());
export { LocationsDetailFormComponent };
